import React from "react"
import { Form, Input, InputNumber, Radio, Checkbox } from "antd"
import { CloseOutlined } from "@ant-design/icons"

function SiteSurveysMotorsFormPiece(props) {
    return (
        <div className={"site-surveys-motors-form-piece"}>
            <CloseOutlined className={"close-icon"} onClick={props.remove} />
            <Form.Item {...props} name={[props.name, "name"]} fieldKey={[props.fieldKey, "name"]} label={"Motor Name"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "active"]} fieldKey={[props.fieldKey, "active"]} label={"Active"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "make"]} fieldKey={[props.fieldKey, "make"]} label={"Make"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "model"]} fieldKey={[props.fieldKey, "model"]} label={"Model"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "horse_power"]} fieldKey={[props.fieldKey, "horse_power"]} label={"Horse Power"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "ac_dc"]} fieldKey={[props.fieldKey, "ac_dc"]} label={"AC/DC"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "full_load_current"]} fieldKey={[props.fieldKey, "full_load_current"]} label={"Full Load Current"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "synchronous_speed"]} fieldKey={[props.fieldKey, "synchronous_speed"]} label={"Synchronous Speed"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "nameplate_speed"]} fieldKey={[props.fieldKey, "nameplate_speed"]} label={"Nameplate Speed"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "vfd"]} fieldKey={[props.fieldKey, "vfd"]} label={"VFD"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "enclosure"]} fieldKey={[props.fieldKey, "name"]} label={"Enclosure"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "power_factor"]} fieldKey={[props.fieldKey, "power_factor"]} label={"Power Factor"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "insulation_class"]} fieldKey={[props.fieldKey, "insulation_class"]} label={"Insulation Class"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "percent_efficiency"]} fieldKey={[props.fieldKey, "percent_efficiency"]} label={"Percent Efficiency"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "application_notes"]} fieldKey={[props.fieldKey, "application_notes"]} label={"Application Notes"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "pump"]} fieldKey={[props.fieldKey, "pump"]} label={"Pump"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "manufacturer"]} fieldKey={[props.fieldKey, "manufacturer"]} label={"Manufacturer"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "model_number"]} fieldKey={[props.fieldKey, "model_number"]} label={"Model Number"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "serial_number"]} fieldKey={[props.fieldKey, "serial_number"]} label={"Serial Number"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "drive_type"]} fieldKey={[props.fieldKey, "drive_type"]} label={"Drive Type"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "service_application"]} fieldKey={[props.fieldKey, "service_application"]} label={"Service/Application"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "motor_type"]} fieldKey={[props.fieldKey, "motor_type"]} label={"Motor Type"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "control_method"]} fieldKey={[props.fieldKey, "control_method"]} label={"Control Method"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "design_pressure"]} fieldKey={[props.fieldKey, "design_pressure"]} label={"Design Pressure"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "stages"]} fieldKey={[props.fieldKey, "stages"]} label={"Stages"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "flow_pressure_var"]} fieldKey={[props.fieldKey, "flow_pressure_var"]} label={"Flow Pressure Var"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "pressure_lost_over_time"]} fieldKey={[props.fieldKey, "pressure_lost_over_time"]} label={"Pressure Lost Over Time"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "annual_operating_hours"]} fieldKey={[props.fieldKey, "annual_operating_hours"]} label={"Annual Operating Hours"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "system"]} fieldKey={[props.fieldKey, "system"]} label={"System"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "impeller_cut"]} fieldKey={[props.fieldKey, "impeller_cut"]} label={"Impeller Cut"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "flow"]} fieldKey={[props.fieldKey, "flow"]} label={"Flow"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "fluid"]} fieldKey={[props.fieldKey, "fluid"]} label={"Fluid"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "loop_type"]} fieldKey={[props.fieldKey, "loop_type"]} label={"Loop Type"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "maintenance_level_required"]} fieldKey={[props.fieldKey, "maintenance_level_required"]} label={"Maintenance Level"}>
                <Radio.Group>
                    <Radio value={0}>Low</Radio>
                    <Radio value={1}>Medium</Radio>
                    <Radio value={2}>High</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item {...props} name={[props.name, "number_of_end_users"]} fieldKey={[props.fieldKey, "number_of_end_users"]} label={"Number Of End Users"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "remaining_life"]} fieldKey={[props.fieldKey, "remaining_life"]} label={"Remaining Life"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "maintenance_issues"]} fieldKey={[props.fieldKey, "maintenance_issues"]} label={"Maintenance Issues?"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "flow_meter_available"]} fieldKey={[props.fieldKey, "flow_meter_available"]} label={"Flow Meter Available?"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "system_schematic_available"]} fieldKey={[props.fieldKey, "system_schematic_available"]} label={"Schematic Available?"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "cavitation_issues"]} fieldKey={[props.fieldKey, "cavitation_issues"]} label={"Cavitation Issues?"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "meter_available"]} fieldKey={[props.fieldKey, "meter_available"]} label={"Meter Available?"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "duty_changed_over_time"]} fieldKey={[props.fieldKey, "duty_changed_over_time"]} label={"Duty Changed Over Time?"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "vibration_noise_issues"]} fieldKey={[props.fieldKey, "vibration_noise_issues"]} label={"Vibration Noise Issues?"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "test_ports_available"]} fieldKey={[props.fieldKey, "test_ports_available"]} label={"Test Ports Available?"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item
                {...props}
                name={[props.name, "parallel_with_other_pumps"]}
                fieldKey={[props.fieldKey, "parallel_with_other_pumps"]}
                label={"Parallel With Other Pumps?"}
                valuePropName="checked">
                <Checkbox />
            </Form.Item>
        </div>
    )
}

export default SiteSurveysMotorsFormPiece
