import React from "react"
import { Link, useRouteMatch } from "react-router-dom"

export default function SearchNav({ activeTab }) {
    const { path } = useRouteMatch()

    return (
        <>
            <h2>Advanced Search</h2>
            <ul className="header-tab-nav" role="navigation">
                <li>
                    <Link to={path}>
                        <button type="link" className={`${activeTab === "search" ? "selected" : ""}`}>
                            Search
                        </button>
                    </Link>
                </li>
            </ul>
        </>
    )
}
