import { createSlice } from "@reduxjs/toolkit"

export const alertPanelSlice = createSlice({
    name: "alerts",
    initialState: {
        alerts: [],
        count: 0,
    },
    reducers: {
        setAlerts: (state, action) => {
            state.alerts = [action.payload, ...state.alerts]
            state.count = state.count + 1
        },
        setAlertCount: (state, action) => {
            state.count = action.payload
        },
    },
})

export const { setAlerts, setAlertCount } = alertPanelSlice.actions

export function addAlert(newAlert) {
    return async (dispatch, getState) => {
        dispatch(setAlerts(newAlert))
    }
}

export function resetCount() {
    return async (dispatch, getState) => {
        dispatch(setAlertCount(0))
    }
}

export const selectAlerts = (state) => state.alerts.alerts
export const selectCount = (state) => state.alerts.count

export default alertPanelSlice.reducer
