import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Skeleton, Space, notification } from "antd"
import { DownloadOutlined, SearchOutlined, FilterOutlined } from "@ant-design/icons"
import nodePath from "path"
import moment from "moment"

import { fetchGroupAccounts, selectGroupAccounts, selectTotal, selectPagination, selectLoading, filterGroupAccounts, generateCsv } from "../groupAccountsSlice"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
import ColumnDateFilter from "../../../components/utilityComponents/table/ColumnDateFilter"

export default function GroupAccountTable({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    let { clientId } = useParams()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const groupAccounts = useSelector(selectGroupAccounts)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialGroupAccounts()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialGroupAccounts = () => {
        dispatch(fetchGroupAccounts(pagination, nodePath.join(url, "")))
    }

    const handleFilterGroupAccounts = (newPagination = null, newSort = null) => {
        return dispatch(
            filterGroupAccounts({
                clientId: clientId,
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortGroupAccountsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterGroupAccounts(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchGroupAccounts(newPagination, nodePath.join(url, "")))
        } else {
            handleFilterGroupAccounts(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortGroupAccountsChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialGroupAccounts()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    const groupBillableAccountsCsv = () => {
        notification.open({
            message: "Group billable account export started",
        })

        dispatch(generateCsv(clientId, filters))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Group Accounts</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <a href="javascript:;" onClick={() => groupBillableAccountsCsv()}>
                                    <DownloadOutlined />
                                    Export
                                </a>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={groupAccounts} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("facility")}
                                key="facility_id"
                                dataIndex="facility"
                                render={(facility) => facility?.name}
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialGroupAccounts}
                                        filterAction={filterGroupAccounts}
                                        filterColumn={"facility_name"}
                                        filterType={"facility_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("facility_name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("account_number")}
                                key="account_number"
                                dataIndex="account_number"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialGroupAccounts}
                                        filterAction={filterGroupAccounts}
                                        filterColumn={"account_number"}
                                        filterType={"account_number_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("account_number_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("name")}
                                key="name"
                                dataIndex="name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialGroupAccounts}
                                        filterAction={filterGroupAccounts}
                                        filterColumn={"name"}
                                        filterType={"name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />
                            <Table.Column title={"Address"} render={(row) => `${row.street_address_1} ${row.street_address_2}, ${row.city}, ${row.state} ${row.zip}, ${row.country}`} />
                            <Table.Column
                                // title={renderColumnHeaderTitle("last_bill_date")}
                                title={"Last Bill Date"}
                                key="last_bill_date"
                                dataIndex="last_bill_date"
                                // filterDropdown={() => (
                                //     <ColumnDateFilter
                                //         handleFetchInitial={handleFetchInitialGroupAccounts}
                                //         filterAction={filterGroupAccounts}
                                //         filterColumn={"last_bill_date"}
                                //         filterType={"last_bill_date"}
                                //         filters={filters}
                                //         pagination={pagination}
                                //         setFilters={setFilters}
                                //         sort={sort}
                                //         setSort={setSort}
                                //     />
                                // )}
                                // filterIcon={() => renderSearchOutlinedButton("last_bill_date_lteq" || "last_bill_date_gteq")}
                                render={(text, record, index) => moment(text).format("L")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("created_at")}
                                key="created_at"
                                dataIndex="created_at"
                                filterDropdown={({ visible }) => (
                                    <ColumnDateFilter
                                        handleFetchInitial={handleFetchInitialGroupAccounts}
                                        filterAction={filterGroupAccounts}
                                        filterColumn={"created_at"}
                                        filterType={"created_at"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("created_at_lteq" || "created_at_gteq")}
                                render={(text, record, index) => moment(text).format("L")}
                            />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <Space>
                                        {/* <div className="row-actions" style={{ display: "inline-block" }}>
                                            <Link to={scopedByClient ? `/clients/${scopedByClient}/group_accounts/${record.id}/edit` : `/group_accounts/${record.id}/edit`}>
                                                <Tooltip title="Subaccounts">
                                                    <EditOutlined />
                                                </Tooltip>
                                            </Link>
                                        </div> */}
                                        {scopedByClient && (
                                            <div className="row-actions" style={{ display: "inline-block" }}>
                                                <Link to={`/clients/${scopedByClient}/group_accounts/${record.id}/subaccounts`}>Subaccounts</Link>
                                            </div>
                                        )}
                                        {scopedByClient && (
                                            <div className="row-actions" style={{ display: "inline-block" }}>
                                                <Link to={`/clients/${scopedByClient}/group_accounts/${record.id}/bills`}>Bills</Link>
                                            </div>
                                        )}
                                    </Space>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}
