import { createSlice } from "@reduxjs/toolkit"
import client from "../../../lib/client"
import { auditLogUrl, exportAuditLogUrl } from "../../../lib/endpoints"
import moment from "moment"

export const auditLogSlice = createSlice({
    name: "auditLog",
    initialState: {
        auditLog: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        filter: { users: null, from: null, to: null, client: null, division: null, facility: null },
        loading: false,
    },
    reducers: {
        setAuditLog: (state, action) => {
            state.auditLog = action.payload.audit_log
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setFilter: (state, action) => {
            state.filter = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        reset: (state) => {
            state.pagination = { current: 1, pageSize: 10 }
            state.filter = { users: null, from: null, to: null, client: null, division: null, facility: null }
            state.total = 0
            state.auditLog = []
        },
    },
})

export const { setAuditLog, setLoading, setPagination, setFilter, reset } = auditLogSlice.actions

function buildFilters(filter) {
    return `${filter.users && filter.users.length ? "&users=" + filter.users : ""}${filter.from ? "&from=" + moment(filter.from).format() : ""}${filter.to ? "&to=" + moment(filter.to).format() : ""}${
        filter.client && filter.client.length ? "&client_id=" + filter.client : ""
    }${filter.division && filter.division.length ? "&division_id=" + filter.division : ""}${filter.facility && filter.facility.length ? "&facility_id=" + filter.facility : ""}${
        filter.key && filter.key.length ? "&key=" + filter.key : ""
    }${filter.value && filter.value.length ? "&value=" + filter.value : ""}`
}

export function fetchAuditLog(pagination, filter) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client
            .get(`${auditLogUrl}?page=${pagination.current}&per=${pagination.pageSize}${buildFilters(filter)}`)
            .then((response) => {
                dispatch(setPagination(pagination))
                dispatch(setAuditLog(response.data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function exportAuditLog(filter) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client
            .get(`${exportAuditLogUrl}?f=b${buildFilters(filter)}`) // without having a ? param it seems to throw a 404...
            .then((response) => {
                // console.log(response)
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectAuditLog = (state) => state.auditLog.auditLog
export const selectPagination = (state) => state.auditLog.pagination
export const selectLoading = (state) => state.auditLog.loading
export const selectTotal = (state) => state.auditLog.total
export const selectFilter = (state) => state.auditLog.filter

export default auditLogSlice.reducer
