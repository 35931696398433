import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

import { clientBillDocumentRepositoryUrl, clientBillDocumentUrl } from "../../lib/endpoints"
import client from "../../lib/client"

export const billDocumentRepositoriesSlice = createSlice({
    name: "billDocumentRepositories",
    initialState: {
        loading: false,
        pagination: { current: 1, pageSize: 10 },
        total: 0,
        documents: [],
        document: null,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setDocuments: (state, action) => {
            state.documents = action.payload.bill_documents
            state.total = action.payload.total
        },
        setDocument: (state, action) => {
            state.document = action.payload
        },
        updateDocument: (state, action) => {
            state.documents = state.documents.map((document) => {
                if (document.id === action.payload.bill_document_id) {
                    return {
                        ...document,
                        file_url: action.payload.bill_document_file,
                    }
                } else {
                    return document
                }
            })
        },
        deleteDocument: (state, action) => {
            state.documents = state.documents.filter((document) => {
                return document.id !== action.payload
            })
            state.total = state.total - 1
        },
    },
})

export const { setLoading, setPagination, setTotal, setDocuments, setDocument, updateDocument, deleteDocument } = billDocumentRepositoriesSlice.actions

export const selectLoading = (state) => state.billDocumentRepositories.loading
export const selectPagination = (state) => state.billDocumentRepositories.pagination
export const selectTotal = (state) => state.billDocumentRepositories.total
export const selectDocuments = (state) => state.billDocumentRepositories.documents
export const selectDocument = (state) => state.billDocumentRepositories.document

export function fetchBillDocumentRepositories(clientId, pagination) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${clientBillDocumentRepositoryUrl(clientId)}?page=${pagination.current}&per=${pagination.pageSize}`)
            .then(({ data }) => {
                dispatch(setPagination(pagination))
                dispatch(setDocuments(data))
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchBillDocument(clientId, documentId) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(clientBillDocumentUrl(clientId, documentId))
            .then(({ data }) => {
                dispatch(setDocument(data))
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateBillRepository(clientId, values) {
    return async (dispatch, getState) => {
        try {
            const response = await client.post(`${clientBillDocumentRepositoryUrl(clientId)}?connection_id=${getState().app.uid}`, {
                bill_document: values,
            })

            return response.data
        } catch (e) {
            notification.error({
                message: "Request error",
                description: e?.response?.statusText,
            })
            console.error(e)
        }
    }
}

export function updateBillRepository(clientId, repoId, values) {
    return async (dispatch, getState) => {
        try {
            const response = await client.patch(`${clientBillDocumentRepositoryUrl(clientId)}/${repoId}?connection_id=${getState().app.uid}`, {
                bill_document: values,
            })

            return response.data
        } catch (e) {
            notification.error({
                message: "Request error",
                description: e?.response?.statusText,
            })
            console.error(e)
        }
    }
}

export function deleteBillRepository(clientId, repoId) {
    return async (dispatch, getState) => {
        client
            .delete(`${clientBillDocumentRepositoryUrl(clientId)}/${repoId}`)
            .then(({ data }) => {
                dispatch(deleteDocument(repoId))
                notification.success({
                    message: data.message,
                })
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
    }
}

export default billDocumentRepositoriesSlice.reducer
