import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Skeleton, notification, Button, Dropdown, Menu, Tooltip } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { DownloadOutlined, FilterOutlined, EditOutlined, DownOutlined, DeleteOutlined, FileTextOutlined, DashboardOutlined, ContainerOutlined } from "@ant-design/icons"
import nodePath from "path"
import moment from "moment"

import { fetchBills, selectBills, selectTotal, selectPagination, selectLoading, filterBills, generateCsv } from "./pastDueBillsSlice"
import WarningChip from "../../bills/components/subcomponents/WarningChip"
import BillAccountLink from "../../../components/utilityComponents/linkGenerators/BillAccountLink"
import ColumnDateFilter from "../../../components/utilityComponents/table/ColumnDateFilter"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
// import { filterSiteSurveys } from "../../ancillaryServices/siteSurveys/siteSurveysSlice"
// // import { clientDivisionCsvUrl } from "../../../lib/endpoints"

export default function BillsMissingBillsTable({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    let { clientId } = useParams()
    /////*** react router end ***/////

    // /////*** redux start ***/////
    const dispatch = useDispatch()
    const bills = useSelector(selectBills)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    // /////*** redux end ***/////

    // /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    // /////*** useState end ***/////

    // /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialBills()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    // /////*** useEffects end ***/////

    // /////*** event handler methods start ***/////
    const handleFetchInitialBills = () => {
        dispatch(fetchBills(pagination, nodePath.join(url, "")))
    }

    const handleFilterBills = (newPagination = null, newSort = null) => {
        return dispatch(
            filterBills({
                clientId: clientId,
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortBillsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterBills(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchBills(newPagination, nodePath.join(url, "")))
        } else {
            handleFilterBills(newPagination)
        }
    }
    // /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title, altTitle = null) => {
        return <ColumnHeaderTitle title={title} altTitle={altTitle} handleSortChange={handleSortBillsChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialBills()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    const exportCsv = async () => {
        notification.open({
            message: "Export started",
        })

        dispatch(generateCsv(clientId, filters))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Bills 30+ Days Past Due</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {/* {renderResetFilters()} */}

                                <Button type="link" onClick={exportCsv}>
                                    <DownloadOutlined />
                                    Export
                                </Button>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={bills} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title=" "
                                key="flags"
                                dataIndex="flags"
                                render={(flags, record) => <WarningChip contentText={Object.values(flags)} flagCount={Object.values(flags).length} childFlags={record.any_child_flags} />}
                                width={50}
                            />
                            <Table.Column title={renderColumnHeaderTitle("vendor_name", "vendor")} key="vendor_name" dataIndex="vendor_name" />
                            <Table.Column
                                title={renderColumnHeaderTitle("account_of_BillableAccount_type_name", "account")}
                                key="account_name"
                                dataIndex="account_name"
                                render={(text, record) => (
                                    <BillAccountLink accountType={record.account_type} accountId={record.account_id} accountName={record.account_name} clientId={record.client_id} />
                                )}
                            />
                            <Table.Column title="Client Batch" key="accounting_batch_batch_code" dataIndex="accounting_batch_batch_code" />
                            <Table.Column title={renderColumnHeaderTitle("date")} key="date" dataIndex="date" render={(text, record, index) => moment(text).format("L")} />
                            <Table.Column title={renderColumnHeaderTitle("due_date")} key="due_date" dataIndex="due_date" render={(text, record, index) => moment(text).format("L")} />
                            <Table.Column title={renderColumnHeaderTitle("created_at")} key="created_at" dataIndex="created_at" render={(text, record, index) => moment(text).format("L")} />
                            <Table.Column title="Status" key="full_status" dataIndex="full_status" />
                            <Table.Column
                                title="Copy"
                                key="copy"
                                render={(text, record) => (
                                    <>
                                        {record.file_url && (
                                            <Button type="primary" size="small" onClick={() => window.open(record.file_url, "_blank")}>
                                                <DownloadOutlined />
                                            </Button>
                                        )}
                                    </>
                                )}
                            />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <div className="row-actions" style={{ display: "inline-block" }}>
                                            <Link to={`${url}/${record.id}/edit`}>
                                                <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip>
                                            </Link>
                                        </div>
                                        {scopedByClient && (
                                            <div className="row-actions pl-2" style={{ display: "inline-block" }}>
                                                <Link to={`${url}/${record.id}/dashboard`}>
                                                    View
                                                    {/* <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip> */}
                                                </Link>
                                            </div>
                                        )}
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
