import { SettingOutlined } from "@ant-design/icons"
import { Button, Col, Popover, Row, Switch } from "antd"
import React from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import Cookies from "universal-cookie"
import { clearAuthStorage } from "../../lib/storage"
import { submitSignOut } from "../login/loginSlice"

export default function Settings() {
    const dispatch = useDispatch()

    let bodyElement = document.querySelector("body")

    const cookies = new Cookies()

    const history = useHistory()

    const toggleDarkMode = (event) => {
        if (event) {
            cookies.set("theme", "dark", { path: "/" })
            bodyElement.classList.remove("light")
            bodyElement.classList.add("dark")
        } else {
            cookies.set("theme", "light", { path: "/" })
            bodyElement.classList.remove("dark")
            bodyElement.classList.add("light")
        }
    }

    const signOut = async () => {
        if (!window.confirm("Are you sure you want to sign out?")) {
            return
        }

        const response = await dispatch(submitSignOut())

        if (response?.success) {
            clearAuthStorage()
            history.push("/login")
        }
    }

    const panelContent = () => {
        return (
            <div style={{ width: "175px" }}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Link to={`/users/profile`}>User Profile</Link>
                    </Col>
                </Row>

                <Row gutter={24} style={{ marginTop: "10px" }}>
                    <Col span={14}>
                        <label htmlFor="dark-mode-toggle" className="dark-toggle">
                            Dark Mode
                        </label>
                    </Col>
                    <Col span={10}>
                        <Switch
                            id="dark-mode-toggle"
                            defaultChecked={bodyElement.className === "dark"}
                            checkedChildren={"On"}
                            unCheckedChildren={"Off"}
                            onChange={toggleDarkMode}
                            style={{ float: "right" }}
                        />
                    </Col>
                </Row>

                <Row gutter={24} style={{ marginTop: "10px" }}>
                    <Col span={24}>
                        <Button type="link" style={{ width: "100%" }} onClick={signOut}>
                            Sign Out
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div id="settings-panel">
            <Popover placement="bottom" title="Settings" content={panelContent} trigger="click" overlayClassName="alert-overlay">
                <SettingOutlined />
            </Popover>
        </div>
    )
}
