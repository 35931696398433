import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, Switch, notification, Skeleton, InputNumber } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { find, findIndex, mapKeys } from "lodash"
import nodePath from "path"

import { fetchLedgerAccount, selectLedgerAccount, setLedgerAccount, submitLedgerAccount, selectLoading } from "../ledgerAccountsSlice"
import { fetchLedgerFields, selectLedgerFields } from "../../ledgerFields/ledgerFieldsSlice"

import { requestNotification } from "../../../../lib/notifications"

export default function LedgerAccountForm({ actionName }) {
    const { url } = useRouteMatch()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    const ledgerAccount = useSelector(selectLedgerAccount)
    const ledgerFields = useSelector(selectLedgerFields)
    const loading = useSelector(selectLoading)
    const { ledgerAccountId, clientId } = useParams()

    useEffect(() => {
        if (ledgerAccountId) {
            dispatch(fetchLedgerAccount(ledgerAccountId, clientId))
        } else {
            dispatch(setLedgerAccount({}))
        }
        dispatch(fetchLedgerFields(clientId))
        // eslint-disable-next-line
    }, [dispatch, ledgerAccountId])

    useEffect(() => {
        form.resetFields()
    }, [form, ledgerAccount])

    // TODO: will need to handle new vs edit action
    // Can make global library function to help here
    const submit = async (values) => {
        // We need to update the keys to always be passing in "usage_unit_attributes"
        // and "usage_factors_attributes" when posting
        const updatedKeys = mapKeys(values, (value, key) => {
            if (key === "ledger_account_fields") {
                return "ledger_account_fields_attributes"
            } else {
                return key
            }
        })

        const response = await dispatch(submitLedgerAccount({ ...updatedKeys }, ledgerAccountId, clientId))

        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    // Render ledger field input in order
    const renderLedgerFields = (fields) => {
        return ledgerFields?.map((ledgerField, index) => {
            // This is pretty weaponized but this is the high-level breakdown of what's happening here:
            // : We have to pre-set the ledger fields that are being built for this ledger account to be all
            // of the ledger fields for this client -> ledgerFields.
            // : We need to find if the current ledgerField exists for this ledgerAccount in order to populate
            // the input with the correct values for the nested form
            const foundLedgerField = find(ledgerAccount.ledger_account_fields, ["ledger_field_id", ledgerField.id])

            // If we did find that this ledgerField currently exists for this LedgerAccount, we need to populate its
            // values from the ledgerAccount object
            if (foundLedgerField) {
                // This foundIndex will identify where the ledgerField exists in the ledgerAccount's ledgerFields array
                const foundIndex = findIndex(ledgerAccount.ledger_account_fields, ["ledger_field_id", ledgerField.id])
                return (
                    // Populate values here
                    <Row gutter={24} key={ledgerField.id}>
                        <Col span={8}>
                            <Form.Item {...foundLedgerField} label={`${ledgerField.order}. ${ledgerField.name}`} name={[foundIndex, "value"]} fieldKey={[foundIndex, "value"]}>
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        <Form.Item {...foundLedgerField} name={[foundIndex, "ledger_field_id"]} fieldKey={[foundIndex, "ledger_field_id"]} initialValue={ledgerField.id} hidden={true}>
                            <Input type="hidden" />
                        </Form.Item>
                    </Row>
                )
            } else {
                // If this specific ledgerField was not found in the ledgerAccount's list, we have to
                // basically create it as an empty input
                return (
                    <Row gutter={24} key={ledgerField.id}>
                        <Col span={8}>
                            <Form.Item label={`${ledgerField.order}. ${ledgerField.name}`} name={[ledgerField.id, "value"]} fieldKey={[ledgerField.id, "value"]}>
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>

                        <Form.Item name={[ledgerField.id, "ledger_field_id"]} fieldKey={[ledgerField.id, "ledger_field_id"]} initialValue={ledgerField.id} hidden={true}>
                            <Input type="hidden" />
                        </Form.Item>
                    </Row>
                )
            }
        })
    }

    return (
        <div id="user-form-container">
            <Link to={`/clients/${clientId}/ledger_accounts`}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={ledgerAccount} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <Skeleton active loading={loading}>
                            <div>
                                {/* TODO: Clean up this initialValue stuff if possible */}
                                {/* Warnings get posted when trying to set initialValues if they already exist */}
                                {!ledgerAccountId && <Form.Item name="client_id" hidden={true} initialValue={clientId} />}

                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Account Name" name="name" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    {/* TODO: Clean up this initialValue stuff if possible */}
                                    {/* Warnings get posted when trying to set initialValues if they already exist */}
                                    {ledgerAccount ? (
                                        <Col span={8}>
                                            <Form.Item label="Active" name="active" valuePropName="checked" rules={[{ required: false }]}>
                                                <Switch defaultChecked />
                                            </Form.Item>
                                        </Col>
                                    ) : (
                                        <Col span={8}>
                                            <Form.Item label="Active" name="active" valuePropName="checked" initialValue={true} rules={[{ required: false }]}>
                                                <Switch defaultChecked />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>

                                <h2>Ledger Fields</h2>
                                <Form.List name={`${ledgerAccountId ? "ledger_account_fields" : "ledger_account_fields_attributes"}`}>
                                    {(fields, { add, remove }) => <>{renderLedgerFields(fields)}</>}
                                </Form.List>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Skeleton>
                    </div>
                </div>
            </Form>
        </div>
    )
}
