import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../stores/appSlice"
import UtilityServiceTable from "./components/UtilityServiceTable"
import UtilityServiceForm from "./components/UtilityServiceForm"

export default function UtilityServicePage({ view, recordSelected }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const renderView = () => {
        switch (view) {
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            default:
                return renderIndex()
        }
    }

    const renderIndex = () => {
        if (scopedByClient) {
            return (
                <>
                    <UtilityServiceTable scopedByClient={scopedByClient} />
                </>
            )
        }

        return <UtilityServiceTable scopedByClient={scopedByClient} />
    }

    const renderForm = () => {
        if (recordSelected) {
            return (
                <>
                    <h1>Edit Form</h1>
                    <UtilityServiceForm actionName="edit" />
                </>
            )
        }

        return (
            <>
                <h1>New Form</h1>
                <UtilityServiceForm actionName="new" />
            </>
        )
    }

    return <>{renderView()}</>
}
