import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"

import ConsoleLayout from "../ConsoleLayout"
import PaymentBatchNav from "../features/financials/PaymentBatchNav"
import PaymentBatchPage from "../features/financials/paymentBatches/PaymentBatchPage"

export default function SettingsRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout highlightTab="financials" header={<PaymentBatchNav activeTab="list" />} body={<PaymentBatchPage view="index" recordSelected={false} />} sidebarType="groupAccounts" />
            </Route>

            <Route exact path="/clients/:clientId/payment_batches/:paymentBatchId/edit">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<PaymentBatchNav activeTab="paymentBatches" headerText="Vendor Batches" recordSelected={true} />}
                    body={<PaymentBatchPage view="form" recordSelected={true} />}
                    sidebarType="paymentBatches"
                />
            </Route>

            <Route exact path="/clients/:clientId/payment_batches/:paymentBatchId/bills">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<PaymentBatchNav activeTab="bills" headerText="Bills" scopedByPaymentBatch={true} />}
                    body={<PaymentBatchPage view="bills" recordSelected={true} />}
                    sidebarType="paymentBatches"
                />
            </Route>

            <Route exact path="/clients/:clientId/payment_batches/:paymentBathchId/bills">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<PaymentBatchNav activeTab="bills" headerText="Bills" scopedByAccountingBatch={true} />}
                    body={<PaymentBatchPage view="bills" recordSelected={true} />}
                    sidebarType="paymentBatches"
                />
            </Route>

            <Route exact path="/clients/:clientId/payment_batches/:paymentBatchId/vendor_payments/:utilityPaymentId/edit">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<PaymentBatchNav activeTab="utilityPayments" headerText="Vendor Payments" scopedByPaymentBatch={true} />}
                    body={<PaymentBatchPage view="utilityPaymentForm" recordSelected={true} />}
                    sidebarType="paymentBatches"
                />
            </Route>

            <Route exact path="/clients/:clientId/payment_batches/:paymentBatchId/vendor_payments">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<PaymentBatchNav activeTab="utilityPayments" headerText="Vendor Payments" scopedByPaymentBatch={true} />}
                    body={<PaymentBatchPage view="utilityPayments" recordSelected={true} />}
                    sidebarType="paymentBatches"
                />
            </Route>

            <Route exact path="/clients/:clientId/payment_batches/:paymentBatchId/vendor_payments/:utilityPaymentId/bills">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<PaymentBatchNav activeTab="bills" headerText="Bills" scopedByAccountingBatch={true} />}
                    body={<PaymentBatchPage view="bills" recordSelected={true} />}
                    sidebarType="paymentBatches"
                />
            </Route>

            <Route exact path="/clients/:clientId/payment_batches/new">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<PaymentBatchNav activeTab="paymentBatches" headerText="Vendor Batches" />}
                    body={<PaymentBatchPage view="form" recordSelected={false} />}
                    sidebarType="paymentBatches"
                />
            </Route>

            <Route exact path="/clients/:clientId/payment_batches">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<PaymentBatchNav activeTab="list" headerText="Vendor Batches" />}
                    body={<PaymentBatchPage view="index" />}
                    sidebarType="paymentBatches"
                />
            </Route>
        </Switch>
    )
}
