import React from "react"
import { Form, Input, InputNumber, Checkbox } from "antd"
import { CloseOutlined } from "@ant-design/icons"

function SiteSurveysLightingFormPiece(props) {
    return (
        <div className={"site-surveys-lighting-form-piece"}>
            <CloseOutlined className={"close-icon"} onClick={props.remove} />
            <Form.Item {...props} name={[props.name, "location"]} fieldKey={[props.fieldKey, "location"]} label={"Location"}>
                <Input />
            </Form.Item>

            <Form.Item {...props} name={[props.name, "updated"]} fieldKey={[props.fieldKey, "updated"]} label={"Updated"} valuePropName="checked">
                <Checkbox />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "a1000w_mh"]} fieldKey={[props.fieldKey, "a1000w_mh"]} label={"A1000w Mh"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "a1000w_mh_no"]} fieldKey={[props.fieldKey, "a1000w_mh_no"]} label={"A1000w Mh No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "a320w_ps"]} fieldKey={[props.fieldKey, "a320w_ps"]} label={"A320w Ps"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "a320w_ps_no"]} fieldKey={[props.fieldKey, "a320w_ps_no"]} label={"Mobile Phone"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "a400w_mh"]} fieldKey={[props.fieldKey, "a400w_mh"]} label={"A400w Mh"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "a400w_mh_no"]} fieldKey={[props.fieldKey, "a400_mh_no"]} label={"A400w Mh No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "a750w_ps"]} fieldKey={[props.fieldKey, "a750w_ps"]} label={"A750w Ps"} valuePropName="checked" >
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "a750w_ps_no"]} fieldKey={[props.fieldKey, "a750_ps_no"]} label={"A750w Ps No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "cfl"]} fieldKey={[props.fieldKey, "cfl"]} label={"Cfl"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "cfl_no"]} fieldKey={[props.fieldKey, "cfl_no"]} label={"Cfl No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "incandescent"]} fieldKey={[props.fieldKey, "incandescent"]} label={"Incandescent"} valuePropName="checked" >
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "incandescent_no"]} fieldKey={[props.fieldKey, "incandescent_no"]} label={"Incandescent No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "induction"]} fieldKey={[props.fieldKey, "induction"]} label={"Induction"} valuePropName="checked" >
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "induction_no"]} fieldKey={[props.fieldKey, "induction_no"]} label={"Induction No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "led"]} fieldKey={[props.fieldKey, "led"]} label={"LED"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "led_no"]} fieldKey={[props.fieldKey, "led_no"]} label={"LED No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "skylights"]} fieldKey={[props.fieldKey, "skylights"]} label={"Skylights"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "skylights_no"]} fieldKey={[props.fieldKey, "skylights_no"]} label={"Skylights No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "t12"]} fieldKey={[props.fieldKey, "t12"]} label={"T12"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "t12_no"]} fieldKey={[props.fieldKey, "t12_no"]} label={"T12 No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "t5"]} fieldKey={[props.fieldKey, "t5"]} label={"T5"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "t5_no"]} fieldKey={[props.fieldKey, "t5_no"]} label={"T5 No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "t8"]} fieldKey={[props.fieldKey, "t8"]} label={"T8"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "t8_no"]} fieldKey={[props.fieldKey, "t8_no"]} label={"T8 No"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
        </div>
    )
}

export default SiteSurveysLightingFormPiece
