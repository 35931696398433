import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import BillNav from "../features/bills/BillNav"
import BillPage from "../features/bills/BillPage"

export default function BillRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout highlightTab="bills" header={<BillNav activeTab="list" />} body={<BillPage view="index" recordSelected={false} />} sidebarType="bills" />
            </Route>

            <Route path={`${path}/import`}>
                <ConsoleLayout highlightTab="bills" header={<BillNav activeTab="list" />} body={<BillPage view="import" recordSelected={false} />} sidebarType="bills" />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout highlightTab="bills" header={<BillNav activeTab="list" />} body={<BillPage view="form" recordSelected={false} />} sidebarType="bills" />
            </Route>

            <Route path={`${path}/dashboard`}>
                <ConsoleLayout highlightTab="bills" header={<BillNav activeTab="dashboard" />} body={<BillPage view="dashboard" recordSelected={false} />} sidebarType="bills" />
            </Route>

            <Route exact path={`${path}/bill_document_repository`}>
                <ConsoleLayout highlightTab="bills" header={<BillNav activeTab="repository" />} body={<BillPage view="repository" recordSelected={false} />} sidebarType="bills" />
            </Route>

            <Route path={`${path}/bill_document_repository/new`}>
                <ConsoleLayout highlightTab="bills" header={<BillNav activeTab="repository" />} body={<BillPage view="repositoryForm" recordSelected={false} actionName="new" />} sidebarType="bills" />
            </Route>

            <Route path={`${path}/bill_document_repository/:billDocumentId/edit`}>
                <ConsoleLayout
                    highlightTab="bills"
                    header={<BillNav activeTab="repository" />}
                    body={<BillPage view="repositoryForm" recordSelected={false} actionName="edit" />}
                    sidebarType="bills"
                />
            </Route>

            <Route path={`${path}/:billId/edit`}>
                <ConsoleLayout highlightTab="bills" header={<BillNav activeTab="list" scopedByBill={false} />} body={<BillPage view="form" recordSelected={true} />} sidebarType="bills" />
            </Route>

            <Route path={`${path}/:billId/dashboard`}>
                <ConsoleLayout highlightTab="bills" header={<BillNav activeTab="dashboard" scopedByBill={true} />} body={<BillPage view="dashboard" recordSelected={true} />} sidebarType="bills" />
            </Route>

            <Route path={`${path}/:billId`}>
                <ConsoleLayout highlightTab="bills" header={<BillNav activeTab="dashboard" scopedByBill={true} />} body={<BillPage view="dashboard" recordSelected={true} />} sidebarType="bills" />
            </Route>
        </Switch>
    )
}
