import React from "react"
import { Link } from "react-router-dom"

export default function BillVendorPaymentLink({ clientId, paymentBatchId, utilityPaymentId, utilityPaymentName }) {
    return (
        <Link to={`/clients/${clientId}/payment_batches/${paymentBatchId}/vendor_payments/${utilityPaymentId}/edit`} target="_blank">
            {utilityPaymentName}
        </Link>
    )
}
