import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { selectPagination, fetchGeneratedReports, selectGeneratedReportsTotal, selectLoading, selectGeneratedReports, filterGeneratedReports } from "./reportsSlice"

import { Link, useParams, useRouteMatch } from "react-router-dom"
import { allObjectValuesEmpty } from "../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../functions/objectFunctions/tableFiltersApplied"
import ColumnSearchFilter from "../../components/utilityComponents/table/ColumnSearchFilter"

import { DownloadOutlined, PlusOutlined, UploadOutlined, EditOutlined, SearchOutlined, FilterOutlined, FileTextOutlined } from "@ant-design/icons"

import { Table, Tooltip, Skeleton } from "antd"
import path from "path"

import moment from 'moment'

export default function GeneratedReports() {
    const dispatch = useDispatch()

    const { url } = useRouteMatch()

    const { clientId } = useParams()

    const [init, setInit] = useState(false)
    const [filters, setFilters] = useState({})

    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })

    const pagination = useSelector(selectPagination)
    const total = useSelector(selectGeneratedReportsTotal)
    const loading = useSelector(selectLoading)
    const generatedReports = useSelector(selectGeneratedReports)

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        handleFetchGeneratedReports()
    }, [dispatch, init])

    const handleFetchGeneratedReports = () => {
        dispatch(fetchGeneratedReports(clientId, pagination))
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchGeneratedReports()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchGeneratedReports(clientId, newPagination))
        } else {
            handleFilterGeneratedReports(newPagination)
        }
    }

    const handleFilterGeneratedReports = (newPagination = null, newSort = null) => {
        return dispatch(
            filterGeneratedReports({
                clientId: clientId,
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const renderColumnHeaderTitle = (title) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortGeneratedReportsChange} sort={sort} />
    }

    const handleSortGeneratedReportsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterGeneratedReports(null, newSort)
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Generated Reports</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <a href="javascript:;" onClick={() => console.log("Import")}>
                                    <UploadOutlined />
                                    Import
                                </a>

                                <a href="javascript:;" onClick={() => console.log("Export")}>
                                    <DownloadOutlined />
                                    Export
                                </a>
                            </div>
                        </div>
                    </div>
                    <Skeleton active loading={loading}>
                        <Table dataSource={generatedReports} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            {/*
                               Document Type,
                               Title,
                               File,
                               Assigned To,
                               Created By,
                               Date Created,
                               Date Updated
                             */}
                            <Table.Column
                                title={renderColumnHeaderTitle("report")}
                                key="report_name"
                                dataIndex="report"
                                render={(gr) => gr?.name}
                                filterDropdown={() => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchGeneratedReports}
                                        filterAction={filterGeneratedReports}
                                        filterColumn={"report_name"}
                                        filterType={"report_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("report_name_cont")}
                            />

                            <Table.Column
                                title={renderColumnHeaderTitle("name")}
                                key="name"
                                dataIndex="name"
                                filterDropdown={() => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchGeneratedReports}
                                        filterAction={filterGeneratedReports}
                                        filterColumn={"name"}
                                        filterType={"name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />

                            <Table.Column
                                // title={renderColumnHeaderTitle("assignable_name")}
                                title="Assigned To"
                                key="assignable_name"
                                dataIndex="assignable_name"
                                render={(gr) => gr}
                                // filterDropdown={() => (
                                //     <ColumnSearchFilter
                                //         handleFetchInitial={handleFetchGeneratedReports}
                                //         filterAction={filterGeneratedReports}
                                //         filterColumn={"assignable_name"}
                                //         filterType={"assignable_name"}
                                //         filters={filters}
                                //         pagination={pagination}
                                //         setFilters={setFilters}
                                //         sort={sort}
                                //         setSort={setSort}
                                //     />
                                // )}
                                // filterIcon={() => renderSearchOutlinedButton("assignable_name")}
                            />

                            <Table.Column
                                title={renderColumnHeaderTitle("Created By")}
                                key="user"
                                dataIndex="user"
                                render={(u) => `${u?.first_name} ${u?.last_name}`}
                                filterDropdown={() => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchGeneratedReports}
                                        filterAction={filterGeneratedReports}
                                        filterColumn={"user"}
                                        filterType={"user"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("user")}
                            />

                            <Table.Column
                                title={renderColumnHeaderTitle("created_at")}
                                key="created_at"
                                dataIndex="created_at"
                                filterDropdown={() => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchGeneratedReports}
                                        filterAction={filterGeneratedReports}
                                        filterColumn={"created_at"}
                                        filterType={"created_at_eq"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("created_at_eq")}
                                render={(text, record) => moment(record.created_at).format("L")}
                            />

                            <Table.Column
                                title={renderColumnHeaderTitle("updated_at")}
                                key="updated_at"
                                dataIndex="updated_at"
                                filterDropdown={() => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchGeneratedReports}
                                        filterAction={filterGeneratedReports}
                                        filterColumn={"updated_at"}
                                        filterType={"updated_at_eq"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("updated_at_eq")}
                                render={(text, record) => moment(record.updated_at).format("L")}
                            />

                            <Table.Column
                                title="File"
                                key="download"
                                render={(text, record) => {
                                    if (!record.file_url) {
                                        return null
                                    }

                                    return (
                                        <div className="row-actions" style={{ display: "inline-block" }}>
                                            <a href={record.file_url} target="_blank">
                                                <Tooltip title="View Report">
                                                    <FileTextOutlined />
                                                </Tooltip>
                                            </a>
                                        </div>
                                    )
                                }}
                            />

                            <Table.Column
                                title="Add'l File"
                                key="download"
                                render={(text, record) => {
                                    if (!record.extra_file_url) {
                                        return null
                                    }

                                    return (
                                        <div className="row-actions" style={{ display: "inline-block" }}>
                                            <a href={record.extra_file_url} target="_blank">
                                                <Tooltip title="View Report">
                                                    <FileTextOutlined />
                                                </Tooltip>
                                            </a>
                                        </div>
                                    )
                                }}
                            />

                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <div className="row-actions" style={{ display: "inline-block" }}>
                                            <Link to={path.join(url, "..", `${record.report_id}`, "generated-reports", `${record.id}`, "edit")}>
                                                <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip>
                                            </Link>
                                        </div>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}
