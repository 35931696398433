import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { debounce } from "lodash"

import {
    fetchAccountingBatches,
    selectAccountingBatches,
    selectPagination,
    selectTotal,
    selectAccountingBatchSearch,
    searchAccountingBatches,
} from "../../../financials/accountingBatches/accountingBatchesSlice"

import PaginatedSelect from "../../../forms/components/PaginatedSelect"
import { Form } from "antd"

export default function ReportAccountingBatches() {
    const { clientId } = useParams()

    const dispatch = useDispatch()

    const pagination = useSelector(selectPagination)

    const handleSearch = debounce((value) => {
        // make sure they've typed at least 3 characters
        if (value && value.length >= 3) {
            dispatch(searchAccountingBatches(value, `clients/${clientId}/accounting_batches/search`))
        }
    }, 1000)

    return (
        <div>
            <Form.List name="form_data">
                {(fields) => (
                    <Form.Item label="Client Batch" name="accounting_batch_ids">
                        <PaginatedSelect
                            mode="multiple"
                            placeholder="Search client batches..."
                            onSearch={handleSearch}
                            paginationSelector={selectAccountingBatches}
                            paginationTotalSelector={selectTotal}
                            loadPaginatedData={(p) => fetchAccountingBatches(clientId, p)}
                            searchSelector={selectAccountingBatchSearch}
                            renderOptionValue={(o) => o.batch_code}
                        />
                    </Form.Item>
                )}
            </Form.List>
        </div>
    )
}
