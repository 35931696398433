import { notification } from "antd"

/**
 * Create an antd notification based on a server response.
 * Typically used for CRUD actions where there is a success and message field.
 *
 * @param data axios response `data` attribute
 */
export function requestNotification(data) {
    try {
        console.log(data)
        if (data.success) {
            notification.success({
                message: "Success",
                description: data.message,
            })
        } else {
            notification.error({
                message: "Error",
                description: data.message.join(". "),
                // description: <>`${data.message.join(<br />)}`</>,
                // description: (
                //     <>
                //         test
                //         <br />
                //         test
                //     </>
                // ),
            })
        }
    } catch (e) {
        notification.warn({
            message: "Error",
            description: "Unknown error: Could not parse server response",
        })
    }
}

export default requestNotification
