import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useRouteMatch, Link } from "react-router-dom"
import { Table, Skeleton, Tooltip, Button } from "antd"
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"

import { fetchUserRoles, selectUserRoles, selectLoading, deleteUserRole } from "./userRolesSlice"
import { titleize } from "../../lib/string"

export default function UserRoleTable() {
    const dispatch = useDispatch()

    const { userId } = useParams()
    const { url } = useRouteMatch()

    const [init, setInit] = useState(false)

    const userRoles = useSelector(selectUserRoles)
    const loading = useSelector(selectLoading)

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        dispatch(fetchUserRoles(userId))
    }, [dispatch, init, userId])

    const deleteRole = (roleId) => {
        if (!window.confirm("Are you sure you want to delete this user role?")) {
            return
        }

        dispatch(deleteUserRole(userId, roleId))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>User Roles</h2>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                <Link to={`${url}/new`}>
                                    <PlusOutlined />
                                    Add New
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Skeleton active loading={loading}>
                        <Table dataSource={userRoles} rowKey={(r) => r.id}>
                            <Table.Column title="Role" key="role_name" dataIndex="role_name" render={(r) => titleize(r)} />

                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <div className="row-actions" style={{ display: "inline-block" }}>
                                            <Link to={`${url}/${record.id}/edit`}>
                                                <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip>
                                            </Link>
                                            <Button danger type="text" onClick={() => deleteRole(record.id)} className="no-bg">
                                                <Tooltip title="Delete">
                                                    <DeleteOutlined />
                                                </Tooltip>
                                            </Button>
                                        </div>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}
