import { Form } from "antd"
import { debounce } from "lodash"
import React from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchPaymentBatches, searchPaymentBatches, selectPaymentBatches, selectPaymentBatchSearch, selectTotal } from "../../../financials/paymentBatches/paymentBatchesSlice"
import PaginatedSelect from "../../../forms/components/PaginatedSelect"

export default function ReportPaymentBatches() {
    const dispatch = useDispatch()

    const { clientId } = useParams()

    const handleSearch = debounce((value) => {
        if (value && value.length >= 1) {
            dispatch(searchPaymentBatches(value, clientId))
        }
    }, 1000)

    return (
        <div>
            <Form.List name="form_data">
                {(fields) => (
                    <Form.Item label="Vendor Batch" name="payment_batch_ids">
                        <PaginatedSelect
                            mode="multiple"
                            placeholder="Search vendor batches..."
                            onSearch={handleSearch}
                            paginationSelector={selectPaymentBatches}
                            paginationTotalSelector={selectTotal}
                            loadPaginatedData={(p) => fetchPaymentBatches(clientId, p)}
                            searchSelector={selectPaymentBatchSearch}
                            renderOptionValue={(p) => p.id}
                        />
                    </Form.Item>
                )}
            </Form.List>
        </div>
    )
}
