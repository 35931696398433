import React from "react"
import { Link } from "react-router-dom"

export default function UserNav({ activeTab }) {
    return (
        <div>
            <h2>Users</h2>
            <ul className="header-tab-nav" role="navigation">
                <li>
                    <Link to={"/users"}>
                        <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                            All Users
                        </button>
                    </Link>
                </li>
            </ul>
        </div>
    )
}
