import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import ChannelPartnerNav from "../features/channelPartners/ChannelPartnerNav"
import ChannelPartnerPage from "../features/channelPartners/ChannelPartnerPage"

export default function ChannelPartnerRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout
                    highlightTab="channelPartners"
                    header={<ChannelPartnerNav activeTab="list" />}
                    body={<ChannelPartnerPage view="index" recordSelected={false} />}
                    sidebarType="channelPartners"
                />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout
                    highlightTab="channelPartners"
                    header={<ChannelPartnerNav activeTab="list" />}
                    body={<ChannelPartnerPage view="form" recordSelected={false} />}
                    sidebarType="channelPartners"
                />
            </Route>

            <Route path={`${path}/:channelPartnerId/edit`}>
                <ConsoleLayout
                    highlightTab="channelPartners"
                    header={<ChannelPartnerNav activeTab="list" scopedByChannelPartner={false} />}
                    body={<ChannelPartnerPage view="form" recordSelected={true} />}
                    sidebarType="channelPartners"
                />
            </Route>
        </Switch>
    )
}
