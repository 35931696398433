import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

// http client
import client from "../../lib/client"
import { billableAccountSearchUrl, billableAccountsScopedByClientUrl, billableAccountsUrl, billableAccountUrl, dynamicUrl, clientBillableAccountsCsvUrl } from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../functions/objectFunctions/createRansackParams"

export const billableAccountsSlice = createSlice({
    name: "billableAccounts",
    initialState: {
        billableAccount: {},
        billableAccounts: [],
        billableAccountSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
        billableAccountSearch: [],
    },
    reducers: {
        setBillableAccount: (state, action) => {
            state.billableAccount = action.payload
        },
        setBillableAccounts: (state, action) => {
            state.total = action.payload.total
            state.billableAccounts = action.payload.billable_accounts
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setBillableAccountSelectOptions: (state, action) => {
            state.billableAccountSelectOptions = action.payload
        },
        setBillableAccountSearch: (state, action) => {
            state.billableAccountSearch = action.payload
        },
        deleteBillableAccount: (state, action) => {
            state.billableAccounts = state.billableAccounts.filter((billableAccount) => billableAccount.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const { setBillableAccount, setBillableAccounts, setPagination, setLoading, setBillableAccountSelectOptions, setBillableAccountSearch } = billableAccountsSlice.actions

export function fetchBillableAccount(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(billableAccountUrl(id)).then((response) => {
            dispatch(setBillableAccount(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchBillableAccounts(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${dynamicUrl(currentPath)}?values=[${pagination.values}]`)
            return response.data
        }

        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setBillableAccounts(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClientBillableAccounts(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientBillableAccountsPath = `clients/${clientId}/billable_accounts/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientBillableAccountsPath)}`)
        return response.data.billable_accounts
    }
}

export function submitBillableAccount(values, billableAccountId) {
    return async (dispatch, getState) => {
        if (billableAccountId) {
            // patch - update
            const response = await client.patch(billableAccountUrl(billableAccountId), { billable_account: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(billableAccountsUrl, { billable_account: values })
            return response.data
        }
    }
}

export function deleteBillableAccount(billableAccountId) {
    return async (dispatch, getState) => {
        client
            .delete(`${billableAccountUrl(billableAccountId)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                    })

                    dispatch(billableAccountsSlice.actions.deleteBillableAccount(billableAccountId))
                } else {
                    notification.error({
                        message: data.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
    }
}

// export function fetchBillableAccountSelectOptions(searchTerm) {
//     return async (dispatch, getState) => {
//         client.get(`${billableAccountSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
//             dispatch(setBillableAccountSelectOptions(response.data.billableAccounts))
//         })
//     }
// }

// export function fetchBillableAccountSelectOptionById(id) {
//     return async (dispatch, getState) => {
//         client.get(`${billableAccountSelectOptionByIdUrl}?billableAccount_id=${id}`).then((response) => {
//             dispatch(setBillableAccountSelectOptions(response.data.billableAccounts))
//         })
//     }
// }

export function searchBillableAccounts(query) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${billableAccountSearchUrl}?q=${query}`)
            .then(({ data }) => {
                dispatch(setBillableAccountSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function filterBillableAccounts(query, { facilityId } = {}) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, billableAccountsScopedByClientUrl(query.clientId))
    if (facilityId) {
        filterUrl += "&facility_id=" + facilityId
    }

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setBillableAccounts(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateCsv(clientId, filters) {
    return async (dispatch, getState) => {
        client.get(`${clientBillableAccountsCsvUrl(clientId)}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectBillableAccount = (state) => state.billableAccounts.billableAccount
export const selectBillableAccounts = (state) => state.billableAccounts.billableAccounts
export const selectTotal = (state) => state.billableAccounts.total
export const selectPagination = (state) => state.billableAccounts.pagination
export const selectLoading = (state) => state.billableAccounts.loading
export const selectBillableAccountSelectOptions = (state) => state.billableAccounts.billableAccountSelectOptions
export const selectBillableAccountSearch = (state) => state.billableAccounts.billableAccountSearch

export default billableAccountsSlice.reducer
