import React from 'react'
import { Form } from "antd"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from "lodash"
import PaginatedSelect from '../../../forms/components/PaginatedSelect'
import { clientFacilitiesSearchUrl } from '../../../../lib/endpoints'

import {
    fetchFacilities,
    selectFacilities,
    selectPagination,
    selectTotal,
    selectFacilitySearch,
    searchFacilities
} from '../../../facilities/facilitiesSlice'

export default function ReportFacilities() {
    const { clientId } = useParams()

    const dispatch = useDispatch()

    const pagination = useSelector(selectPagination)
    const facilities = useSelector(selectFacilities)

    const handleSearch = debounce((value) => {
        // make sure they've typed at least 3 characters
        if (value && value.length >= 3) {
            dispatch(searchFacilities(value, clientFacilitiesSearchUrl(clientId)))
        }
    }, 1000)

    return (
        <div>
            <Form.List name="form_data">
                {
                    fields => (
                        <Form.Item label="Facility" name="facility_ids">
                            <PaginatedSelect
                                mode='multiple'
                                placeholder='Search facilities...'
                                onSearch={handleSearch}
                                paginationSelector={selectFacilities}
                                paginationTotalSelector={selectTotal}
                                loadPaginatedData={(p) => fetchFacilities(p, `clients/${clientId}/facilities`)}
                                searchSelector={selectFacilitySearch}
                            />
                        </Form.Item>
                    )
                }
            </Form.List>
        </div>
    )
}
