import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Skeleton, notification, Button, Dropdown, Menu } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { DownloadOutlined, FilterOutlined, DownOutlined, EditOutlined, DeleteOutlined, FileTextOutlined, DashboardOutlined, ContainerOutlined } from "@ant-design/icons"
import nodePath from "path"
import moment from "moment"

import { fetchBillableAccounts, selectBillableAccounts, selectTotal, selectPagination, selectLoading, filterBillableAccounts, generateCsv } from "./billableAccountsConsecutiveEstimationsSlice"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"

export default function BillableAccountsConsecutiveEstimationsTable({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    let { clientId } = useParams()
    /////*** react router end ***/////

    // /////*** redux start ***/////
    const dispatch = useDispatch()
    const billableAccounts = useSelector(selectBillableAccounts)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    // /////*** redux end ***/////

    // /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    // /////*** useState end ***/////

    // /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialBillableAccounts()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    // /////*** useEffects end ***/////

    // /////*** event handler methods start ***/////
    const handleFetchInitialBillableAccounts = () => {
        dispatch(fetchBillableAccounts(pagination, nodePath.join(url, "")))
    }

    const handleFilterBillableAccounts = (newPagination = null, newSort = null) => {
        return dispatch(
            filterBillableAccounts({
                clientId: clientId,
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortBillableAccountsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterBillableAccounts(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchBillableAccounts(newPagination, nodePath.join(url, "")))
        } else {
            handleFilterBillableAccounts(newPagination)
        }
    }
    // /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title, altTitle = null) => {
        return <ColumnHeaderTitle title={title} altTitle={altTitle} handleSortChange={handleSortBillableAccountsChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialBillableAccounts()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    const exportCsv = async () => {
        notification.open({
            message: "Export started",
        })

        dispatch(generateCsv(clientId, filters))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Billable Accounts With Three Consecutive Estimated Reads</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {/* {renderResetFilters()} */}

                                <Button type="link" onClick={exportCsv}>
                                    <DownloadOutlined />
                                    Export
                                </Button>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={billableAccounts} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column title={renderColumnHeaderTitle("address")} key="address" dataIndex="address" />
                            <Table.Column title={renderColumnHeaderTitle("facility_name", "Facility")} key="facility_id" dataIndex="facility" render={(facility) => facility?.name} />
                            <Table.Column title={renderColumnHeaderTitle("vendor_name", "vendor")} key="vendor_id" render={(record) => record.vendor?.name} />
                            <Table.Column title={renderColumnHeaderTitle("account_number", "Account #")} key="account_number" dataIndex="account_number" />
                            <Table.Column title={renderColumnHeaderTitle("name")} key="name" dataIndex="name" />
                            <Table.Column title="Services" key="service_names" dataIndex="service_names" />
                            <Table.Column
                                title={renderColumnHeaderTitle("last_bill_date")}
                                key="last_bill_date"
                                render={(record) => (record.last_bill_date ? moment(record.last_bill_date).format("L") : "")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("last_bill_due_date")}
                                key="last_bill_due_date"
                                render={(record) => (record.last_bill_due_date ? moment(record.last_bill_due_date).format("L") : "")}
                            />
                            <Table.Column
                                title=""
                                key="last_bill_file"
                                render={(record) => (
                                    <>
                                        {record.last_bill_file_url && (
                                            <Button type="primary" size="small" onClick={() => window.open(record.last_bill_file_url, "_blank")}>
                                                <DownloadOutlined />
                                            </Button>
                                        )}
                                    </>
                                )}
                            />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Menu.Item key="1" icon={<FileTextOutlined />}>
                                                        <Link to={`/clients/${clientId}/billable_accounts/${record.id}/bills`}>Bills</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="2" icon={<DashboardOutlined />}>
                                                        <Link to={`/clients/${clientId}/billable_accounts/${record.id}/services`}>Services</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="3" icon={<ContainerOutlined />}>
                                                        <Link to={`/clients/${clientId}/billable_accounts/${record.id}/documents`}>Documents</Link>
                                                    </Menu.Item>
                                                    <Menu.Item key="3" icon={<EditOutlined />}>
                                                        <Link to={`/clients/${clientId}/billable_accounts/${record.id}/edit`}>Edit Account</Link>
                                                    </Menu.Item>
                                                    {/* <Menu.Item key="3" icon={<DeleteOutlined />}>
                                                        Delete Account (not hooked up)
                                                    </Menu.Item> */}
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
