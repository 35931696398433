import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../stores/appSlice"
import BillableAccountTable from "./components/BillableAccountTable"
import BillableAccountForm from "./components/BillableAccountForm"
import BillTable from "../bills/components/BillTable"
import ServiceTable from "../services/components/ServiceTable"
import ServiceForm from "../services/components/ServiceForm"
// import BillTable from "../billableAccounts/components/BillableAccountTable"
import DocumentsTable from "../documents/components/DocumentsTable"
import DocumentForm from "../documents/components/DocumentForm"
import { useParams } from "react-router"
import ImportForm from "../imports/ImportForm"
import Dashboard from "../../features/dashboards/components/Dashboard"

export default function BillableAccountPage({ view, recordSelected }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const { clientId, billableAccountId } = useParams()

    const renderView = () => {
        switch (view) {
            case "dashboard":
                return renderDashboard()
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            case "services":
                return renderServices()
            case "serviceForm":
                return renderServiceForm()
            case "bills":
                return renderBills()
            case "documents":
                return renderDocuments()
            case "documents-form":
                return renderDocumentsForm()
            case "import":
                return renderImport()
            default:
                return renderDashboard()
        }
    }

    const renderDashboard = () => {
        return <Dashboard scopeType="BillableAccount" scopeId={billableAccountId} clientId={clientId} />
    }

    const renderIndex = () => {
        if (scopedByClient) {
            return (
                <>
                    <BillableAccountTable scopedByClient={scopedByClient} />
                </>
            )
        }

        return <BillableAccountTable scopedByClient={scopedByClient} />
    }

    const renderForm = () => {
        if (recordSelected) {
            return (
                <>
                    <h1>Edit Form</h1>
                    <BillableAccountForm actionName="edit" />
                </>
            )
        }

        return (
            <>
                <h1>New Form</h1>
                <BillableAccountForm actionName="new" />
            </>
        )
    }

    const renderServices = () => {
        return (
            <>
                <h1>Billable Account's Services</h1>
                <ServiceTable scopedByClient={scopedByClient} />
            </>
        )
    }

    const renderServiceForm = () => {
        if (recordSelected) {
            return (
                <>
                    <h1>Edit Form</h1>
                    <ServiceForm actionName="edit" scopedByBillableAccount={true} />
                </>
            )
        }

        return (
            <>
                <h1>New Form</h1>
                <ServiceForm actionName="new" scopedByBillableAccount={true} />
            </>
        )
    }

    const renderDocuments = () => {
        return <DocumentsTable type="BillableAccount" id={billableAccountId} display="Billable Account" />
    }

    const renderDocumentsForm = () => {
        return <DocumentForm type="BillableAccount" id={billableAccountId} />
    }

    const renderBills = () => {
        return <BillTable scopedByClient={scopedByClient} accountType={"billable_account"} accountId={billableAccountId} />
    }

    const renderImport = () => {
        return <ImportForm dataType="billableAccount" />
    }

    return <>{renderView()}</>
}
