import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../../lib/client"
import { ledgerFieldsUrl, ledgerFieldUrl, ledgerFieldsSidebar, dynamicUrl } from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"
import { notification } from "antd"

export const ledgerFieldsSlice = createSlice({
    name: "ledgerFields",
    initialState: {
        ledgerField: {},
        ledgerFields: [],
        ledgerFieldSelectOptions: [],
        ledgerFieldSearch: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setLedgerField: (state, action) => {
            state.ledgerField = action.payload
        },
        setLedgerFields: (state, action) => {
            state.ledgerFields = action.payload.ledger_fields
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setLedgerFieldSelectOptions: (state, action) => {
            state.ledgerFieldSelectOptions = action.payload
        },
        setLedgerFieldSearch: (state, action) => {
            state.ledgerFieldSearch = action.payload
        },
    },
})

export const { setLedgerField, setLedgerFields, setPagination, setLoading, setLedgerFieldSelectOptions, setLedgerFieldSearch } = ledgerFieldsSlice.actions

export function fetchLedgerField(id, clientId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(ledgerFieldUrl(id, clientId)).then((response) => {
            dispatch(setLedgerField(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchLedgerFields(clientId, pagination) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        // Just wanted to reuse this functionality without having to write another function.
        // The else statement is for the ledger accounts form where we want all results - not just paginated
        if (pagination) {
            client.get(`${ledgerFieldsUrl(clientId)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
                dispatch(setPagination(pagination))
                dispatch(setLedgerFields(response.data))
                dispatch(setLoading(false))
            })
        } else {
            client.get(`${ledgerFieldsUrl(clientId)}`).then((response) => {
                dispatch(setPagination(pagination))
                dispatch(setLedgerFields(response.data))
                dispatch(setLoading(false))
            })
        }
    }
}

export function fetchLedgerFieldsSidebar(clientId) {
    return async (dispatch, getState) => {
        const response = await client.get(`${ledgerFieldsSidebar(clientId)}`)
        return response.data.ledger_fields
    }
}

export function fetchClientLedgerFields(clientId, pagination = null) {
    return async (dispatch, getState) => {
        const clientLedgerFieldsPath = `clients/${clientId}/ledgerFields/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientLedgerFieldsPath)}`)
        return response.data.ledgerFields
    }
}

export function fetchClientLedgerFieldsSelectOptions(clientId) {
    return async (dispatch) => {
        client
            .get(ledgerFieldsSidebar(clientId))
            .then(({ data }) => {
                if (data?.success || data?.ledger_fields) {
                    dispatch(setLedgerFieldSelectOptions(data?.ledger_fields))
                } else {
                    /**
                     * Sometimes the client does not have access to Ledger Accounts, and therefore
                     * this endpoint throws a `success: false` response. To keep anything from breaking,
                     * we have to check that success flag and show the user an error.
                     *
                     * If we want to fail silently, we don't have to pop a notification, but then the area
                     * in the form will be blank for no reason.
                     */
                    notification.error({
                        message: "Ledger Field Error",
                        description: data?.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Ledger Field Error",
                    description: e?.response?.statusText,
                })
            })
    }
}

export function submitLedgerField(values, id, clientId) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(ledgerFieldUrl(id, clientId), { ledger_field: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(ledgerFieldsUrl(clientId), { ledger_field: values })
            return response.data
        }
    }
}

// TODO: will need to scope this as well
// export function fetchLedgerFieldSelectOptions(searchTerm, currentPath) {
//     return async (dispatch, getState) => {
//         client.get(`${ledgerFieldSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
//             dispatch(setLedgerFieldSelectOptions(response.data.ledgerFields))
//         })
//     }
// }

// export function fetchLedgerFieldSelectOptionById(id, currentPath) {
//     return async (dispatch, getState) => {
//         client.get(`${ledgerFieldSelectOptionByIdUrl}?ledgerField_id=${id}`).then((response) => {
//             dispatch(setLedgerFieldSelectOptions(response.data.ledgerField))
//         })
//     }
// }

// export function searchLedgerFields(query) {
//     return async (dispatch) => {
//         dispatch(setLoading(true))

//         client
//             .get(`${ledgerFieldSearchUrl}?q=${query}`)
//             .then(({ data }) => {
//                 dispatch(setLedgerFieldSearch(data))
//             })
//             .catch((error) => {
//                 console.warn(error)
//             })
//             .finally(() => {
//                 dispatch(setLoading(false))
//             })
//     }
// }

export function filterLedgerFields(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, ledgerFieldsUrl(query.clientId))

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setLedgerFields(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function deleteLedgerField(id, clientId) {
    return async (dispatch, getState) => {
        client
            .delete(`${ledgerFieldUrl(id, clientId)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                    })
                window.location.reload();
                } else {
                    notification.error({
                        message: data.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
    }
}

export const selectLedgerField = (state) => state.ledgerFields.ledgerField
export const selectLedgerFields = (state) => state.ledgerFields.ledgerFields
export const selectTotal = (state) => state.ledgerFields.total
export const selectPagination = (state) => state.ledgerFields.pagination
export const selectLoading = (state) => state.ledgerFields.loading
export const selectLedgerFieldSelectOptions = (state) => state.ledgerFields.ledgerFieldSelectOptions
export const selectLedgerFieldSearch = (state) => state.ledgerFields.ledgerFieldSearch

export default ledgerFieldsSlice.reducer
