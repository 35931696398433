import pluralize from "pluralize"

export default null

/**
 * Look up if user has permission to do `action` on `model` from `roles` permission set object.
 *
 * @param {*} action action to perform
 * @param {*} model model to perform action on
 * @param {*} roles object of roles
 * @returns true/false whether user can perform `action` on `model` according to `roles`.
 */
export function can(action, model, roles) {
    try {
        return roles[model][action]
    } catch (e) {
        return false
    }
}

/**
 * Determine the redirect url for a user based on what access they have
 *
 * @param {Number} clientId client id that user has accessed to - used in url
 * @param {Object} permissions object of permissions sent from the server
 * @returns {String} where to redirect the user to
 */
export function clientUserRootPath(clientId, permissions) {
    const order = ["client", "division", "facility", "billable_account", "supply_account"]

    for (let o = 0; o < order.length; ++o) {
        const model = order[o]

        if (can("read", model, permissions)) {
            const stem = model === "client" ? "/" : `/${pluralize(model)}/`
            return `/clients/${clientId}${stem}${["billable_account", "supply_account"].includes(model) ? "" : "dashboard"}`
        }
    }

    return `/clients/${clientId}/dashboard`
}
