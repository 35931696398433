import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../../stores/appSlice"
import PaymentBatchTable from "./components/PaymentBatchTable"
import PaymentBatchForm from "./components/PaymentBatchForm"
import VendorPaymentTable from "./components/VendorPaymentTable"
import VendorPaymentForm from "./components/VendorPaymentForm"
import BillTable from "./components/BillTable"

export default function PaymentBatchPage({ view, recordSelected }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const renderView = () => {
        switch (view) {
            case "dashboard":
                return renderDashboard()
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            case "bills":
                return renderBills()
            case "utilityPayments":
                return renderVendorPayments()
            case "utilityPaymentForm":
                return renderVendorPaymentForm()
            // case "subaccounts":
            //     return renderSubaccounts()
            default:
                return renderIndex()
        }
    }

    const renderDashboard = () => {
        if (!scopedByClient) {
            return null
        } else if (recordSelected) {
            return <h1>Single Vendor Batch Dashboard</h1>
        }

        return <h1>Vendor Batch dashboard (scoped by client)</h1>
    }

    const renderIndex = () => {
        if (scopedByClient) {
            return <PaymentBatchTable scopedByClient={scopedByClient} />
        }

        return <PaymentBatchTable scopedByClient={scopedByClient} />
    }

    const renderForm = () => {
        return (
            <>
                <h1>Manage Vendor Batch</h1>
                <PaymentBatchForm actionName={recordSelected ? "edit" : "new"} />
            </>
        )
    }

    const renderBills = () => {
        return <BillTable scopedByClient={scopedByClient} />
        // return <h1>Bill Table</h1>
    }

    const renderVendorPayments = () => {
        return <VendorPaymentTable scopedByClient={scopedByClient} />
    }

    const renderVendorPaymentForm = () => {
        return (
            <>
                <h1>Manage Vendor Payment</h1>
                <VendorPaymentForm actionName={recordSelected ? "edit" : "new"} />
            </>
        )
    }

    return <>{renderView()}</>
}
