import React from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"

import { selectScopedByClient, selectScopedClient } from "../../stores/appSlice"
import { selectClient } from "../clients/clientsSlice"

import Role from "../userRoles/Role"

export default function ClientNav({ activeTab, clientSelected = false }) {
    const { clientId } = useParams()
    const client = useSelector(selectClient)
    const scopedByClient = useSelector(selectScopedByClient)

    const formatRoute = (newRoute) => {
        if (clientId) {
            return `/clients/${clientId}${newRoute}`
        }
        return `/clients${newRoute || ""}`
    }

    const renderHeader = () => {
        if (scopedByClient) {
            return clientSelected ? renderIndexHeader() : renderRecordHeader()
        }

        return (
            <>
                <h2>Clients</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                            All Clients
                        </button>
                    </li>
                </ul>
            </>
        )
    }

    const renderIndexHeader = () => {
        return (
            <>
                <h2>Clients</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                            All Clients
                        </button>
                    </li>
                </ul>
            </>
        )
    }

    const renderRecordHeader = () => {
        return (
            <>
                {client && <h2>{client.name}</h2>}
                <ul className="header-tab-nav" role="navigation">
                    <li>
                       <Link to={() => formatRoute("/dashboard")}>
                           <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                               Dashboard
                           </button>
                       </Link>
                    </li>

                    {client && client.user_management && (
                        <Role action="read" model="user">
                            <li>
                                <Link to={() => formatRoute("/users")}>
                                    <button type="link" className={`${activeTab === "users" ? "selected" : ""}`}>
                                        Users
                                    </button>
                                </Link>
                            </li>
                        </Role>
                    )}

                    {/* it seems like the subscription settings are just on the client itself, so they have to be able to read client... */}
                    <Role action="read_subscription" model="client">
                        <li>
                            <Link to={() => formatRoute("/subscriptions")}>
                                <button type="link" className={`${activeTab === "subscriptions" ? "selected" : ""}`}>
                                    Subscriptions
                                </button>
                            </Link>
                        </li>
                    </Role>

                    <li>
                        <Link to={() => formatRoute("/documents")}>
                            <button type="link" className={`${activeTab === "documents" ? "selected" : ""}`}>
                                Documents
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
