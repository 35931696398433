import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"
import ClientNav from "../features/clients/ClientNav"
import ClientPage from "../features/clients/ClientPage"
import ClientForm from "../features/clients/components/ClientForm"
import ClientTable from "../features/clients/components/ClientTable"
import ClientVendorNumberTable from "../features/clientVendorNumbers/ClientVendorNumberTable"
import ClientVendorNumberForm from "../features/clientVendorNumbers/ClientVendorNumberForm"

import DivisionRoutes from "./DivisionRoutes"
import FacilityRoutes from "./FacilityRoutes"
import BillableAccountRoutes from "./BillableAccountRoutes"
import SupplyAccountRoutes from "./SupplyAccountRoutes"
import GroupAccountRoutes from "./GroupAccountRoutes"
import GroupSupplyAccountRoutes from "./GroupSupplyAccountRoutes"
import AccountingBatchRoutes from "./AccountingBatchRoutes"
import PaymentBatchRoutes from "./PaymentBatchRoutes"
import BillRoutes from "./BillRoutes"
import LedgerRoutes from "./LedgerRoutes"
import AncillaryServiceRoutes from "./AncillaryServiceRoutes"
import AlertPanelRoutes from "./AlertPanelRoutes"
import ReportRoutes from "./ReportRoutes"
import AdvancedSearchRoutes from "./AdvancedSearchRoutes"

export default function ClientRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            {/* TODO: Make client page */}
            <Route exact path={path}>
                <ConsoleLayout highlightTab="clients" header={<ClientNav activeTab="list" />} body={<ClientTable />} />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout highlightTab="clients" header={<ClientNav activeTab="list" />} body={<ClientForm />} />
            </Route>

            <Route path={`${path}/:clientId/edit`}>
                <ConsoleLayout highlightTab="clients" header={<ClientNav activeTab="list" index={false} />} body={<ClientForm />} />
            </Route>

            <Route exact path={`${path}/:clientId/client_vendor_numbers`}>
                <ConsoleLayout highlightTab="clients" header={<ClientNav activeTab="list" />} body={<ClientVendorNumberTable />} />
            </Route>

            <Route exact path={`${path}/:clientId/client_vendor_numbers/new`}>
                <ConsoleLayout highlightTab="clients" header={<ClientNav activeTab="list" />} body={<ClientVendorNumberForm />} />
            </Route>

            <Route exact path={`${path}/:clientId/client_vendor_numbers/:clientVendorNumberId/edit`}>
                <ConsoleLayout highlightTab="clients" header={<ClientNav activeTab="list" />} body={<ClientVendorNumberForm />} />
            </Route>

            <Route path={`${path}/:clientId/dashboard`}>
                <ConsoleLayout highlightTab="home" header={<ClientNav activeTab="dashboard" index={false} />} body={<ClientPage view="dashboard" recordSelected={true} />} />
            </Route>

            <Route exact path={`${path}/:clientId/users`}>
                <ConsoleLayout highlightTab="home" header={<ClientNav activeTab="users" index={false} />} body={<ClientPage view="users" recordSelected={true} />} />
            </Route>

            <Route exact path={`${path}/:clientId/users/:userId/edit`}>
                <ConsoleLayout highlightTab="home" header={<ClientNav activeTab="users" index={false} />} body={<ClientPage view="user-form" recordSelected={true} />} />
            </Route>

            <Route exact path={`${path}/:clientId/users/:userId/roles`}>
                <ConsoleLayout highlightTab="home" header={<ClientNav activeTab="users" index={false} />} body={<ClientPage view="roles" recordSelected={true} />} />
            </Route>

            <Route exact path={`${path}/:clientId/users/:userId/roles/new`}>
                <ConsoleLayout highlightTab="home" header={<ClientNav activeTab="users" index={false} />} body={<ClientPage view="role-form" recordSelected={true} />} />
            </Route>

            <Route exact path={`${path}/:clientId/users/:userId/roles/:roleId/edit`}>
                <ConsoleLayout highlightTab="home" header={<ClientNav activeTab="users" index={false} />} body={<ClientPage view="role-form" recordSelected={true} />} />
            </Route>

            <Route exact path={`${path}/:clientId/subscriptions`}>
                <ConsoleLayout highlightTab="home" header={<ClientNav activeTab="subscriptions" index={false} />} body={<ClientPage view="subscriptions" recordSelected={true} />} />
            </Route>

            <Route exact path={`${path}/:clientId/documents`}>
                <ConsoleLayout
                    highlightTab="home"
                    header={<ClientNav activeTab="documents" scopedByClient={true} />}
                    body={<ClientPage view="documents" recordSelected={true} />}
                    sidebarType="clients"
                />
            </Route>

            <Route path={`${path}/:clientId/documents/new`}>
                <ConsoleLayout
                    highlightTab="clients"
                    header={<ClientNav activeTab="documents" scopedByClient={true} />}
                    body={<ClientPage view="documents-form" recordSelected={true} />}
                    sidebarType="clients"
                />
            </Route>

            <Route path={`${path}/:clientId/documents/:documentId/edit`}>
                <ConsoleLayout
                    highlightTab="clients"
                    header={<ClientNav activeTab="documents" scopedByClient={true} />}
                    body={<ClientPage view="documents-form" recordSelected={true} />}
                    sidebarType="clients"
                />
            </Route>

            <Route path={`${path}/:clientId/advanced_search`}>
                <AdvancedSearchRoutes />
            </Route>

            {/* Nested Divisions */}
            <Route path={`${path}/:clientId/divisions`}>
                <DivisionRoutes />
            </Route>

            {/* Nested Facilities */}
            <Route path={`${path}/:clientId/facilities`}>
                <FacilityRoutes />
            </Route>

            {/* Nested Billable Accounts */}
            <Route path={`${path}/:clientId/billable_accounts`}>
                <BillableAccountRoutes />
            </Route>

            {/* Nested Supply Accounts */}
            <Route path={`${path}/:clientId/supply_accounts`}>
                <SupplyAccountRoutes />
            </Route>

            {/* Nested Group Accounts */}
            <Route path={`${path}/:clientId/group_accounts`}>
                <GroupAccountRoutes />
            </Route>

            {/* Nested Group Supply Accounts */}
            <Route path={`${path}/:clientId/group_supply_accounts`}>
                <GroupSupplyAccountRoutes />
            </Route>

            {/* Nested Bills */}
            <Route path={`${path}/:clientId/bills`}>
                <BillRoutes />
            </Route>

            {/* Nested Client Batch Routes */}
            <Route path={`${path}/:clientId/accounting_batches`}>
                <AccountingBatchRoutes />
            </Route>

            {/* Nested Payment Batch Routes */}
            <Route path={`${path}/:clientId/payment_batches`}>
                <PaymentBatchRoutes />
            </Route>

            {/* Nested Ledger Fields Routes */}
            <Route path={`${path}/:clientId/ledger_fields`}>
                <LedgerRoutes />
            </Route>

            {/* Nested Ledger Accounts Routes */}
            <Route path={`${path}/:clientId/ledger_accounts`}>
                <LedgerRoutes />
            </Route>

            <Route path={`${path}/:clientId/ancillary_services`}>
                <AncillaryServiceRoutes />
            </Route>

            <Route path={`${path}/:clientId/alerts`}>
                <AlertPanelRoutes />
            </Route>

            {/* <Route path={`${path}/:clientId`}>
                <ConsoleLayout header={<ClientNav activeTab="dashboard" index={false} />} body={<h1>Single Client Dashboard</h1>} />
            </Route> */}

            {/* Nested Ledger Accounts Routes */}
            <Route path={`${path}/:clientId/reports`}>
                <ReportRoutes />
            </Route>
        </Switch>
    )
}
