// TODO: Turn this off later
import * as ActionCable from "@rails/actioncable"
import { createConsumer } from "@rails/actioncable"
import { notification, Alert } from "antd"

import store from "../stores/store"
import { setAlerts } from "../features/nav/alertPanelSlice"
import { updateGeneratedReportFile } from "../features/reports/reportsSlice"
import { updateUploadFile } from "../features/financials/accountingBatches/accountingBatchesSlice"
import { updateDocument } from "../features/billDocumentRepositories/billDocumentRepositoriesSlice"

// ActionCable.logger.enabled = true

const URL = process.env.REACT_APP_CABLE_URL
const consumer = createConsumer(URL)

export const processCableResponse = (data) => {
    let msgDuration = 4.5

    if (data.error) {
        msgDuration = 0
    }

    if (data.notification_type) {
        notification[`${data.notification_type}`]({
            message: data.message,
            description: data.description ? data.description.join(", ") : "",
            duration: msgDuration,
        })
    } else {
        notification.open({
            message: data.message,
            description: data.description ? data.description.join(", ") : "",
            duration: msgDuration,
        })
    }

    if (data.link) {
        store.dispatch(setAlerts(data))
    }

    // dispatchers for specific data updates
    if (data.type === "generated_report") {
        store.dispatch(updateGeneratedReportFile(data))
    } else if (data.type === "accounting_batch_upload_file") {
        store.dispatch(updateUploadFile(data))
        // console.log("generated file")
    } else if (data.type === "bill_documents") {
        store.dispatch(updateDocument(data))
    }
}

export default consumer
