import { capitalize } from "lodash"

export default null

/**
 * Return the titleized version of a string like in rails
 *
 * @param {*} str underscored string like_this
 * @returns titleized string
 */
export function titleize(str) {
    // this_is_some_text
    return str
        .split("_")
        .map((i) => capitalize(i))
        .join(" ")
}

/**
 * Format a number to display as currency e.g. 2500 -> $2,500.00
 *
 * @param {*} number number to display as currency
 * @param {*} empty  text to use if the number is empty e.g. n/a, or --, etc.
 * @returns formatted currency string
 */
export function currency(number, empty = "--") {
    // return the empty placeholder text if we get a NaN
    if (Number.isNaN(Number.parseFloat(number))) {
        return empty
    }

    const f = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
    return f.format(number)
}
