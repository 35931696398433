import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"

import { selectScopedByClient } from "../../stores/appSlice"
import { fetchVendor, selectVendor } from "./vendorsSlice"

export default function VendorNav({ activeTab, scopedByvendor = false }) {
    const dispatch = useDispatch()
    const { clientId, vendorId } = useParams()
    const vendor = useSelector(selectVendor)
    const scopedByClient = useSelector(selectScopedByClient)

    // Fetch the vendor we need for breadcrumb
    // TODO: we may be able to simply swap this out for the use selector based
    // on how we decide to finally load in the records from other components.
    useEffect(() => {
        if (vendorId) {
            dispatch(fetchVendor(vendorId))
        }
    }, [dispatch, vendorId])

    const formatRoute = (newRoute) => {
        if (clientId) {
            if (vendorId && scopedByvendor) {
                return `/clients/${clientId}/vendors/${vendorId}${newRoute}`
            } else {
                return `/clients/${clientId}/vendors${newRoute}`
            }
        } else if (vendorId) {
            return `/vendors/${vendorId}${newRoute}`
        }

        return `/vendors${newRoute || ""}`
    }

    // If we are scoped by a client, we are looking at an end-user's view.
    // If we are not scoped by a client, we are looking at an admin's view.
    const renderHeader = () => {
        if (scopedByClient) {
            return scopedByvendor ? renderRecordHeader() : renderIndexHeader()
        }

        // Admin - Not scoped by client here
        return (
            <>
                <h2>Vendors</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={"/vendors"}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Vendors
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/vendors/payment_methods"}>
                            <button type="link" className={`${activeTab === "payment_methods" ? "selected" : ""}`}>
                                Payment Methods
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderRecordHeader = () => {
        return (
            <>
                <h2>{`vendors > ${vendor.name}`}</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                       <Link to={() => formatRoute("/dashboard")}>
                           <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                               Dashboard
                           </button>
                       </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/bills")}>
                            <button type="link" className={`${activeTab === "bills" ? "selected" : ""}`}>
                                Vendors bills
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderIndexHeader = () => {
        return (
            <>
                <h2>Vendors</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                       <Link to={() => formatRoute("/dashboard")}>
                           <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                               Vendors Dashboard
                           </button>
                       </Link>
                    </li>
                    <li>
                        <Link to={() => formatRoute("")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                Vendors List
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
