import React from "react"
import AdvancedSearchForm from "./components/AdvancedSearchForm"

export default function SearchPage({ view }) {
    const renderView = () => {
        switch (view) {
            case "search":
                return renderSearch()
            default:
                return renderSearch()
        }
    }

    const renderSearch = () => {
        return <AdvancedSearchForm />
    }

    return <>{renderView()}</>
}
