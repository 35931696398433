import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { userSearchUrl, usersUrl, clientUsersUrl, userUrl, usersCsvUrl, dynamicUrl, resendConfirmationEmailUrl } from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../functions/objectFunctions/createRansackParams"
import { notification } from "antd"

export const usersSlice = createSlice({
    name: "users",
    initialState: {
        users: [],
        user: {},
        loading: false,
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        userSearch: [],
    },
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setTotal: (state, action) => {
            state.total = action.payload
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },
        setUserSearch: (state, action) => {
            state.userSearch = action.payload
        },
        deleteUser: (state, action) => {
            state.users = state.users.filter((user) => user.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const { setUsers, setLoading, setTotal, setPagination, setUser, setUserSearch } = usersSlice.actions

// search all users
export function searchUsers(query) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${userSearchUrl}?q=${query}`)
            .then(({ data }) => {
                dispatch(setUsers(data))
                dispatch(setUserSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

// load paginated users (table view)
export function loadUsers(pagination, currentPath) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`)
            .then(({ data }) => {
                dispatch(setPagination(pagination))
                dispatch(setUsers(data.users))
                dispatch(setTotal(data.total))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

// load individual user (show)
export function fetchUser(userId) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${userUrl(userId)}`)
            .then(({ data }) => {
                dispatch(setUser(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

// submit user form (create/update)
export function submitUser(values, id) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        if (id) {
            const response = await client.patch(userUrl(id), { user: values })
            dispatch(setLoading(false))
            return response.data
        } else {
            const response = await client.post(usersUrl, { user: values })
            dispatch(setLoading(false))
            return response.data
        }
    }
}

export function deleteUser(userId) {
    return async (dispatch, getState) => {
        client
            .delete(`${userUrl(userId)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                    })

                    dispatch(usersSlice.actions.deleteUser(userId))
                } else {
                    notification.error({
                        message: data.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
    }
}

export function filterUsers(query) {
    /// create the filter URL string
    let filterUrl = !!query.clientId ? createFilterUrl(query, clientUsersUrl(query.clientId)) : createFilterUrl(query, usersUrl)

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setUsers(response.data.users))
                dispatch(setTotal(response.data.total))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function resendAccountConfirmationEmail(userId) {
    return async (dispatch) => {
        const response = await client.get(resendConfirmationEmailUrl(userId))
        return response.data
    }
}

export function generateUsersCsv(filters) {
    return async (dispatch, getState) => {
        client.get(`${usersCsvUrl}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectUsers = (state) => state.users.users
export const selectLoading = (state) => state.users.loading
export const selectTotal = (state) => state.users.total
export const selectPagination = (state) => state.users.pagination
export const selectUser = (state) => state.users.user
export const selectUserSearch = (state) => state.users.userSearch

export default usersSlice.reducer
