import { DownloadOutlined, PlusOutlined, UploadOutlined, EditOutlined, SearchOutlined, FilterOutlined, FileTextOutlined, FileZipOutlined, DeleteOutlined } from "@ant-design/icons"
import { Skeleton, Table, Tooltip, Button } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import { fetchBillDocumentRepositories, selectDocuments, selectPagination, selectTotal, selectLoading, deleteDocument, deleteBillRepository } from "./billDocumentRepositoriesSlice"
import { allObjectValuesEmpty } from "../../functions/objectFunctions/allObjectValuesEmpty"
import path from "path"
import Role from "../userRoles/Role"
import moment from "moment"

export default function BillDocumentRepositoryTable() {
    const dispatch = useDispatch()

    const [init, setInit] = useState(false)
    const [filters, setFilters] = useState({})

    const documents = useSelector(selectDocuments)
    const pagination = useSelector(selectPagination)
    const total = useSelector(selectTotal)
    const loading = useSelector(selectLoading)

    const { clientId } = useParams()
    const { url } = useRouteMatch()

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        dispatch(fetchBillDocumentRepositories(clientId, pagination))
    }, [init])

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchBillDocumentRepositories(clientId, newPagination))
        } else {
            handleFilterBillDocuments(newPagination)
        }
    }

    const handleFilterBillDocuments = (newPagination = null, newSort = null) => {
        //
    }

    const handleDelete = (e, record) => {
        e.preventDefault()

        if (!window.confirm("Are you sure you want to delete this bill document repository? This action cannot be undone.")) {
            return
        }

        dispatch(deleteBillRepository(clientId, record.id))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Bill Document Repository</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                <Role action="create" model="bill_document">
                                    <Link to={`${url}/new`}>
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role>
                            </div>
                        </div>
                    </div>
                    <Skeleton active loading={loading}>
                        <Table dataSource={documents} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column key="name" title="Name" dataIndex="name" />

                            <Table.Column key="user" title="Created By" dataIndex="user" render={(u) => `${u?.first_name} ${u?.last_name}`} />

                            <Table.Column key="created_at" title="Created" dataIndex="created_at" render={(text, record) => moment(record.created_at).format("L")} />

                            <Table.Column key="updated_at" title="Updated" dataIndex="updated_at" render={(text, record) => moment(record.updated_at).format("L")} />

                            <Table.Column
                                title="Zip"
                                key="download"
                                render={(text, record) => {
                                    if (!record.file_url) {
                                        return null
                                    }

                                    return (
                                        <div className="row-actions" style={{ display: "inline-block" }}>
                                            <a href={record.file_url} target="_blank">
                                                <Tooltip title="Download Documents">
                                                    <FileZipOutlined />
                                                </Tooltip>
                                            </a>
                                        </div>
                                    )
                                }}
                            />

                            <Table.Column
                                title="Edit"
                                key="edit"
                                render={(text, record) => (
                                    <>
                                        <Role action="update" model="bill_document">
                                            <div className="row-actions" style={{ display: "inline-block" }}>
                                                <Link to={path.join(url, `${record.id}`, "edit")}>
                                                    <Tooltip title="Edit">
                                                        <EditOutlined />
                                                    </Tooltip>
                                                </Link>
                                            </div>
                                        </Role>
                                    </>
                                )}
                            />

                            <Table.Column
                                title="Delete"
                                key="delete"
                                render={(text, record) => (
                                    <>
                                        <Role action="destroy" model="bill_document">
                                            <div className="row-actions" style={{ display: "inline-block" }}>
                                                <a style={{ color: "indianred" }} href="#" onClick={(e) => handleDelete(e, record)}>
                                                    <Tooltip title="Delete">
                                                        <DeleteOutlined />
                                                    </Tooltip>
                                                </a>
                                            </div>
                                        </Role>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}
