import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../lib/client"
import {
    groupSupplyAccountUrl,
    subaccountOptionsForGroupSupplyAccountUrl,
    clientGroupSupplyAccountsSidebarUrl,
    dynamicUrl,
    groupSupplyAccountsScopedByClientUrl,
    groupSupplySubaccountsScopedByClientUrl,
} from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"

export const groupSupplyAccountsSlice = createSlice({
    name: "groupSupplyAccounts",
    initialState: {
        groupSupplyAccount: {},
        groupSupplyAccounts: [],
        groupSupplyAccountSelectOptions: [],
        subaccounts: [],
        subaccountSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
        groupSupplyAccountSearch: [],
    },
    reducers: {
        setGroupSupplyAccount: (state, action) => {
            state.groupSupplyAccount = action.payload
        },
        setGroupSupplyAccounts: (state, action) => {
            state.groupSupplyAccounts = action.payload.group_supply_accounts
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setGroupSupplyAccountSelectOptions: (state, action) => {
            state.groupSupplyAccountSelectOptions = action.payload
        },
        setGroupSupplyAccountSearch: (state, action) => {
            state.groupSupplyAccountSearch = action.payload
        },
        setSubaccounts: (state, action) => {
            state.subaccounts = action.payload.subaccounts
            state.total = action.payload.total
        },
        setSubaccountSelectOptions: (state, action) => {
            state.subaccountSelectOptions = action.payload
        },
    },
})

export const { setGroupSupplyAccount, setGroupSupplyAccounts, setPagination, setLoading, setGroupSupplyAccountSelectOptions, setGroupSupplyAccountSearch, setSubaccounts, setSubaccountSelectOptions } =
    groupSupplyAccountsSlice.actions

export function fetchGroupSupplyAccount(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(groupSupplyAccountUrl(id)).then((response) => {
            dispatch(setGroupSupplyAccount(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchGroupSupplyAccounts(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setGroupSupplyAccounts(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchGroupSupplyAccountsSidebar(clientId) {
    return async (dispatch, getState) => {
        const response = await client.get(clientGroupSupplyAccountsSidebarUrl(clientId))
        return response.data.group_supply_accounts
    }
}

export function fetchClientGroupSupplyAccounts(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientGroupSupplyAccountsPath = `clients/${clientId}/group_supply_accounts/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientGroupSupplyAccountsPath)}`)
        return response.data.group_supply_accounts
    }
}

export function fetchSubaccounts(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setSubaccounts(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchSubaccountSelectOptions(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(subaccountOptionsForGroupSupplyAccountUrl(id)).then((response) => {
            dispatch(setSubaccountSelectOptions(response.data.subaccount_options))
            dispatch(setLoading(false))
        })
    }
}

export function submitGroupSupplyAccount(values, id) {
    return async (dispatch, getState) => {
        // const id = getState().groupSupplyAccounts.groupSupplyAccount.id
        if (id) {
            // patch - update
            const response = await client.patch(groupSupplyAccountUrl(id), { group_supply_account: values })
            return response.data
        }
    }
}

// export function fetchGroupSupplyAccountSelectOptions(searchTerm) {
//     return async (dispatch, getState) => {
//         client.get(`${groupSupplyAccountSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
//             dispatch(setGroupSupplyAccountSelectOptions(response.data.groupSupplyAccounts))
//         })
//     }
// }

// export function fetchGroupSupplyAccountSelectOptionById(id) {
//     return async (dispatch, getState) => {
//         client.get(`${groupSupplyAccountSelectOptionByIdUrl}?groupSupplyAccount_id=${id}`).then((response) => {
//             dispatch(setGroupSupplyAccountSelectOptions(response.data.groupSupplyAccounts))
//         })
//     }
// }

// export function searchGroupSupplyAccounts(query) {
//     return async (dispatch) => {
//         dispatch(setLoading(true))

//         client
//             .get(`${groupSupplyAccountSearchUrl}?q=${query}`)
//             .then(({ data }) => {
//                 dispatch(setGroupSupplyAccountSearch(data))
//             })
//             .catch((error) => {
//                 console.warn(error)
//             })
//             .finally(() => {
//                 dispatch(setLoading(false))
//             })
//     }
// }

export function filterGroupSupplyAccounts(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, groupSupplyAccountsScopedByClientUrl(query.clientId))

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setGroupSupplyAccounts(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function filterSubaccounts(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, groupSupplySubaccountsScopedByClientUrl(query.clientId, query.groupSupplyAccountId), "filter_subaccounts")

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setSubaccounts(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectGroupSupplyAccount = (state) => state.groupSupplyAccounts.groupSupplyAccount
export const selectGroupSupplyAccounts = (state) => state.groupSupplyAccounts.groupSupplyAccounts
export const selectTotal = (state) => state.groupSupplyAccounts.total
export const selectPagination = (state) => state.groupSupplyAccounts.pagination
export const selectLoading = (state) => state.groupSupplyAccounts.loading
export const selectGroupSupplyAccountSelectOptions = (state) => state.groupSupplyAccounts.groupSupplyAccountSelectOptions
export const selectGroupSupplyAccountSearch = (state) => state.groupSupplyAccounts.groupSupplyAccountSearch
export const selectSubaccounts = (state) => state.groupSupplyAccounts.subaccounts
export const selectSubaccountSelectOptions = (state) => state.groupSupplyAccounts.subaccountSelectOptions

export default groupSupplyAccountsSlice.reducer
