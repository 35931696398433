import React from "react"
import { Link } from "react-router-dom"

export default function SettingsNav({ activeTab, headerText = null }) {
    const renderHeaderText = () => {
        if (headerText) {
            return `Settings > ${headerText}`
        }

        return "Settings"
    }

    return (
        <>
            <h2>{renderHeaderText()}</h2>
            <ul className="header-tab-nav" role="navigation">
                <li>
                    <Link to={"/settings/facility_types"}>
                        <button type="link" className={`${activeTab === "facilityTypes" ? "selected" : ""}`}>
                            Facility Types
                        </button>
                    </Link>
                </li>

                <li>
                    <Link to={"/settings/units"}>
                        <button type="link" className={`${activeTab === "unitsOfMeasure" ? "selected" : ""}`}>
                            Units of Measure
                        </button>
                    </Link>
                </li>

                <li>
                    <Link to={"/settings/production_units"}>
                        <button type="link" className={`${activeTab === "productionUnits" ? "selected" : ""}`}>
                            Production Units
                        </button>
                    </Link>
                </li>

                <li>
                    <Link to={"/settings/cost_parameters"}>
                        <button type="link" className={`${activeTab === "costParameters" ? "selected" : ""}`}>
                            Cost Parameters
                        </button>
                    </Link>
                </li>

                <li>
                    <Link to={"/settings/audit_log"}>
                        <button type="link" className={`${activeTab === "auditLog" ? "selected" : ""}`}>
                            Audit Log
                        </button>
                    </Link>
                </li>
            </ul>
        </>
    )
}
