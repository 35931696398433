import { createSlice } from "@reduxjs/toolkit"
import { remove, filter } from "lodash"
import { batch } from "react-redux"
import { notification } from "antd"

// http client
import client from "../../../lib/client"
import {
    accountingBatchesUrl,
    accountingBatchUrl,
    accountingBatchesSidebar,
    dynamicUrl,
    batchGroupSelectOptionsUrl,
    accountingBatchBillsUrl,
    accountingBatchNewBillsUrl,
    accountingBatchBillsCsvUrl,
    accountingBatchCsvUrl,
    accountingBatchGenerateUploadFileUrl,
    accountingBatchGenerateInvoicePdfUrl,
} from "../../../lib/endpoints"

export const accountingBatchesSlice = createSlice({
    name: "accountingBatches",
    initialState: {
        accountingBatch: {},
        accountingBatches: [],
        accountingBatchSelectOptions: [],
        accountingBatchSearch: [],
        batchGroupSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        bills: [],
        fetchingBills: false,
        loading: false,
    },
    reducers: {
        setAccountingBatch: (state, action) => {
            state.accountingBatch = action.payload
        },
        setAccountingBatches: (state, action) => {
            state.accountingBatches = action.payload.accounting_batches
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setAccountingBatchSelectOptions: (state, action) => {
            state.accountingBatchSelectOptions = action.payload
        },
        setAccountingBatchSearch: (state, action) => {
            state.accountingBatchSearch = action.payload
        },
        setBatchGroupSelectOptions: (state, action) => {
            state.batchGroupSelectOptions = action.payload
        },
        setBills: (state, action) => {
            state.bills = action.payload
        },
        deleteAccountingBatch: (state, action) => {
            state.accountingBatches = state.accountingBatches.filter((accountingBatch) => accountingBatch.id !== action.payload)
            state.total = state.total - 1
        },

        disableGenerateUploadFile: (state, action) => {
            try {
                state.accountingBatches = state.accountingBatches.map((ab) => {
                    if (ab.id === action.payload) {
                        return {
                            ...ab,
                            can_generate_upload_file: false,
                            generating_file: true,
                        }
                    } else {
                        return ab
                    }
                })
            } catch (e) {
                console.error("Error disabling upload file generation: ", e)
                return state
            }
        },
        updateUploadFile: (state, action) => {
            try {
                state.accountingBatches = state.accountingBatches.map((ab) => {
                    if (ab.id === action.payload.accounting_batch_id) {
                        return {
                            ...ab,
                            upload_file_url: action.payload.link,
                            generating_file: false,
                        }
                    } else {
                        return ab
                    }
                })
            } catch (e) {
                console.error("Upload file generation error: ", e)
                return state
            }
        },
    },
})

export const {
    setAccountingBatch,
    setAccountingBatches,
    setPagination,
    setLoading,
    setAccountingBatchSelectOptions,
    setAccountingBatchSearch,
    setBatchGroupSelectOptions,
    setBills,
    disableGenerateUploadFile,
    updateUploadFile,
} = accountingBatchesSlice.actions

export function fetchAccountingBatch(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(accountingBatchUrl(id)).then((response) => {
            dispatch(setAccountingBatch(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchAccountingBatches(clientId, pagination) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${accountingBatchesUrl(clientId)}?values=[${pagination.values}]`)
            return response.data
        }

        client.get(`${accountingBatchesUrl(clientId)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setAccountingBatches(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchAccountingBatchesSidebar(clientId) {
    return async (dispatch, getState) => {
        const response = await client.get(`${accountingBatchesSidebar(clientId)}`)
        return response.data.accounting_batches
    }
}

export function submitAccountingBatch(values, id, clientId, formatDate = true) {
    return async (dispatch, getState) => {
        if (formatDate) {
            if (id) {
                // patch - update
                const response = await client.patch(accountingBatchUrl(id, clientId), { accounting_batch: { ...values, batch_date: new Date(values.batch_date).toISOString() } })

                if (!!response.data.bills) {
                    dispatch(setBills(response.data.bills))
                }

                return response.data
            } else {
                // post - create
                const response = await client.post(accountingBatchesUrl(clientId), { accounting_batch: { ...values, batch_date: new Date(values.batch_date).toISOString() } })
                return response.data
            }
        } else {
            if (id) {
                // patch - update
                const response = await client.patch(accountingBatchUrl(id, clientId), { accounting_batch: values })

                if (!!response.data.bills) {
                    dispatch(setBills(response.data.bills))
                }

                return response.data
            } else {
                // post - create
                const response = await client.post(accountingBatchesUrl(clientId), { accounting_batch: values })
                return response.data
            }
        }
    }
}

export function deleteAccountingBatch(id) {
    return async (dispatch, getState) => {
        client
            .delete(`${accountingBatchUrl(id)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                    })

                    dispatch(accountingBatchesSlice.actions.deleteAccountingBatch(id))
                } else {
                    notification.error({
                        message: data.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
    }
}

export function fetchBatchGroupSelectOptions(clientId) {
    return async (dispatch, getState) => {
        client.get(`${batchGroupSelectOptionsUrl(clientId)}`).then((response) => {
            dispatch(setBatchGroupSelectOptions(response.data.batch_groups))
        })
    }
}

export function fetchAccountingBatchBills(clientId, accountingBatchId) {
    return async (dispatch, getState) => {
        client.get(`${accountingBatchBillsUrl(clientId, accountingBatchId)}`).then((response) => {
            dispatch(setBills(response.data.bills))
        })
    }
}

export function fetchAccountingBatchNewBills(clientId, accountingBatchId) {
    return async (dispatch, getState) => {
        const response = await client.get(`${accountingBatchNewBillsUrl(clientId, accountingBatchId)}`)
        dispatch(setBills(response.data.bills))
        return { success: true, new_bills: response.data.new_bills, total: response.data.new_bills_total }
    }
}

// export function deleteAccountingBatch(accountingBatchId) {
//     return async (dispatch, getState) => {
//         const response = await client.delete(`${accountingBatchUrl(accountingBatchId)}`)
//         if (response.data.success) {
//             let batches = [...getState().accountingBatches.accountingBatches]

//             let newList = filter(batches, function (n) {
//                 return n.id !== parseInt(accountingBatchId)
//             })

//             dispatch(setAccountingBatches({ accounting_batches: [...newList], total: getState().accountingBatches.total - 1 }))
//             return response.data
//         } else {
//             return response.data
//         }
//     }
// }

export function generateCsv(accountingBatchId) {
    return async (dispatch, getState) => {
        client.get(`${accountingBatchBillsCsvUrl(accountingBatchId)}?connection_id=${getState().app.uid}`)
    }
}

export function generateAccountingBatchCsv(accountingBatchId) {
    return async (dispatch, getState) => {
        client.get(`${accountingBatchCsvUrl(accountingBatchId)}?connection_id=${getState().app.uid}`)
    }
}

export function generateAccountingBatchUploadFile(accountingBatchId) {
    return async (dispatch, getState) => {
        dispatch(disableGenerateUploadFile(accountingBatchId))
        client.get(`${accountingBatchGenerateUploadFileUrl(accountingBatchId)}?connection_id=${getState().app.uid}`)
    }
}

export function generateAccountingBatchInvoicePdf(accountingBatchId) {
    return async (dispatch, getState) => {
        client.get(`${accountingBatchGenerateInvoicePdfUrl(accountingBatchId)}?connection_id=${getState().app.uid}`)
    }
}

// export function fetchAccountingBatchSelectOptions(clientId) {
//     return async (dispatch, getState) => {
//         client.get(`${accountingBatchSelectOptionsUrl(clientId)}`).then((response) => {
//             dispatch(setAccountingBatchSelectOptions(response.data.accounting_batches))
//         })
//     }
// }

// export function fetchAccountingBatchSelectOptionById(id, currentPath) {
//     return async (dispatch, getState) => {
//         client.get(`${accountingBatchSelectOptionByIdUrl}?accountingBatch_id=${id}`).then((response) => {
//             dispatch(setAccountingBatchSelectOptions(response.data.accountingBatch))
//         })
//     }
// }

export function searchAccountingBatches(query, url) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${dynamicUrl(url)}?q=${query}`)
            .then(({ data }) => {
                dispatch(setAccountingBatchSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectAccountingBatch = (state) => state.accountingBatches.accountingBatch
export const selectAccountingBatches = (state) => state.accountingBatches.accountingBatches
export const selectTotal = (state) => state.accountingBatches.total
export const selectPagination = (state) => state.accountingBatches.pagination
export const selectLoading = (state) => state.accountingBatches.loading
export const selectAccountingBatchSelectOptions = (state) => state.accountingBatches.accountingBatchSelectOptions
export const selectAccountingBatchSearch = (state) => state.accountingBatches.accountingBatchSearch
export const selectBatchGroupSelectOptions = (state) => state.accountingBatches.batchGroupSelectOptions
export const selectBills = (state) => state.accountingBatches.bills

export default accountingBatchesSlice.reducer
