import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { userProfileUrl } from "../../lib/endpoints"
import { notification } from "antd"

export const profilesSlice = createSlice({
    name: "profiles",
    initialState: {
        loading: false,
        profile: null,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setProfile: (state, action) => {
            state.profile = action.payload
        },
    },
})

export const { setLoading, setProfile } = profilesSlice.actions

export function fetchProfile() {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client
            .get(userProfileUrl)
            .then(({ data }) => {
                dispatch(setProfile(data))
            })
            .catch((e) => {
                notification.error({
                    message: "Profile update request error",
                    description: e?.response?.statusText,
                })
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function submitProfile(values) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client
            .patch(userProfileUrl, {
                user: values,
            })
            .then(({ data }) => {
                if (data?.success) {
                    notification.success({
                        message: "Profile was updated",
                    })
                } else {
                    notification.error({
                        message: "Profile update request error",
                        description: data.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Profile update request error",
                    description: e?.response?.statusText,
                })
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectLoading = (state) => state.profiles.loading
export const selectProfile = (state) => state.profiles.profile

export default profilesSlice.reducer
