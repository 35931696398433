import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../stores/appSlice"
import SupplyAccountTable from "./components/SupplyAccountTable"
import SupplyAccountForm from "./components/SupplyAccountForm"
import BillTable from "../bills/components/BillTable"
import ServiceTable from "../services/components/ServiceTable"
import ServiceForm from "../services/components/ServiceForm"
// import BillTable from "../supplyAccounts/components/SupplyAccountTable"
import DocumentsTable from "../documents/components/DocumentsTable"
import DocumentForm from "../documents/components/DocumentForm"
import { useParams } from "react-router"
import ImportForm from "../imports/ImportForm"
import Dashboard from "../../features/dashboards/components/Dashboard"

export default function SupplyAccountPage({ view, recordSelected }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const { clientId, supplyAccountId } = useParams()

    const renderView = () => {
        switch (view) {
            case "dashboard":
                return renderDashboard()
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            case "services":
                return renderServices()
            case "serviceForm":
                return renderServiceForm()
            case "bills":
                return renderBills()
            case "documents":
                return renderDocuments()
            case "documents-form":
                return renderDocumentsForm()
            case "import":
                return renderImport()
            default:
                return renderDashboard()
        }
    }

    const renderDashboard = () => {
        return <Dashboard scopeType="SupplyAccount" scopeId={supplyAccountId} clientId={clientId} />
    }

    const renderIndex = () => {
        if (scopedByClient) {
            return (
                <>
                    <SupplyAccountTable scopedByClient={scopedByClient} />
                </>
            )
        }

        return <SupplyAccountTable scopedByClient={scopedByClient} />
    }

    const renderForm = () => {
        if (recordSelected) {
            return (
                <>
                    <h1>Edit Form</h1>
                    <SupplyAccountForm actionName="edit" />
                </>
            )
        }

        return (
            <>
                <h1>New Form</h1>
                <SupplyAccountForm actionName="new" />
            </>
        )
    }

    const renderServices = () => {
        return (
            <>
                <h1>Supply Account's Services</h1>
                <ServiceTable scopedByClient={scopedByClient} />
            </>
        )
    }

    const renderServiceForm = () => {
        if (recordSelected) {
            return (
                <>
                    <h1>Edit Form</h1>
                    <ServiceForm actionName="edit" scopedBySupplyAccount={true} />
                </>
            )
        }

        return (
            <>
                <h1>New Form</h1>
                <ServiceForm actionName="new" scopedBySupplyAccount={true} />
            </>
        )
    }

    const renderBills = () => {
        return <BillTable scopedByClient={scopedByClient} accountType={"supply_account"} accountId={supplyAccountId} />
    }

    const renderDocuments = () => {
        return <DocumentsTable type="SupplyAccount" id={supplyAccountId} display="Supply Account" />
    }

    const renderDocumentsForm = () => {
        return <DocumentForm type="SupplyAccount" id={supplyAccountId} />
    }

    const renderImport = () => {
        return <ImportForm dataType="supplyAccount" />
    }
    return <>{renderView()}</>
}
