import React from "react"
import { Form, Input, InputNumber, Checkbox } from "antd"
import { CloseOutlined } from "@ant-design/icons"

function SiteSurveysCompressorsFormPiece(props) {
    return (
        <div className={"site-surveys-compressors-form-piece"} key={props.key}>
            <CloseOutlined className={"close-icon"} onClick={props.remove} />
            <Form.Item {...props} name={[props.name, "active"]} fieldKey={[props.fieldKey, "active"]} label={"Active"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "manufacturer"]} fieldKey={[props.fieldKey, "manufacturer"]} label={"Manufacturer"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "compressor_type"]} fieldKey={[props.fieldKey, "compressor_type"]} label={"Type"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "model"]} fieldKey={[props.fieldKey, "model"]} label={"Model"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "vfd"]} fieldKey={[props.fieldKey, "vfd"]} label={"VFD"} valuePropName="checked">
                <Checkbox />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "annual_operating_days"]} fieldKey={[props.fieldKey, "annual_operating_days"]} label={"Annual Operating Days"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "voltage"]} fieldKey={[props.fieldKey, "voltage"]} label={"Voltage"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "max_load_psig"]} fieldKey={[props.fieldKey, "max_load_psig"]} label={"Max Load Psig"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "cfm"]} fieldKey={[props.fieldKey, "cfm"]} label={"CFM"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "hours_per_day"]} fieldKey={[props.fieldKey, "hours_per_day"]} label={"Hours Per Day"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "year"]} fieldKey={[props.fieldKey, "year"]} label={"Year"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "discharge_psig"]} fieldKey={[props.fieldKey, "discharge_psig"]} label={"Discharge Psig"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "dryer_manufacturer"]} fieldKey={[props.fieldKey, "dryer_manufacturer"]} label={"Dryer Manufacturer"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "dryer_type"]} fieldKey={[props.fieldKey, "dryer_type"]} label={"Dryer Type"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "notes"]} fieldKey={[props.fieldKey, "notes"]} label={"Notes"}>
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </Form.Item>
        </div>
    )
}

export default SiteSurveysCompressorsFormPiece
