import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../stores/appSlice"
import BillTable from "./components/BillTable"
import BillForm from "./components/BillForm"
import FacilityTable from "../facilities/components/FacilityTable"
import FacilityForm from "../facilities/components/FacilityForm"
import DocumentsTable from "../documents/components/DocumentsTable"
import { useParams } from "react-router"
import DocumentForm from "../documents/components/DocumentForm"
import BillDocumentRepositoryTable from "../billDocumentRepositories/BillDocumentRepositoryTable"
import BillDocumentRepositoryForm from "../billDocumentRepositories/BillDocumentRepositoryForm"
import ImportForm from "../imports/ImportForm"

export default function BillPage({ view, recordSelected, actionName }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const { BillId } = useParams()

    const renderView = () => {
        switch (view) {
            case "dashboard":
                // return renderDashboard()
                return renderIndex()
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            case "import":
                return renderImport()
            case "facilities":
                return renderFacilities()
            case "facilities-form":
                return renderFacilitiesForm()
            case "documents":
                return renderDocuments()
            case "documents-form":
                return renderDocumentsForm()
            case "repository":
                return renderRepository()
            case "repositoryForm":
                return renderRepositoryForm()
            default:
                // return renderDashboard()
                return renderIndex()
        }
    }

    const renderDashboard = () => {
        if (!scopedByClient) {
            return null
        } else if (recordSelected) {
            return <h1>Single Bill Dashboard</h1>
        }

        return <h1>Bills dashboard (scoped by client)</h1>
    }

    const renderIndex = () => {
        if (scopedByClient) {
            return (
                <>
                    <BillTable scopedByClient={scopedByClient} />
                </>
            )
        }

        return <BillTable scopedByClient={scopedByClient} />
    }

    const renderImport = () => {
        return <ImportForm dataType="bills" />
    }

    const renderForm = () => {
        if (recordSelected) {
            return (
                <>
                    <h1>Edit Form</h1>
                    <BillForm actionName="edit" />
                </>
            )
        }

        return (
            <>
                <h1>New Form</h1>
                <BillForm actionName="new" />
            </>
        )
    }

    const renderFacilities = () => {
        return <FacilityTable scopedByClient={scopedByClient} />
    }

    const renderFacilitiesForm = () => {
        return <FacilityForm />
    }

    const renderDocuments = () => {
        return <DocumentsTable type="Bill" id={BillId} />
    }

    const renderDocumentsForm = () => {
        return <DocumentForm type="Bill" id={BillId} />
    }

    const renderRepository = () => {
        return <BillDocumentRepositoryTable />
    }

    const renderRepositoryForm = () => {
        return <BillDocumentRepositoryForm actionName={actionName} />
    }

    return <>{renderView()}</>
}
