import React from "react"
import { Form, Input } from "antd"
import { CloseOutlined } from "@ant-design/icons"

function SiteSurveysContactsFormPiece(props) {
    return (
        <div className={"site-surveys-contacts-form-piece"} key={props.key}>
            <CloseOutlined className={"close-icon"} onClick={props.remove} />
            <Form.Item {...props} name={[props.name, "name"]} fieldKey={[props.fieldKey, "name"]} label={"Name"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "office_phone"]} fieldKey={[props.fieldKey, "office_phone"]} label={"Office Phone"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "mobile_phone"]} fieldKey={[props.fieldKey, "mobile_phone"]} label={"Mobile Phone"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "job_title"]} fieldKey={[props.fieldKey, "job_title"]} label={"Job Title"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "email"]} fieldKey={[props.fieldKey, "email"]} label={"Email"} rules={[{ type: "email" }]}>
                <Input />
            </Form.Item>
        </div>
    )
}

export default SiteSurveysContactsFormPiece
