import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Row, Col, notification, Select } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"

import { fetchGroupAccount, fetchSubaccountSelectOptions, selectGroupAccount, selectSubaccountSelectOptions, submitGroupAccount } from "../groupAccountsSlice"

import { requestNotification } from "../../../lib/notifications"

const { Option } = Select

export default function GroupAccountForm({ actionName }) {
    const { url } = useRouteMatch()
    const history = useHistory()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const groupAccount = useSelector(selectGroupAccount)
    const subaccountSelectOptions = useSelector(selectSubaccountSelectOptions)
    const { groupAccountId } = useParams()

    useEffect(() => {
        if (groupAccountId) {
            dispatch(fetchGroupAccount(groupAccountId))
            dispatch(fetchSubaccountSelectOptions(groupAccountId))
        } else {
            // dispatch(setSubaccountOptions({}))
        }
    }, [dispatch, groupAccountId])

    useEffect(() => {
        form.resetFields()
    }, [form, groupAccount])

    const submit = async (values) => {
        const response = await dispatch(submitGroupAccount(values, groupAccountId))
        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    return (
        <div id="user-form-container">
            <Link to={"/settings/units"}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={groupAccount} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={16}>
                                    <Form.Item label="Subaccounts" name="subaccount_ids">
                                        <Select placeholder={"Select subaccounts"} showArrow={true} mode="multiple" allowClear>
                                            {subaccountSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.account_number}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
