import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"

import { fetchUtilityService } from "./utilityServicesSlice"

export default function UtilityServiceNav({ activeTab, scopedByUtilityService = false }) {
    const dispatch = useDispatch()
    const { utilityServiceId } = useParams()

    // Fetch the utilityService we need for breadcrumb
    // TODO: we may be able to simply swap this out for the use selector based
    // on how we decide to finally load in the records from other components.
    useEffect(() => {
        if (utilityServiceId) {
            dispatch(fetchUtilityService(utilityServiceId))
        }
    }, [dispatch, utilityServiceId])

    const formatRoute = (newRoute) => {
        if (utilityServiceId) {
            return `/utility_services/${utilityServiceId}${newRoute}`
        }

        return `/utility_services${newRoute || ""}`
    }

    const renderHeader = () => {
        // Admin - Not scoped by client here
        return (
            <>
                <h2>Utility Services</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Utility Services
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
