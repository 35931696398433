import React, { useEffect, useState } from "react"
import { Layout, Skeleton } from "antd"
import { useSelector, useDispatch } from "react-redux"

import Nav from "./features/nav/Nav"
import Sidebar from "./features/nav/Sidebar"
import { selectScopedByClient } from "./stores/appSlice"
import { fetchUserRoleForUser, selectUserRolesLoading } from "./features/userRoles/userRolesSlice"

import { useParams } from "react-router-dom"

import { useHistory } from "react-router-dom"

export default function ConsoleLayout({ highlightTab, header, body, sidebarType, landingPage }) {
    const dispatch = useDispatch()

    const scopedByClient = useSelector(selectScopedByClient)
    const userRolesLoading = useSelector(selectUserRolesLoading)

    const { clientId } = useParams()

    const [init, setInit] = useState(false)

    const history = useHistory()

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        // pass history.push to this so we can redirect the user based on the results of the user roles request
        // if the api is telling us to redirect and we are not on a client-scoped page, then we pass
        // the callback, otherwise it would redirect every page no matter what... so if they are on any
        // page not scoped by client this will redirect them... if we don't want to redirect, we can
        // check the path here to only check for the homepage or something
        dispatch(fetchUserRoleForUser(clientId, !clientId ? history.push : null))
    }, [init])

    return (
        <Layout id="console">
            {!userRolesLoading && <Nav highlightTab={highlightTab} />}

            <Layout id="console-body">
                {scopedByClient && <Sidebar sidebarType={sidebarType} />}

                <div id="console-main-content">
                    <Layout id="navigation-sub-header">
                        <Layout.Content>
                            <div id="current-console-title">{header}</div>
                        </Layout.Content>
                    </Layout>
                    <Layout.Content id="console-content">
                        <div id="console-content-container">{body}</div>
                    </Layout.Content>
                </div>
            </Layout>
        </Layout>
    )
}
