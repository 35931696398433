import React, { useEffect, useState } from "react"
import { LeftOutlined, InboxOutlined } from "@ant-design/icons"
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Form, Input, Button, Upload } from "antd"
import npath from "path"
import { useDispatch, useSelector } from "react-redux"
import { createDocument, fetchDocument, selectDocument, selectLoading, setDocument } from "../documentsSlice"

export default function DocumentForm({ type, id }) {
    const dispatch = useDispatch()

    const { url } = useRouteMatch()

    const [file, setFile] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const loading = useSelector(selectLoading)
    const document = useSelector(selectDocument)

    const history = useHistory()

    const { documentId } = useParams()

    const [form] = Form.useForm()

    useEffect(() => {
        if (documentId) {
            if (!loaded) {
                setLoaded(true)
                dispatch(fetchDocument(documentId))
            }
        } else {
            setLoaded(true)
            dispatch(setDocument({}))
            form.resetFields()
        }
    }, [dispatch, documentId, form, loaded])

    useEffect(() => {
        if (documentId && document && document.title) {
            form.resetFields()
        }
    }, [document.title])

    const submit = async (values) => {
        const payload = values

        // for whatever reason sending file as 'null' as multipart/form-data blows
        // up the server, you have to either not have that param or send an actual file
        if (file) {
            payload.file = file
        }

        if (documentId) {
            payload.id = documentId
        }

        const response = await dispatch(createDocument(type, id, payload))

        if (response.data.success) {
            history.push(backPath())
        } else {
            window.alert("Unable to create document...")
        }
    }

    const fileSelect = (file) => {
        setFile(file)
        return false
    }

    const fileRemove = () => {
        setFile(null)
    }

    const backPath = () => {
        if (documentId) {
            return npath.join(url, "../../../", "documents")
        } else {
            return npath.join(url, "../../", "documents")
        }
    }

    return (
        <div className="user-form-container">
            {/* Going back an extra level and tacking on documents just to not have the trailing slash that breaks stuff */}
            <Link to={backPath()}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form onFinish={submit} initialValues={document} form={form}>
                <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item>
                    <Upload.Dragger name="file" multiple={false} beforeUpload={fileSelect} onRemove={fileRemove} maxCount={1}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Upload.Dragger>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
