import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import GroupAccountNav from "../features/groupAccounts/GroupAccountNav"
import GroupAccountPage from "../features/groupAccounts/GroupAccountPage"

export default function GroupAccountRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout highlightTab="groupAccounts" header={<GroupAccountNav activeTab="list" />} body={<GroupAccountPage view="index" recordSelected={false} />} sidebarType="groupAccounts" />
            </Route>

            {/* <Route path={`${path}/new`}>
                <ConsoleLayout highlightTab="groupAccounts" header={<GroupAccountNav activeTab="list" />} body={<GroupAccountPage view="form" recordSelected={false} />} sidebarType="groupAccounts" />
            </Route>

            <Route path={`${path}/dashboard`}>
                <ConsoleLayout
                    highlightTab="groupAccounts"
                    header={<GroupAccountNav activeTab="dashboard" />}
                    body={<GroupAccountPage view="dashboard" recordSelected={false} />}
                    sidebarType="groupAccounts"
                />
            </Route>

            <Route path={`${path}/:groupAccountId/dashboard`}>
                <ConsoleLayout
                    highlightTab="groupAccounts"
                    header={<GroupAccountNav activeTab="dashboard" scopedByGroupAccount={true} />}
                    body={<GroupAccountPage view="dashboard" recordSelected={true} />}
                    sidebarType="groupAccounts"
                />
            </Route> */}

            <Route path={`${path}/:groupAccountId/edit`}>
                <ConsoleLayout
                    highlightTab="groupAccountSubaccounts"
                    header={<GroupAccountNav activeTab="subaccounts" scopedByGroupAccount={true} />}
                    body={<GroupAccountPage view="form" recordSelected={true} />}
                    sidebarType="groupAccounts"
                />
            </Route>

            <Route path={`${path}/:groupAccountId/bills`}>
                <ConsoleLayout
                    highlightTab="groupAccountBills"
                    header={<GroupAccountNav activeTab="bills" scopedByGroupAccount={true} />}
                    body={<GroupAccountPage view="bills" recordSelected={true} />}
                    sidebarType="groupAccounts"
                />
            </Route>

            <Route path={`${path}/:groupAccountId/subaccounts`}>
                <ConsoleLayout
                    highlightTab="groupAccountSubaccounts"
                    header={<GroupAccountNav activeTab="subaccounts" scopedByGroupAccount={true} />}
                    body={<GroupAccountPage view="subaccounts" recordSelected={true} />}
                    sidebarType="groupAccounts"
                />
            </Route>

            <Route path={`${path}/:groupAccountId`}>
                <ConsoleLayout
                    highlightTab="groupAccounts"
                    header={<GroupAccountNav activeTab="dashboard" scopedByGroupAccount={true} />}
                    body={<GroupAccountPage view="dashboard" recordSelected={true} />}
                    sidebarType="groupAccounts"
                />
            </Route>
        </Switch>
    )
}
