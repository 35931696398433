// src/hooks/useConfirmationModal.js

import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

const { confirm } = Modal;

const useConfirmationModal = () => {
  const dispatch = useDispatch();

  const showConfirm = (onAccept, id, client_id = null) => {
    confirm({
      title: 'Do you want to delete this record?',
      content: 'This action cannot be undone',
      onOk() {
        client_id ? dispatch(onAccept(id, client_id)) : dispatch(onAccept(id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return showConfirm;
};

export default useConfirmationModal;
