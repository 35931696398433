import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { advancedSearchAssociationsUrl, advancedSearchUrl, globalAdvancedSearchAssociationsUrl, globalAdvancedSearchUrl } from "../../lib/endpoints"
import { notification } from "antd"

export const searchSlice = createSlice({
    name: "search",
    initialState: {
        loading: false,
        services: [],
        vendors: [],
        divisions: [],
        facilities: [],
        results: [],
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setAssociationData: (state, action) => {
            state.services = action.payload.services
            state.vendors = action.payload.vendors
            state.divisions = action.payload.divisions
            state.facilities = action.payload.facilities
        },
        setResults: (state, action) => {
            state.results = action.payload.billable_accounts?.map((b) => ({ ...b, type: "billable_accounts" }))?.concat(action.payload.supply_accounts?.map((b) => ({ ...b, type: "supply_accounts" })))
        },
    },
})

export const { setLoading, setAssociationData, setResults } = searchSlice.actions

export function fetchSearchAssociations(clientId = null) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(clientId ? advancedSearchAssociationsUrl(clientId) : globalAdvancedSearchAssociationsUrl)
            .then(({ data }) => {
                dispatch(setAssociationData(data))
            })
            .catch((e) => {
                console.error(e)
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function performSearch(clientId, values) {
    return async (dispatch) => {
        const params = Object.keys(values)
            .map((key, index) => {
                const value = values[key]

                if (!value) {
                    return null
                }

                return [key, value]
            })
            .filter((i) => !!i)
            .map((value, index) => {
                return `${!index ? "?" : "&"}[q][${value[0]}]=${value[1].toString()}`
            })
            .join("")

        if (!params) {
            notification.warn({
                message: "Search Error",
                description: "You must provide at least one search filter",
            })
            return
        }

        dispatch(setLoading(true))

        client
            .get(`${clientId ? advancedSearchUrl(clientId) : globalAdvancedSearchUrl }${params}`)
            .then(({ data }) => {
                dispatch(setResults(data))
            })
            .catch((e) => {
                console.error(e)
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectLoading = (state) => state.search.loading
export const selectServices = (state) => state.search.services
export const selectVendors = (state) => state.search.vendors
export const selectDivisions = (state) => state.search.divisions
export const selectFacilities = (state) => state.search.facilities
export const selectResults = (state) => state.search.results

export default searchSlice.reducer
