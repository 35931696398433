import React from "react"
import { Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import AncillaryServicesNav from "../features/ancillaryServices/AncillaryServicesNav"
import AncillaryServicesPage from "../features/ancillaryServices/AncillaryServicesPage"

export default function AncillaryServiceRoutes() {
    /// change the first route path when we have an ancillary services h
    return (
        <Switch>
            <Route exact path={`/clients/:clientId/ancillary_services`}>
                <ConsoleLayout
                    highlightTab="ancillaryServices"
                    header={<AncillaryServicesNav activeTab="list" />}
                    body={<AncillaryServicesPage view="index" recordSelected={false} />}
                    sidebarType="ancillary_services"
                />
            </Route>

            <Route exact path={`/clients/:clientId/ancillary_services/site_surveys`}>
                <ConsoleLayout
                    highlightTab="ancillaryServices"
                    header={<AncillaryServicesNav activeTab="siteSurveys" />}
                    body={<AncillaryServicesPage view="siteSurveys" recordSelected={false} />}
                    sidebarType="ancillary_services"
                />
            </Route>

            <Route exact path={`/clients/:clientId/ancillary_services/site_surveys/new`}>
                <ConsoleLayout
                    highlightTab="ancillaryServices"
                    header={<AncillaryServicesNav activeTab="siteSurveys" />}
                    body={<AncillaryServicesPage view="siteSurveysForm" recordSelected={false} />}
                    sidebarType="ancillary_services"
                />
            </Route>

            <Route exact path={`/clients/:clientId/ancillary_services/site_surveys/:siteSurveyId/edit`}>
                <ConsoleLayout
                    highlightTab="ancillaryServices"
                    header={<AncillaryServicesNav activeTab="siteSurveys" />}
                    body={<AncillaryServicesPage view="siteSurveysForm" recordSelected={true} />}
                    sidebarType="ancillary_services"
                />
            </Route>
        </Switch>
    )
}
