import { allObjectValuesEmpty } from "./allObjectValuesEmpty"

export function createFilterUrl(query, clientScopedUrl, alternateFilter = "filter") {
    /// create the start of the filter URL string
    let filterUrl = ""

    if (alternateFilter === "") {
        filterUrl = `${clientScopedUrl}?`
    } else {
        filterUrl = `${clientScopedUrl}/${alternateFilter}?`
    }

    /// if all of the values in the filter object are not empty, we take each of the key-value pairs in the query,
    ///  and add on another part to the filterUrl. in the query:
    /// { each key } - correlates to the field that needs to be filtered and its ransack predicate (ex. name_cont), and
    /// { each value } - correlates to the string to filter it by
    if (!allObjectValuesEmpty(query.filters)) {
        Object.entries(query.filters).forEach(([key, value]) => {
            filterUrl += `q[${key}]=${value}&`
        })
    }

    /// add in filter by column and direction
    filterUrl += `page=${query.pagination.current}&per=${query.pagination.pageSize}&q[s]=${query.sort.column} ${query.sort.direction}`

    return filterUrl
}
