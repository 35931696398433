import { Button, Form, Input, Row, Col, notification } from "antd"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, Link, useLocation } from "react-router-dom"

import { forgotPassword, selectLoading, selectLoggedIn } from "./passwordResetSlice"
import MucLogoCircle from "../../components/utilityComponents/assetComponents/MucLogoCircle"

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function ForgotPassword() {
    const dispatch = useDispatch()
    let query = useQuery()

    const accountConfirmation = query.get("account_confirmation_success")

    const submit = async (values) => {
        console.log("Redirect URL: ", process.env.REACT_APP_FRONTEND_RESET_PASSWORD_URL)
        const response = await dispatch(forgotPassword({ ...values, redirect_url: process.env.REACT_APP_FRONTEND_RESET_PASSWORD_URL }))

        if (response) {
            notification.success({
                message: "Password reset request received",
                description: "If an account with this email exists, a password reset link will be sent to it",
            })
        } else {
            notification.error({
                message: "There was an error with the request",
            })
        }
    }

    return (
        <div id="login-content">
            <Row gutter={[0, 30]} style={{ marginBottom: "30px" }}>
                <Col className="login-logo">
                    <MucLogoCircle />
                </Col>
            </Row>

            <Form onFinish={(values) => submit(values)}>
                {accountConfirmation && (
                    <Row gutter={[16, 4]}>
                        <h3>Account confirmation successful! Please enter your email to set a new account password</h3>
                    </Row>
                )}
                <Form.Item name="email" label="Email" rules={[{ required: true, type: "email" }]}>
                    <Input />
                </Form.Item>

                <Row id="login-buttons-row" gutter={[16, 4]}>
                    <Col span={24}>
                        <Button id="sign-in-button" type="warning" htmlType="submit" style={{ width: "100%" }}>
                            Reset Password
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
