import React from "react"

import FacilityTypeTable from "./components/FacilityTypeTable"
import FacilityTypeForm from "./components/FacilityTypeForm"

export default function FacilityTypePage({ view, recordSelected = null }) {
    const renderView = () => {
        switch (view) {
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            default:
                return renderIndex()
        }
    }

    const renderIndex = () => {
        return <FacilityTypeTable />
    }

    const renderForm = () => {
        if (recordSelected) {
            return <FacilityTypeForm actionName="edit" />
        }

        return <FacilityTypeForm actionName="new" />
    }

    return <>{renderView()}</>
}
