export const states = ['Alabama','Alaska','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District Of Columbia','Florida','Georgia','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia', 'Wisconsin', 'Wyoming', 'Alberta', 'Ontario', 'British Columbia', 'District of Columbia', 'Shropshire', 'Northants', 'Quebec', 'Newfoundland and Labrador', 'New Brunswick', 'Manitoba', 'Saskatchewan', 'Nova Scotia', 'Sonora']

export function formatCurrency(value, symbol=true) {
  if (!value){
    value = '0.00'
  }

  var symbolConfig = symbol ? { style: 'currency', currency: 'USD' } : {}

  return new Intl.NumberFormat('en-US', symbolConfig).format(parseFloat(value));
}

export function roundHumanizedNumbers(value){
  if (!value){
    value = '0.00'
  }
  return new Intl.NumberFormat('en-US').format(Math.round(parseFloat(value)));
}