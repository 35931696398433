import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Form, Input, Row, Col, Select } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { notification } from 'antd'
import { fetchPaymentMethod, setPaymentMethod, submitPaymentMethod, selectPaymentMethod } from '../paymentMethodsSlice';

const { Option } = Select

export default function PaymentMethodForm() {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const payment_method = useSelector(selectPaymentMethod)
    const { paymentMethodId } = useParams()
    const history = useHistory()

    useEffect(() => {
        if (paymentMethodId) {
            dispatch(fetchPaymentMethod(paymentMethodId))
        } else {
            dispatch(setPaymentMethod({}))
        }
    }, [dispatch, paymentMethodId])

    useEffect(() => {
        form.resetFields()
    }, [form, payment_method])

    const handleServicesSelect = (value) => {
        form.setFieldsValue({ services: value })
    }

    const submit = async (values) => {
        const response = await dispatch(submitPaymentMethod(values))

        if (response.success) {
            history.push("/vendors/payment_methods")
        } else {
            notification.error({
                message: "Request error",
                description: response.message
            })
        }
    }

    return (
        <div id="user-form-container">
            <Link to="/vendors/payment_methods">
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={payment_method} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Payment Method Name" name="name" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
