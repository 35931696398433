import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Form, Input, Row, Col, Skeleton, Switch } from "antd"
import { LeftOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons"
import { mapKeys } from "lodash"

import { fetchClient, setClient, selectClient, submitClient, selectLoading } from "../clientsSlice"

export default function ClientForm() {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const client = useSelector(selectClient)
    const loading = useSelector(selectLoading)
    const { clientId } = useParams()
    const history = useHistory()
    const [batchGroupsToRemove, setBatchGroupsToRemove] = useState([])

    useEffect(() => {
        if (clientId) {
            dispatch(fetchClient(clientId))
        } else {
            dispatch(setClient(null))
        }
    }, [dispatch, clientId])

    useEffect(() => {
        form.resetFields()
    }, [form, client])

    // We must set the _destroy attribute of nested fields in order for rails
    // to mark them for deletion
    const setBatchGroupsForRemoval = (removeFnc, id) => {
        const batchGroups = form.getFieldValue("batch_groups")

        if (batchGroups && batchGroups[id]) {
            setBatchGroupsToRemove(batchGroupsToRemove.concat({ id: batchGroups[id].id, _destroy: true }))
        }

        removeFnc()
    }

    const removeBatchGroups = (values) => {
        const batchGroups = values.batch_groups
        return { ...values, batch_groups: { ...batchGroups, ...batchGroupsToRemove } }
    }

    const submit = async (values) => {
        const finalValues = removeBatchGroups(values)

        // We need to update the keys to always be passing in "batch_groups_attributes"
        const updatedKeys = mapKeys(finalValues, (value, key) => {
            if (key === "batch_groups") {
                return "batch_groups_attributes"
            } else {
                return key
            }
        })

        const response = await dispatch(submitClient(updatedKeys, clientId))

        if (response.success) {
            history.push("/clients")
        }
    }

    return (
        <div id="user-form-container">
            <Link to="/clients">
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={{ ...client, bills_due_on: client ? client.bills_due_on : "Upon Receipt", active: client ? client.active : true }} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <Skeleton active loading={loading}>
                            <div>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Active" name="active" valuePropName="checked" rules={[{ required: false }]}>
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Short Code" name="short_code" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Identifier" name="identifier" rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Bills Due On" name="bills_due_on" rules={[{ required: false }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Notes" name="notes">
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <h2>Batch Groups</h2>
                                        <Form.List name={`${clientId ? "batch_groups" : "batch_groups_attributes"}`}>
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map((field) => (
                                                        <Row gutter={24} key={field.name}>
                                                            <Col span={8}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Name"
                                                                    name={[field.name, "name"]}
                                                                    fieldKey={[field.fieldKey, "name"]}
                                                                    rules={[{ required: true, message: "Missing Batch Group Name" }]}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span={2}>
                                                                <MinusCircleOutlined
                                                                    // TODO: Properly style this
                                                                    style={{ color: "red", position: "absolute", top: "39px" }}
                                                                    onClick={() => setBatchGroupsForRemoval(() => remove(field.name), field.name)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    ))}

                                                    <Form.Item>
                                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ color: "black" }}>
                                                            Add Batch Group
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Col>
                                </Row>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Skeleton>
                    </div>
                </div>
            </Form>
        </div>
    )
}
