import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Form, Row, Col } from "antd"
import { useParams } from "react-router-dom"
import { fetchSearchAssociations, performSearch, selectResults, setResults } from "../searchSlice"
import AdvancedSearchAssociations from "./AdvancedSearchAssociations"
import AdvancedSearchOptionsInputs from "./AdvancedSearchOptionsInputs"
import AdvancedSearchResults from "./AdvancedSearchResults"
import AdvancedSearchLedgerAccount from "./AdvancedSearchLedgerAccount"
import { fetchClientLedgerFieldsSelectOptions } from "../../financials/ledgerFields/ledgerFieldsSlice"
import AdvancedSearchPayments from "./AdvancedSearchPayments"

export default function AdvancedSearchForm() {
    const dispatch = useDispatch()

    const { clientId } = useParams()

    const [form] = Form.useForm()

    const [init, setInit] = useState(false)

    const results = useSelector(selectResults)

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        dispatch(setResults([]))
        // fetch search associations
        dispatch(fetchSearchAssociations(clientId))
        // fetch ledger fields
        clientId && dispatch(fetchClientLedgerFieldsSelectOptions(clientId))
    }, [dispatch, init])

    const submit = (values) => {
        dispatch(performSearch(clientId, values))
    }

    return (
        <div id="user-form-container">
            <Form form={form} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <AdvancedSearchAssociations />
                            <hr />
                            <AdvancedSearchOptionsInputs />
                            <hr />
                            <Row gutter={24}>
                            { 
                                clientId &&
                                <Col span={12}>
                                     <AdvancedSearchLedgerAccount />
                                </Col>
                            }
                                <Col span={12}>
                                    <AdvancedSearchPayments />
                                </Col>
                            </Row>

                            <Form.Item style={{ textAlign: "center" }}>
                                <Button type="primary" htmlType="submit" size="large">
                                    Submit
                                </Button>
                            </Form.Item>

                            {results && !!results.length && (
                                <div>
                                    <hr />
                                    <AdvancedSearchResults />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
