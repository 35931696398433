import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../stores/appSlice"
import VendorTable from "./components/VendorTable"
import VendorForm from "./components/VendorForm"
import ImportForm from "../imports/ImportForm"

// import BillTable from "../vendors/components/VendorTable"

export default function VendorPage({ view, recordSelected }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const renderView = () => {
        switch (view) {
            case "import":
                return renderImport()
            case "dashboard":
                return renderDashboard()
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            case "bills":
                return renderBills()
            default:
                return renderDashboard()
        }
    }

    const renderDashboard = () => {
        if (!scopedByClient) {
            return null
        } else if (recordSelected) {
            return <h1>Single Vendor Dashboard</h1>
        }

        return <h1>Vendors dashboard (scoped by client)</h1>
    }

    const renderIndex = () => {
        if (scopedByClient) {
            return (
                <>
                    <VendorTable scopedByClient={scopedByClient} />
                </>
            )
        }

        return <VendorTable scopedByClient={scopedByClient} />
    }

    const renderImport = () => {
        console.log('vendors render page')
        return <ImportForm dataType="vendor" />
    }

    const renderForm = () => {
        if (recordSelected) {
            return (
                <>
                    <h1>Edit Form</h1>
                    <VendorForm actionName="edit" />
                </>
            )
        }

        return (
            <>
                <h1>New Form</h1>
                <VendorForm actionName="new" />
            </>
        )
    }

    const renderBills = () => {
        return <h1>Vendor's Bills</h1>
    }

    return <>{renderView()}</>
}
