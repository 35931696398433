import React from "react"

export default function DashboardCard(props) {
    return (
        <div className={"card"}>
            <div className={"card-title"}>
                <b>{props.title}</b> {props.titleSuffix}
            </div>
            {/* When there is no data number we display an empty card with a dash in it as the number */}
            {
                props.dataNumber ? (
                    <div>
                        <div className={"card-content"}>
                            <div className={"card-icon-container"}>
                                {props.topIcon}
                                {props.bottomIcon}
                            </div>
                            <span className={"card-data-number"}>{props.dataNumber}</span>
                            <span className={"card-data-suffix"}>{props.dataSuffix}</span>
                        </div>
                        <div className={"card-data-footer"}>
                            <b>{props.footer}</b> {props.footerSuffix}{props.footerDataSuffix}
                        </div>
                    </div>
                ) : (
                    <div className="card-content">
                        <div className="card-data-number">
                            -
                        </div>
                    </div>
                )
            }
        </div>
    )
}
