import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchReports, selectReports } from "./reportsSlice"
import { Tooltip, Row, Col } from "antd"
import { QuestionCircleOutlined, FilePdfOutlined, FileTextOutlined } from "@ant-design/icons"
import { Link, useRouteMatch } from "react-router-dom"
import path from "path"

export default function ReportDashboard() {
    const { url } = useRouteMatch()

    const dispatch = useDispatch()

    const { clientId } = useParams()

    const [init, setInit] = useState(false)

    const reports = useSelector(selectReports)

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        dispatch(fetchReports(clientId))
    }, [dispatch, init, clientId])

    const fileIcon = (report) => {
        const type = report.export_type

        switch (type) {
            case "pdf":
                return FilePdfOutlined
            case "csv":
                return FileTextOutlined
            case "csv_and_pdf":
                return FilePdfOutlined
            default:
                console.log("Unknown file type: ", type)
                return null
        }
    }

    return (
        <div>
            <Row gutter={24}>
                {reports.map((r) => {
                    const Icon = fileIcon(r)

                    return (
                        <Col span={12} style={{ marginBottom: "25px" }}>
                            <Link to={path.join(url, "..", `${r.id}`, "new")} key={r.id}>
                                <div className="card report-card">
                                    <div className="card-title">
                                        <h2 style={{ display: "inline" }}>
                                            <Icon />
                                            &nbsp;
                                            {r.name}
                                        </h2>
                                        &nbsp;
                                        <Tooltip title={r.description}>
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </div>
                                    <div className="card-content">
                                        <b>Key Items:</b>&nbsp;{r.key_items}
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}
