import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Table, Tooltip, Skeleton, Menu, Dropdown, Button } from "antd"
import { DownloadOutlined, PlusOutlined, UploadOutlined, EditOutlined, FilterOutlined, SearchOutlined, DownOutlined, FieldBinaryOutlined, LoginOutlined } from "@ant-design/icons"

import { fetchClients, selectClients, selectTotal, selectPagination, selectLoading, filterClients } from "../clientsSlice"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import Role from "../../userRoles/Role"

export default function ClientTable() {
    /////*** redux start ***/////
    const dispatch = useDispatch()

    const clients = useSelector(selectClients)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialClients()
        }
    }, [loaded, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialClients = () => {
        dispatch(fetchClients(pagination))
    }

    const handleFilterClients = (newPagination = null, newSort = null) => {
        return dispatch(
            filterClients({
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortClientsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterClients(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchClients(newPagination))
        } else {
            handleFilterClients(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortClientsChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialClients()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Clients</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <Link to="/clients/new">
                                    <PlusOutlined />
                                    Add New
                                </Link>

                                <a href="javascript:;" onClick={() => console.log("Import")}>
                                    <UploadOutlined />
                                    Import
                                </a>

                                <a href="javascript:;" onClick={() => console.log("Export")}>
                                    <DownloadOutlined />
                                    Export
                                </a>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table
                            dataSource={clients}
                            pagination={{ ...pagination, showSizeChanger: true, total: total, pageSizeOptions: [10, 20, 50, 100, 1000] }}
                            onChange={handleTableChange}
                            rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("name")}
                                key="name"
                                dataIndex="name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialClients}
                                        filterAction={filterClients}
                                        filterColumn={"name"}
                                        filterType={"name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />

                            <Table.Column
                                title={renderColumnHeaderTitle("active")}
                                key="active"
                                dataIndex="active"
                                render={(text) => (text ? "Active" : "Deactivated")}
                                // filterDropdown={() => (
                                //     <ColumnSearchFilter
                                //         handleFetchInitial={handleFetchInitialClients}
                                //         filterAction={filterClients}
                                //         filterColumn={"deactivated"}
                                //         filterType={"deactivated"}
                                //         filters={filters}
                                //         pagination={pagination}
                                //         setFilters={setFilters}
                                //         sort={sort}
                                //     />
                                // )}
                                // filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />

                            <Table.Column
                                title={renderColumnHeaderTitle("short_code")}
                                key="short_code"
                                dataIndex="short_code"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialClients}
                                        filterAction={filterClients}
                                        filterColumn={"short_code"}
                                        filterType={"short_code_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("short_code_cont")}
                            />

                            <Table.Column
                                title=""
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Role action="update" model="client">
                                                        <Menu.Item key="1" icon={<EditOutlined />}>
                                                            <Link to={`/clients/${record.id}/edit`}>Edit Client</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="update" model="client">
                                                        <Menu.Item key="2" icon={<FieldBinaryOutlined />}>
                                                            <Link to={`/clients/${record.id}/client_vendor_numbers`}>Vendor Numbers</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="update" model="client">
                                                        <Menu.Item key="3" icon={<LoginOutlined />}>
                                                            <Link to={`/clients/${record.id}/dashboard`}>Scope to Client</Link>
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
