import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch } from "react-router-dom"
import { Table, Tooltip, Skeleton, Dropdown, Menu, Button } from "antd"
import { PlusOutlined, EditOutlined, FilterOutlined, SearchOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons"

import { fetchUnits, selectUnits, deleteUnit, selectTotal, selectPagination, selectLoading, filterUnits } from "../unitsSlice"
import { allObjectValuesEmpty } from "../../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../../functions/objectFunctions/tableFiltersApplied"
import ColumnSearchFilter from "../../../../components/utilityComponents/table/ColumnSearchFilter"
import useConfirmationModal from "../../../alertsPanel/ShowConfirm"

export default function UnitTable() {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const units = useSelector(selectUnits)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "symbol",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            // handleFetchInitialUnits()
            handleFilterUnits(pagination)
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialUnits = () => {
        dispatch(fetchUnits(pagination))
    }

    const handleFilterUnits = (newPagination = null, newSort = null) => {
        return dispatch(
            filterUnits({
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortUnitsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterUnits(null, newSort)
    }

    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteUnit, id);
    };
    
    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchUnits(newPagination))
        } else {
            handleFilterUnits(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortUnitsChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialUnits()
                        setFilters({})
                        setSort({ column: "", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Units of Measure</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <Link to={`${url}/new`}>
                                    <PlusOutlined />
                                    Add New
                                </Link>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={units} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("symbol")}
                                key="symbol"
                                dataIndex="symbol"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialUnits}
                                        filterAction={filterUnits}
                                        filterColumn={"symbol"}
                                        filterType={"symbol_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("symbol_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("description")}
                                key="description"
                                dataIndex="description"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialUnits}
                                        filterAction={filterUnits}
                                        filterColumn={"description"}
                                        filterType={"description_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("description_cont")}
                            />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Menu.Item key="1" icon={<EditOutlined />}>
                                                        <Link to={`/settings/units/${record.id}/edit`}>Edit Unit</Link>
                                                    </Menu.Item>

                                                    {/* <Role action="update" model="facility_type">
                                                        <Menu.Item key="1" icon={<EditOutlined />}>
                                                            <Link to={`/settings/facility_types/${record.id}/edit`}>Edit Facility Type</Link>
                                                        </Menu.Item>
                                                    </Role> */}

                                                    <Menu.Item key="2" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)}>
                                                        Delete Unit
                                                    </Menu.Item>

                                                    {/* <Role action="destroy" model="facility_type">
                                                        <Menu.Item key="2" icon={<DeleteOutlined />} onClick={() => dispatch(deleteFacilityType(record.id))}>
                                                            Delete FacilityType
                                                        </Menu.Item>
                                                    </Role> */}
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
