import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form, Input, Row, Col, Button, Select, notification, Switch } from "antd"
import { Link, useRouteMatch, useParams, useHistory } from "react-router-dom"
import path from "path"
import { fetchBillDocument, selectDocument, setDocument, generateBillRepository, updateBillRepository } from "./billDocumentRepositoriesSlice"
import moment from "moment"
import ReportAssignee from "../reports/ReportAssignee"
import ReportUtilityServices from "../reports/types/fields/ReportUtilityServices"
import ReportVendors from "../reports/types/fields/ReportVendors"
import ReportDivisions from "../reports/types/fields/ReportDivisions"
import ReportFacilities from "../reports/types/fields/ReportFacilities"
import ReportBillableAccounts from "../reports/types/fields/ReportBillableAccounts"
import ReportSupplyAccounts from "../reports/types/fields/ReportSupplyAccounts"
import ReportAccountingBatches from "../reports/types/fields/ReportAccountingBatches"
import ReportDateRange from "../reports/types/fields/ReportDateRange"
import ReportPaymentBatches from "../reports/types/fields/ReportPaymentBatches"

export default function BillDocumentRepositoryForm({ actionName }) {
    const dispatch = useDispatch()

    const history = useHistory()
    const { url } = useRouteMatch()

    const { clientId, billDocumentId } = useParams()

    const [init, setInit] = useState(false)
    const [regen, setRegen] = useState(false)

    const document = useSelector(selectDocument)

    const [form] = Form.useForm()

    useEffect(() => {
        return () => {
            dispatch(setDocument(null))
        }
    }, [])

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        if (billDocumentId) {
            dispatch(fetchBillDocument(clientId, billDocumentId))
        }
    }, [init])

    useEffect(() => {
        if (!document) {
            return
        }

        form.resetFields()
    }, [document])

    const backPath = () => {
        if (actionName === "new") {
            return path.join(url, "..")
        } else {
            return path.join(url, "..", "..")
        }
    }

    const submit = async (values) => {
        const response = await dispatch(actionName === "new" ? generateBillRepository(clientId, values) : updateBillRepository(clientId, billDocumentId, values))

        if (response.success) {
            notification.success({
                message: response.message,
            })

            history.push(backPath())
        } else {
            notification.error({
                message: "Request error",
                description: response.message,
            })
        }
    }

    const initialValues = () => {
        const doc = document || { form_data: { date_range: [moment(), moment()] } }

        if (actionName === "new") {
            return {}
        } else {
            return {
                ...doc,
                form_data: {
                    ...doc.form_data,
                    date_range: doc.form_data?.date_range ? doc.form_data.date_range.map((d) => moment(d)) : null,
                },
            }
        }
    }

    return (
        <div id="user-form-container">
            <Form form={form} onFinish={submit} initialValues={initialValues()}>
                <div className="card">
                    <div className="card-body" style={{ display: "block" }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="Title" name="name" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <ReportAssignee form={form} record={document} />

                        <Row gutter={24}>
                            <Col span={24}>
                                <ReportDateRange />
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <ReportUtilityServices required={false} />
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <ReportAccountingBatches />
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <ReportPaymentBatches />
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <ReportVendors />
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <ReportDivisions />
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <ReportFacilities />
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <ReportBillableAccounts />
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <ReportSupplyAccounts />
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="Last Bill Only" name="last_bill_only">
                                    <Switch defaultChecked={document?.last_bill_only} />
                                </Form.Item>
                            </Col>
                        </Row>

                        {actionName === "edit" && (
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label="Regenerate Report" name="regenerate">
                                        <Switch checked={regen} onChange={(c) => setRegen(c)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    )
}
