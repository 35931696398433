import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import GroupSupplyAccountNav from "../features/groupSupplyAccounts/GroupSupplyAccountNav"
import GroupSupplyAccountPage from "../features/groupSupplyAccounts/GroupSupplyAccountPage"

export default function GroupSupplyAccountRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout
                    highlightTab="groupSupplyAccounts"
                    header={<GroupSupplyAccountNav activeTab="list" />}
                    body={<GroupSupplyAccountPage view="index" recordSelected={false} />}
                    sidebarType="groupSupplyAccounts"
                />
            </Route>

            {/* <Route path={`${path}/new`}>
                <ConsoleLayout highlightTab="groupSupplyAccounts" header={<GroupSupplyAccountNav activeTab="list" />} body={<GroupSupplyAccountPage view="form" recordSelected={false} />} sidebarType="groupSupplyAccounts" />
            </Route>

            <Route path={`${path}/dashboard`}>
                <ConsoleLayout
                    highlightTab="groupSupplyAccounts"
                    header={<GroupSupplyAccountNav activeTab="dashboard" />}
                    body={<GroupSupplyAccountPage view="dashboard" recordSelected={false} />}
                    sidebarType="groupSupplyAccounts"
                />
            </Route>

            <Route path={`${path}/:groupSupplyAccountId/dashboard`}>
                <ConsoleLayout
                    highlightTab="groupSupplyAccounts"
                    header={<GroupSupplyAccountNav activeTab="dashboard" scopedByGroupSupplyAccount={true} />}
                    body={<GroupSupplyAccountPage view="dashboard" recordSelected={true} />}
                    sidebarType="groupSupplyAccounts"
                />
            </Route> */}

            <Route path={`${path}/:groupSupplyAccountId/edit`}>
                <ConsoleLayout
                    highlightTab="groupSupplyAccountSubaccounts"
                    header={<GroupSupplyAccountNav activeTab="subaccounts" scopedByGroupSupplyAccount={true} />}
                    body={<GroupSupplyAccountPage view="form" recordSelected={true} />}
                    sidebarType="groupSupplyAccounts"
                />
            </Route>

            <Route path={`${path}/:groupSupplyAccountId/bills`}>
                <ConsoleLayout
                    highlightTab="groupSupplyAccountBills"
                    header={<GroupSupplyAccountNav activeTab="bills" scopedByGroupSupplyAccount={true} />}
                    body={<GroupSupplyAccountPage view="bills" recordSelected={true} />}
                    sidebarType="groupSupplyAccounts"
                />
            </Route>

            <Route path={`${path}/:groupSupplyAccountId/subaccounts`}>
                <ConsoleLayout
                    highlightTab="groupSupplyAccountSubaccounts"
                    header={<GroupSupplyAccountNav activeTab="subaccounts" scopedByGroupSupplyAccount={true} />}
                    body={<GroupSupplyAccountPage view="subaccounts" recordSelected={true} />}
                    sidebarType="groupSupplyAccounts"
                />
            </Route>

            <Route path={`${path}/:groupSupplyAccountId`}>
                <ConsoleLayout
                    highlightTab="groupSupplyAccounts"
                    header={<GroupSupplyAccountNav activeTab="dashboard" scopedByGroupSupplyAccount={true} />}
                    body={<GroupSupplyAccountPage view="dashboard" recordSelected={true} />}
                    sidebarType="groupSupplyAccounts"
                />
            </Route>
        </Switch>
    )
}
