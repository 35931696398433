import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useRouteMatch, Link } from "react-router-dom"
import { Table, Skeleton, Tooltip, Button, Popconfirm, notification } from "antd"
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"

import { fetchClientVendorNumbers, selectClientVendorNumbers, selectLoading, deleteClientVendorNumber, selectPagination, selectTotal } from "./clientVendorNumbersSlice"
import { titleize } from "../../lib/string"
import { allObjectValuesEmpty } from "../../functions/objectFunctions/allObjectValuesEmpty"

export default function ClientVendorNumberTable() {
    const dispatch = useDispatch()

    const { clientId } = useParams()
    const { url } = useRouteMatch()

    const [init, setInit] = useState(false)

    const clientVendorNumbers = useSelector(selectClientVendorNumbers)
    const total = useSelector(selectTotal)
    // const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        dispatch(fetchClientVendorNumbers(clientId))
    }, [dispatch, init, clientId])

    const deleteRecord = async (id) => {
        const response = await dispatch(deleteClientVendorNumber(clientId, id))
        if (response.success) {
            notification.open({
                message: "Success",
                description: "Deleted Client Vendor Number",
            })
        } else {
            notification.open({
                message: "Error",
                description: "There was an error deleting the Client Vendor Number",
            })
        }
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Client Vendor Numbers</h2>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                <Link to={`${url}/new`}>
                                    <PlusOutlined />
                                    Add New
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Skeleton active loading={loading}>
                        <Table dataSource={clientVendorNumbers} rowKey={(r) => r.id}>
                            <Table.Column title="Vendor" key="vendor_name" dataIndex="vendor_name" render={(r) => titleize(r)} />
                            <Table.Column title="Vendor Number" key="vendor_number" dataIndex="vendor_number" />

                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <div className="row-actions" style={{ display: "inline-block" }}>
                                            <Link to={`${url}/${record.id}/edit`}>
                                                <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip>
                                            </Link>

                                            <Popconfirm title="Are you sure you want to delete this Client Vendor Number?" onConfirm={() => deleteRecord(record.id)} className="row-actions pl-2">
                                                <Link to={"javascript:;"}>Delete</Link>
                                            </Popconfirm>
                                        </div>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}
