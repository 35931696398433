import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../stores/appSlice"
import FacilityTable from "./components/FacilityTable"
import FacilityForm from "./components/FacilityForm"
import BillableAccountTable from "../billableAccounts/components/BillableAccountTable"
import SupplyAccountTable from "../supplyAccounts/components/SupplyAccountTable"
import Dashboard from "../../features/dashboards/components/Dashboard"
import DocumentsTable from "../documents/components/DocumentsTable"
import DocumentForm from "../documents/components/DocumentForm"
import { useParams } from "react-router"
import ImportForm from "../imports/ImportForm"

export default function FacilityPage({ view, recordSelected }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const { clientId, facilityId } = useParams()

    const renderView = () => {
        switch (view) {
            case "dashboard":
                return renderDashboard()
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            case "billableAccounts":
                return renderBillableAccounts()
            case "supplyAccounts":
                return renderSupplyAccounts()
            case "documents":
                return renderDocuments()
            case "documents-form":
                return renderDocumentsForm()
            case "import":
                return renderImport()
            default:
                return renderDashboard()
        }
    }

    const renderDashboard = () => {
        if (!scopedByClient) {
            return null
        } else if (recordSelected) {
            return <Dashboard scopeType="Facility" scopeId={facilityId} clientId={clientId} />
        }

        return <Dashboard scopeType="Facility" scopeId={null} clientId={clientId} />
    }

    const renderIndex = () => {
        if (scopedByClient) {
            return (
                <>
                    <FacilityTable scopedByClient={scopedByClient} />
                </>
            )
        }

        return <FacilityTable scopedByClient={scopedByClient} />
    }

    const renderForm = () => {
        if (recordSelected) {
            return (
                <>
                    <h1>Edit Form</h1>
                    <FacilityForm actionName="edit" />
                </>
            )
        }

        return (
            <>
                <h1>New Form</h1>
                <FacilityForm actionName="new" />
            </>
        )
    }

    const renderBillableAccounts = () => {
        return <BillableAccountTable scopedByClient={scopedByClient} />
    }

    const renderSupplyAccounts = () => {
        return <SupplyAccountTable scopedByClient={scopedByClient} />
    }

    const renderDocuments = () => {
        return <DocumentsTable type="Facility" id={facilityId} />
    }

    const renderDocumentsForm = () => {
        return <DocumentForm type="Facility" id={facilityId} />
    }

    const renderImport = () => {
        return <ImportForm dataType="facility" />
    }

    return <>{renderView()}</>
}
