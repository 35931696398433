import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import SupplyAccountNav from "../features/supplyAccounts/SupplyAccountNav"
import SupplyAccountPage from "../features/supplyAccounts/SupplyAccountPage"

export default function SupplyAccountRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="list" />}
                    body={<SupplyAccountPage view="index" recordSelected={false} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="list" />}
                    body={<SupplyAccountPage view="form" recordSelected={false} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/import`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="list" />}
                    body={<SupplyAccountPage view="import" recordSelected={false} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/:supplyAccountId/edit`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="list" scopedBySupplyAccount={false} />}
                    body={<SupplyAccountPage view="form" recordSelected={true} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/:supplyAccountId/dashboard`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="dashboard" scopedBySupplyAccount={true} />}
                    body={<SupplyAccountPage view="dashboard" recordSelected={true} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            {/* SERVICES ------------------------------------------------------------------------------------------------------------- */}

            <Route path={`${path}/:supplyAccountId/services/:serviceId/edit`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="services" scopedBySupplyAccount={true} />}
                    body={<SupplyAccountPage view="serviceForm" recordSelected={true} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/:supplyAccountId/services/new`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="services" scopedBySupplyAccount={true} />}
                    body={<SupplyAccountPage view="serviceForm" recordSelected={false} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/:supplyAccountId/services`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="services" scopedBySupplyAccount={true} />}
                    body={<SupplyAccountPage view="services" recordSelected={true} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            {/* END SERVICES --------------------------------------------------------------------------------------------------------- */}

            <Route path={`${path}/:supplyAccountId/bills`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="bills" scopedBySupplyAccount={true} />}
                    body={<SupplyAccountPage view="bills" recordSelected={true} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/:supplyAccountId/documents/new`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="documents" scopedBySupplyAccount={true} />}
                    body={<SupplyAccountPage view="documents-form" recordSelected={true} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/:supplyAccountId/documents/:documentId/edit`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="documents" scopedBySupplyAccount={true} />}
                    body={<SupplyAccountPage view="documents-form" recordSelected={true} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/:supplyAccountId/documents`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="documents" scopedBySupplyAccount={true} />}
                    body={<SupplyAccountPage view="documents" recordSelected={true} />}
                    sidebarType="supplyAccounts"
                />
            </Route>

            <Route path={`${path}/:supplyAccountId`}>
                <ConsoleLayout
                    highlightTab="supplyAccounts"
                    header={<SupplyAccountNav activeTab="dashboard" scopedBySupplyAccount={true} />}
                    body={<SupplyAccountPage view="dashboard" recordSelected={true} />}
                    sidebarType="supplyAccounts"
                />
            </Route>
        </Switch>
    )
}
