import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"

import { selectScopedByClient } from "../../stores/appSlice"
import { fetchGroupAccount, selectGroupAccount } from "./groupAccountsSlice"

export default function GroupAccountNav({ activeTab, scopedByGroupAccount = false }) {
    const dispatch = useDispatch()
    const { clientId, groupAccountId } = useParams()
    const groupAccount = useSelector(selectGroupAccount)
    const scopedByClient = useSelector(selectScopedByClient)

    // Fetch the groupAccount we need for breadcrumb
    // TODO: we may be able to simply swap this out for the use selector based
    // on how we decide to finally load in the records from other components.
    useEffect(() => {
        if (groupAccountId) {
            dispatch(fetchGroupAccount(groupAccountId))
        }
    }, [dispatch, groupAccountId])

    const formatRoute = (newRoute) => {
        if (clientId) {
            if (groupAccountId && scopedByGroupAccount) {
                return `/clients/${clientId}/group_accounts/${groupAccountId}${newRoute}`
            } else {
                return `/clients/${clientId}/group_accounts${newRoute}`
            }
        } else if (groupAccountId) {
            return `/group_accounts/${groupAccountId}${newRoute}`
        }

        return `/group_accounts${newRoute || ""}`
    }

    // If we are scoped by a client, we are looking at an end-user's view.
    // If we are not scoped by a client, we are looking at an admin's view.
    const renderHeader = () => {
        if (scopedByClient) {
            return scopedByGroupAccount ? renderRecordHeader() : renderIndexHeader()
        }

        // Admin - Not scoped by client here
        return (
            <>
                <h2>Group Accounts</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Group Accounts
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderRecordHeader = () => {
        return (
            <>
                <h2>{`${groupAccount.division_name} > ${groupAccount.facility_name}  > ${groupAccount.name}`}</h2>
                <ul className="header-tab-nav" role="navigation">
                    {/* Uknown if we need a dashboard for this page */}
                    {/* <li>
                        <Link to={() => formatRoute("/dashboard")}>
                            <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                                Dashboard
                            </button>
                        </Link>
                    </li> */}

                    <li>
                        <Link to={() => formatRoute("/subaccounts")}>
                            <button type="link" className={`${activeTab === "subaccounts" ? "selected" : ""}`}>
                                Subaccounts
                            </button>
                        </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/bills")}>
                            <button type="link" className={`${activeTab === "bills" ? "selected" : ""}`}>
                                Bills
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    const renderIndexHeader = () => {
        return (
            <>
                <h2>Group Accounts</h2>
                <ul className="header-tab-nav" role="navigation">
                    {/* Uknown if we need dashboard */}
                    {/* <li>
                        <Link to={() => formatRoute("/dashboard")}>
                            <button type="link" className={`${activeTab === "dashboard" ? "selected" : ""}`}>
                                Group Accounts Dashboard
                            </button>
                        </Link>
                    </li> */}
                    <li>
                        <Link to={() => formatRoute("")}>
                            <button type="link" className={`${activeTab === "list" ? "selected" : ""}`}>
                                All Group Accounts
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
