import { configureStore } from "@reduxjs/toolkit"
import appSliceReducer from "./appSlice.js"
import alertPanelSliceReducer from "../features/nav/alertPanelSlice"
import loginSliceReducer from "../features/login/loginSlice"
import clientsSliceReducer from "../features/clients/clientsSlice"
import channelPartnersSliceReducer from "../features/channelPartners/channelPartnersSlice"
import divisionsSliceReducer from "../features/divisions/divisionsSlice"
import facilitiesSliceReducer from "../features/facilities/facilitiesSlice"
import billableAccountsSliceReducer from "../features/billableAccounts/billableAccountsSlice"
import supplyAccountsSliceReducer from "../features/supplyAccounts/supplyAccountsSlice"
import groupAccountsSliceReducer from "../features/groupAccounts/groupAccountsSlice"
import groupSupplyAccountsSliceReducer from "../features/groupSupplyAccounts/groupSupplyAccountsSlice"
import billsSliceReducer from "../features/bills/billsSlice"
import servicesSliceReducer from "../features/services/servicesSlice"
import vendorsSliceReducer from "../features/vendors/vendorsSlice"
import paymentMethodsSliceReducer from "../features/vendors/paymentMethods/paymentMethodsSlice"
import utilityServicesSliceReducer from "../features/utilityServices/utilityServicesSlice"
import documentsSliceReducer from "../features/documents/documentsSlice"
import usersSliceReducer from "../features/users/usersSlice"
import userRolesSliceReducer from "../features/userRoles/userRolesSlice"
import rolesSliceReducer from "../features/roles/rolesSlice"
import siteSurveysReducer from "../features/ancillaryServices/siteSurveys/siteSurveysSlice"
import reportsReducer from "../features/reports/reportsSlice"
import dashboardsReducer from "../features/dashboards/dashboardsSlice"
import searchReducer from "../features/search/searchSlice"
import clientVendorNumbersReducer from "../features/clientVendorNumbers/clientVendorNumbersSlice"

// Financials
import accountingBatchesSliceReducer from "../features/financials/accountingBatches/accountingBatchesSlice"
import paymentBatchesSliceReducer from "../features/financials/paymentBatches/paymentBatchesSlice"
import ledgerFieldsSliceReducer from "../features/financials/ledgerFields/ledgerFieldsSlice"
import ledgerAccountsSliceReducer from "../features/financials/ledgerAccounts/ledgerAccountsSlice"

// Settings
import auditLogSliceReducer from "../features/settings/auditLog/auditLogSlice"
import facilityTypesSliceReducer from "../features/settings/facilityTypes/facilityTypesSlice"
import productionUnitsSliceReducer from "../features/settings/productionUnits/productionUnitsSlice"
import costParametersSliceReducer from "../features/settings/costParameters/costParametersSlice"
import unitsSliceReducer from "../features/settings/units/unitsSlice"

// Alerts
import billableAccountsMissingBillsSliceReducer from "../features/alertsPanel/BillableAccountsMissingBills/billableAccountsMissingBillsSlice"
import supplyAccountsMissingBillsSliceReducer from "../features/alertsPanel/SupplyAccountsMissingBills/supplyAccountsMissingBillsSlice"
import billableAccountsConsecutiveEstimationsReducer from "../features/alertsPanel/BillableAccountsConsecutiveEstimations/billableAccountsConsecutiveEstimationsSlice"
import pastDueBillsSliceReducer from "../features/alertsPanel/PastDueBills/pastDueBillsSlice.js"

// Bill Document Repository
import billDocumentRepositoriesSliceReducer from "../features/billDocumentRepositories/billDocumentRepositoriesSlice"

// Profile
import profilesSliceReducer from "../features/profiles/profilesSlice"

import { setLoggedIn } from "../features/login/loginSlice"
import { fetchAndSetUid } from "./appSlice"
import { getToken } from "../lib/storage"
import consumer, { processCableResponse } from "../lib/cable"

const store = configureStore({
    reducer: {
        app: appSliceReducer,
        alerts: alertPanelSliceReducer,
        login: loginSliceReducer,
        clients: clientsSliceReducer,
        clientVendorNumbers: clientVendorNumbersReducer,
        channelPartners: channelPartnersSliceReducer,
        divisions: divisionsSliceReducer,
        facilities: facilitiesSliceReducer,
        billableAccounts: billableAccountsSliceReducer,
        supplyAccounts: supplyAccountsSliceReducer,
        groupAccounts: groupAccountsSliceReducer,
        groupSupplyAccounts: groupSupplyAccountsSliceReducer,
        bills: billsSliceReducer,
        services: servicesSliceReducer,
        utilityServices: utilityServicesSliceReducer,
        auditLog: auditLogSliceReducer,
        facilityTypes: facilityTypesSliceReducer,
        productionUnits: productionUnitsSliceReducer,
        costParameters: costParametersSliceReducer,
        vendors: vendorsSliceReducer,
        payment_methods: paymentMethodsSliceReducer,
        users: usersSliceReducer,
        documents: documentsSliceReducer,
        units: unitsSliceReducer,
        accountingBatches: accountingBatchesSliceReducer,
        paymentBatches: paymentBatchesSliceReducer,
        ledgerFields: ledgerFieldsSliceReducer,
        ledgerAccounts: ledgerAccountsSliceReducer,
        userRoles: userRolesSliceReducer,
        roles: rolesSliceReducer,
        siteSurveys: siteSurveysReducer,
        billableAccountsMissingBills: billableAccountsMissingBillsSliceReducer,
        supplyAccountsMissingBills: supplyAccountsMissingBillsSliceReducer,
        billableAccountsConsecutiveEstimations: billableAccountsConsecutiveEstimationsReducer,
        pastDueBills: pastDueBillsSliceReducer,
        reports: reportsReducer,
        dashboards: dashboardsReducer,
        search: searchReducer,
        billDocumentRepositories: billDocumentRepositoriesSliceReducer,
        profiles: profilesSliceReducer,
    },
})

let initUid = false
let initUserConnectionSocket = false

const authToken = getToken()

if (authToken) {
    store.dispatch(setLoggedIn(true))
}

store.subscribe(async () => {
    const state = store.getState()
    const { loggedIn } = state.login
    const { uid } = state.app

    if (!initUid && loggedIn) {
        initUid = true

        store.dispatch(fetchAndSetUid())
    }

    if (!initUserConnectionSocket && uid) {
        initUserConnectionSocket = true
        setUpCableConnection(uid)
    }
})

function setUpCableConnection(uid) {
    consumer.subscriptions.create(
        {
            channel: "UserConnectionChannel",
            connection_id: uid,
        },
        {
            connected: () => console.log("connection established"),
            disconnected: (a, b, c) => console.log("a: ", a, " b: ", b, " c: ", c),
            received: (data) => processCableResponse(data),
        }
    )
}

export default store
