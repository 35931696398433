import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import LedgerNav from "../features/financials/LedgerNav"
import LedgerFieldPage from "../features/financials/ledgerFields/LedgerFieldPage"
import LedgerAccountPage from "../features/financials/ledgerAccounts/LedgerAccountPage"

export default function SettingsRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            {/* Ledger Fields ----------------------------------------------------------------------------------------------------- */}
            <Route path="/clients/:clientId/ledger_fields/:ledgerFieldId/edit">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<LedgerNav activeTab="ledgerFields" headerText="Ledger Fields" recordSelected={true} />}
                    body={<LedgerFieldPage view="form" recordSelected={true} />}
                    sidebarType="ledgerFields"
                />
            </Route>
            

            <Route path="/clients/:clientId/ledger_accounts/import">
                <ConsoleLayout highlightTab="financials" header={<LedgerNav activeTab="ledgerAccounts" />} body={<LedgerAccountPage view="import" recordSelected={false} />} sidebarType="ledgerAccounts" />
            </Route>

            <Route path="/clients/:clientId/ledger_fields/import">
                <ConsoleLayout highlightTab="financials" header={<LedgerNav activeTab="ledgerFields" />} body={<LedgerFieldPage view="import" recordSelected={false} />} sidebarType="ledgerFields" />
            </Route>

            <Route path="/clients/:clientId/ledger_fields/new">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<LedgerNav activeTab="ledgerFields" headerText="Ledger Fields" />}
                    body={<LedgerFieldPage view="form" recordSelected={false} />}
                    sidebarType="ledgerFields"
                />
            </Route>

            <Route path="/clients/:clientId/ledger_fields">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<LedgerNav activeTab="ledgerFields" headerText="Ledger Fields" />}
                    body={<LedgerFieldPage view="index" />}
                    sidebarType="ledgerFields"
                />
            </Route>

            {/* Ledger Accounts -------------------------------------------------------------------------------------------------- */}
            <Route path="/clients/:clientId/ledger_accounts/:ledgerAccountId/edit">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<LedgerNav activeTab="ledgerAccounts" headerText="Ledger Accounts" recordSelected={true} />}
                    body={<LedgerAccountPage view="form" recordSelected={true} />}
                    sidebarType="ledgerAccounts"
                />
            </Route>

            <Route path="/clients/:clientId/ledger_accounts/new">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<LedgerNav activeTab="ledgerAccounts" headerText="Ledger Accounts" />}
                    body={<LedgerAccountPage view="form" recordSelected={false} />}
                    sidebarType="ledgerAccounts"
                />
            </Route>

            <Route path="/clients/:clientId/ledger_accounts">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<LedgerNav activeTab="ledgerAccounts" headerText="Ledger Accounts" />}
                    body={<LedgerAccountPage view="index" />}
                    sidebarType="ledgerAccounts"
                />
            </Route>
        </Switch>
    )
}
