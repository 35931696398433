import React from "react"
import { Form, Switch } from "antd"

export default function ReportLedgerFields({ form }) {
    const val = form.getFieldsValue()?.form_data?.ledger_details

    return (
        <div>
            <Form.List name="form_data">
                {(fields) => (
                    <Form.Item label="Ledger Details" name="ledger_details">
                        <Switch checked={val} />
                    </Form.Item>
                )}
            </Form.List>
        </div>
    )
}
