import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"

import ConsoleLayout from "../ConsoleLayout"
import AccountingBatchNav from "../features/financials/AccountingBatchNav"
import AccountingBatchPage from "../features/financials/accountingBatches/AccountingBatchPage"

export default function SettingsRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout
                    highlightTab="financials"
                    header={<AccountingBatchNav activeTab="list" />}
                    body={<AccountingBatchPage view="index" recordSelected={false} />}
                    sidebarType="groupAccounts"
                />
            </Route>

            <Route path="/clients/:clientId/accounting_batches/:accountingBatchId/edit">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<AccountingBatchNav activeTab="accountingBatches" headerText="Client Batches" recordSelected={true} />}
                    body={<AccountingBatchPage view="form" recordSelected={true} />}
                    sidebarType="accountingBatches"
                />
            </Route>

            <Route path="/clients/:clientId/accounting_batches/:accountingBatchId/bills">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<AccountingBatchNav activeTab="bills" headerText="Bills" scopedByAccountingBatch={true}/>}
                    body={<AccountingBatchPage view="bills" recordSelected={true}/>}
                    sidebarType="accountingBatches"
                />
            </Route>

            <Route path="/clients/:clientId/accounting_batches/new">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<AccountingBatchNav activeTab="accountingBatches" headerText="Client Batches" />}
                    body={<AccountingBatchPage view="form" recordSelected={false} />}
                    sidebarType="accountingBatches"
                />
            </Route>

            <Route path="/clients/:clientId/accounting_batches">
                <ConsoleLayout
                    highlightTab="financials"
                    header={<AccountingBatchNav activeTab="list" headerText="Client Batches" />}
                    body={<AccountingBatchPage view="index" />}
                    sidebarType="accountingBatches"
                />
            </Route>
        </Switch>
    )
}
