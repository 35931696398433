import React from "react"

import ProductionUnitTable from "./components/ProductionUnitTable"
import ProductionUnitForm from "./components/ProductionUnitForm"

export default function ProductionUnitPage({ view, recordSelected = null }) {
    const renderView = () => {
        switch (view) {
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            default:
                return renderIndex()
        }
    }

    const renderIndex = () => {
        return <ProductionUnitTable />
    }

    const renderForm = () => {
        if (recordSelected) {
            return <ProductionUnitForm actionName="edit" />
        }

        return <ProductionUnitForm actionName="new" />
    }

    return <>{renderView()}</>
}
