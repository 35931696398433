import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import FacilityNav from "../features/facilities/FacilityNav"
import FacilityPage from "../features/facilities/FacilityPage"

export default function FacilityRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="list" />}
                    body={<FacilityPage view="index" recordSelected={false} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="list" />}
                    body={<FacilityPage view="form" recordSelected={false} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/import`}>
                <ConsoleLayout 
                    highlightTab="facilities" 
                    header={<FacilityNav activeTab="list" />} 
                    body={<FacilityPage view="import" recordSelected={false} />} 
                    sidebarType="facilities" />
            </Route>

            <Route path={`${path}/dashboard`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="dashboard" />}
                    body={<FacilityPage view="dashboard" recordSelected={false} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/:facilityId/edit`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="list" scopedByFacility={false} />}
                    body={<FacilityPage view="form" recordSelected={true} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/:facilityId/dashboard`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="dashboard" scopedByFacility={true} />}
                    body={<FacilityPage view="dashboard" recordSelected={true} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/:facilityId/billable_accounts`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="billable_accounts" scopedByFacility={true} />}
                    body={<FacilityPage view="billableAccounts" recordSelected={true} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/:facilityId/supply_accounts`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="supply_accounts" scopedByFacility={true} />}
                    body={<FacilityPage view="supplyAccounts" recordSelected={true} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/:facilityId/documents/new`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="documents" scopedByFacility={true} />}
                    body={<FacilityPage view="documents-form" recordSelected={true} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/:facilityId/documents/:documentId/edit`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="documents" scopedByFacility={true} />}
                    body={<FacilityPage view="documents-form" recordSelected={true} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/:facilityId/documents`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="documents" scopedByFacility={true} />}
                    body={<FacilityPage view="documents" recordSelected={true} />}
                    sidebarType="facilities"
                />
            </Route>

            <Route path={`${path}/:facilityId`}>
                <ConsoleLayout
                    highlightTab="facilities"
                    header={<FacilityNav activeTab="dashboard" scopedByFacility={true} />}
                    body={<FacilityPage view="dashboard" recordSelected={true} />}
                    sidebarType="facilities"
                />
            </Route>
        </Switch>
    )
}
