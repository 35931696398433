import React from "react"
import { Link } from "react-router-dom"

export default function BillAccountingBatchLink({ clientId, accountingBatchId, accountingBatchName }) {
    return (
        <Link to={`/clients/${clientId}/accounting_batches/${accountingBatchId}/edit/`} target="_blank">
            {accountingBatchName}
        </Link>
    )
}
