import React from "react"
import { useSelector } from "react-redux"
import { selectReport } from "./reportsSlice"
import { COST_AND_USAGE, INVENTORY, TRIAL_BALANCE, GLOBAL_DATA, ACCRUAL, FACILITIES, UNIVERSAL_DATA } from "../../lib/reports"
import CostAndUsage from "./types/CostAndUsage"
import Inventory from "./types/Inventory"
import TrialBalance from "./types/TrialBalance"
import GlobalData from "./types/GlobalData"
import UniversalData from "./types/UniversalData"

import Accrual from "./types/Accrual"
import Facilities from "./types/Facilities"

export default function ReportFields({ form, actionName }) {
    const report = useSelector(selectReport)

    switch (report?.name) {
        case COST_AND_USAGE:
            return <CostAndUsage />
        case INVENTORY:
            return <Inventory />
        case TRIAL_BALANCE:
            return <TrialBalance />
        case GLOBAL_DATA:
            return <GlobalData form={form} actionName={actionName} />
        case UNIVERSAL_DATA:
            return <UniversalData form={form} actionName={actionName} />    
        case ACCRUAL:
            return <Accrual />
        case FACILITIES:
            return <Facilities />
        default:
            console.error("ERROR: unknown report name: ", report?.name)
            return null
    }
}
