import React from "react"
import { Form, Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import SiteSurveysLightingFormPiece from "./SiteSurveysLightingFormPiece"

function SiteSurveysLightingForm(props) {
    /////*** event handler methods start ***/////
    const handleRemove = (field, remove) => {
        let allValues = props.form.getFieldValue()

        if (!!allValues.lightings_attributes[field.name]) {
            if (!!allValues.lightings_attributes[field.name]["site_survey_id"]) {
                let updatedFieldsToRemove = [...props.toRemove]
                updatedFieldsToRemove.push({ ...allValues.lightings_attributes[field.name], _destroy: true })
                props.setToRemove(updatedFieldsToRemove)
            }
        }
        remove(field.name)
    }
    /////*** event handler methods end ***/////

    return (
        <div id={"site-surveys-lighting-form"} className={"card"}>
            <Form.List name={"lightings_attributes"}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <SiteSurveysLightingFormPiece {...field} key={field.key} remove={() => handleRemove(field, remove)} />
                        ))}
                        <Form.Item className={"add-field"}>
                            <Button block onClick={() => add()} icon={<PlusOutlined />}>
                                Add field
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </div>
    )
}

export default SiteSurveysLightingForm
