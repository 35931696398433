import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { dynamicUrl, userRolesUrl, userRoleUrl } from "../../lib/endpoints"
import { notification } from "antd"
import { clientUserRootPath } from "../../lib/roles"

export const userRolesSlice = createSlice({
    name: "userRoles",
    initialState: {
        loading: false,
        userRoles: [],
        userRole: {},
        userRolesForUser: null,
        userRolesLoading: true,
        clientOnlyUser: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setUserRoles: (state, action) => {
            state.userRoles = action.payload
        },
        setUserRole: (state, action) => {
            state.userRole = action.payload
        },
        removeUserRole: (state, action) => {
            state.userRoles = state.userRoles.filter((ur) => {
                return ur.id !== action.payload
            })
        },
        setUserRolesForUser: (state, action) => {
            state.userRolesForUser = action.payload
        },
        setUserRolesLoading: (state, action) => {
            state.userRolesLoading = action.payload
        },
        setClientOnlyUser: (state, action) => {
            state.clientOnlyUser = action.payload
        },
    },
})

export const { setUserRoles, setLoading, setUserRole, removeUserRole, setUserRolesForUser, setUserRolesLoading, setClientOnlyUser } = userRolesSlice.actions

export function fetchUserRoles(userId) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(userRolesUrl(userId))
            .then(({ data }) => {
                dispatch(
                    setUserRoles(data)

                    // setUserRoles(
                    //     data.map((i) => {
                    //         return {
                    //             ...i.user_role,
                    //             user_role_clients_attributes: i.user_role_clients,
                    //             user_role_divisions_attributes: i.user_role_divisions,
                    //             user_role_facilities_attributes: i.user_role_facilities,
                    //             user_role_billable_accounts_attributes: i.user_role_billable_accounts,
                    //             user_role_supply_accounts_attributes: i.user_role_supply_accounts,
                    //         }
                    //     })
                    // )
                )
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchUserRole(userId, roleId) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(userRoleUrl(userId, roleId))
            .then(({ data }) => {
                dispatch(setUserRole(data))
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchUserRoleForUser(clientId, redirect) {
    return async (dispatch) => {
        client
            .get(dynamicUrl(`/user_roles/for_user?client_id=${clientId || ""}`))
            .then(({ data }) => {
                dispatch(setUserRolesForUser(data?.permissions))

                // check for redirect client id -- if there is one use the history.push callback that
                // was given as an arg to redirect to that url...
                if (data?.redirect_client_id && typeof redirect === "function") {
                    dispatch(setClientOnlyUser(true))
                    redirect(clientUserRootPath(data.redirect_client_id, data?.permissions))
                }

                if (data?.client_only_user) {
                    dispatch(setClientOnlyUser(true))
                }

                dispatch(setUserRolesLoading(false))
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
            })
    }
}

export function createUserRole(userId, values) {
    return async () => {
        try {
            const response = await client.post(userRolesUrl(userId), {
                user_role: values,
            })

            return response?.data
        } catch (e) {
            notification.error({
                message: "Request error",
                description: e?.response?.statusText,
            })
        }
    }
}

export function updateUserRole(userId, roleId, values) {
    return async () => {
        try {
            const response = await client.patch(userRoleUrl(userId, roleId), {
                user_role: values,
            })

            return response?.data
        } catch (e) {
            notification.error({
                message: "Request error",
                description: e?.response?.statusText,
            })
        }
    }
}

export function deleteUserRole(userId, roleId) {
    return async (dispatch) => {
        client
            .delete(userRoleUrl(userId, roleId))
            .then(() => {
                dispatch(removeUserRole(roleId))

                notification.success({
                    message: "Success",
                    description: "User role deleted",
                })
            })
            .catch((e) => {
                console.error(e)
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
            })
    }
}

export const selectUserRoles = (state) => state.userRoles.userRoles
export const selectUserRolesForUser = (state) => state.userRoles.userRolesForUser
export const selectUserRole = (state) => state.userRoles.userRole
export const selectLoading = (state) => state.userRoles.loading
export const selectUserRolesLoading = (state) => state.userRoles.userRolesLoading
export const selectClientOnlyUser = (state) => state.userRoles.clientOnlyUser

export default userRolesSlice.reducer
