import React from "react"
import { Form } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { debounce } from "lodash"
import PaginatedSelect from "../../../forms/components/PaginatedSelect"

import { fetchUtilityServices, selectUtilityServices, selectPagination, selectTotal, selectUtilityServiceSearch, searchUtilityServices } from "../../../utilityServices/utilityServicesSlice"

export default function ReportUtilityServices({ onChange, required = true, mode = 'single' }) {
    const dispatch = useDispatch()

    const pagination = useSelector(selectPagination)

    const handleSearch = debounce((value) => {
        // make sure they've typed at least 3 characters
        if (value && value.length >= 3) {
            dispatch(searchUtilityServices(value))
        }
    }, 1000)

    return (
        <div>
            <Form.List name="form_data">
                {(fields) => (
                    <Form.Item label="Utility Service" name="utility_service_id" rules={[{ required: required }]}>
                        <PaginatedSelect
                            mode={mode}
                            placeholder="Search utility services..."
                            onSearch={handleSearch}
                            paginationSelector={selectUtilityServices}
                            paginationTotalSelector={selectTotal}
                            loadPaginatedData={(p) => fetchUtilityServices(p, "utility_services")}
                            searchSelector={selectUtilityServiceSearch}
                            onChange={onChange}
                        />
                    </Form.Item>
                )}
            </Form.List>
        </div>
    )
}
