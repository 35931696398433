import { Button, Form, Input, Row, Col } from "antd"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, Link } from "react-router-dom"

import { requestLogin, selectLoading, selectLoggedIn } from "./loginSlice"
import MucLogoCircle from "../../components/utilityComponents/assetComponents/MucLogoCircle"

export default function Login() {
    const loading = useSelector(selectLoading)
    const loggedIn = useSelector(selectLoggedIn)

    const dispatch = useDispatch()

    if (loggedIn) {
        return <Redirect to="/" />
    }

    return (
        <div id="login-content">
            <Row gutter={[0, 30]} style={{ marginBottom: "30px" }}>
                <Col className="login-logo">
                    <MucLogoCircle />
                </Col>
            </Row>

            <Form onFinish={(values) => dispatch(requestLogin(values))}>
                <Form.Item name="email" label="Email" rules={[{ required: true, type: "email" }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="password" label="Password" rules={[{ required: true }]}>
                    <Input.Password />
                </Form.Item>

                <Row id="login-buttons-row" gutter={[16, 4]}>
                    <Col span={24}>
                        <Button id="sign-in-button" type="warning" htmlType="submit" loading={loading} style={{ width: "100%" }}>
                            Sign In
                        </Button>
                    </Col>
                </Row>

                <br />
                <Row gutter={24}>
                    <Col span={24}>
                        <Link to="/forgot_password">Forgot your password?</Link>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
