import { createSlice } from "@reduxjs/toolkit"
import client from "../../lib/client"
import { forgotPasswordUrl, resetPasswordUrl } from "../../lib/endpoints"
import { setClient, setToken, setUid } from "../../lib/storage"

export const passwordResetSlice = createSlice({
    name: "login",
    initialState: {
        loading: false,
        loggedIn: false,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload
        },
    },
})

export const { setLoading, setLoggedIn } = passwordResetSlice.actions

export function forgotPassword(values) {
    return async (dispatch, getState) => {
        try {
            const response = await client.post(forgotPasswordUrl, values)
            if (response.data.success) {
                return true
            } else {
                // This would be an actual error in the request/response unrelated to the
                // email address being invalid.
                return false
            }
        } catch (error) {
            // If this is set, it means that there isn't a user account with that email.
            // We want to obscure this error message from the user.
            if (error.response.data.errors.length > 0) {
                return true
            } else {
                return false
            }
        }
    }
}

export function resetPassword(values, accessToken, clientC, uid) {
    return async (dispatch, getState) => {
        const config = {
            headers: {
                "access-token": accessToken,
                client: clientC,
                uid: uid,
            },
        }

        try {
            const response = await client.put(resetPasswordUrl, values, config)
            return response.data
        } catch (error) {
            return error.response.data.errors.full_messages
        }
    }
}

export default passwordResetSlice.reducer
