import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

// http client
import client from "../../lib/client"
import { supplyAccountsUrl, supplyAccountUrl, dynamicUrl, supplyAccountsScopedByClientUrl, supplyAccountSearchUrl, supplyAccountCsvUrl } from "../../lib/endpoints"
import { createFilterUrl } from "../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../functions/objectFunctions/createRansackParams"

export const supplyAccountsSlice = createSlice({
    name: "supplyAccounts",
    initialState: {
        supplyAccount: {},
        supplyAccounts: [],
        supplyAccountSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
        supplyAccountSearch: [],
    },
    reducers: {
        setSupplyAccount: (state, action) => {
            state.supplyAccount = action.payload
        },
        setSupplyAccounts: (state, action) => {
            state.supplyAccounts = action.payload.supply_accounts
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setSupplyAccountSelectOptions: (state, action) => {
            state.supplyAccountSelectOptions = action.payload
        },
        setSupplyAccountSearch: (state, action) => {
            state.supplyAccountSearch = action.payload
        },
        deleteSupplyAccount: (state, action) => {
            state.supplyAccounts = state.supplyAccounts.filter((supplyAccount) => supplyAccount.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const { setSupplyAccount, setSupplyAccounts, setPagination, setLoading, setSupplyAccountSelectOptions, setSupplyAccountSearch } = supplyAccountsSlice.actions

export function fetchSupplyAccount(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(supplyAccountUrl(id)).then((response) => {
            dispatch(setSupplyAccount(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchSupplyAccounts(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${dynamicUrl(currentPath)}?values=[${pagination.values}]`)
            return response.data
        }

        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setSupplyAccounts(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function searchSupplyAccounts(query, url) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${dynamicUrl(url)}?q=${query}`)
            .then(({ data }) => {
                dispatch(setSupplyAccountSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchClientSupplyAccounts(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientSupplyAccountsPath = `clients/${clientId}/supply_accounts/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientSupplyAccountsPath)}`)
        return response.data.supply_accounts
    }
}

export function submitSupplyAccount(values) {
    return async (dispatch, getState) => {
        const id = getState().supplyAccounts.supplyAccount.id
        if (id) {
            // patch - update
            const response = await client.patch(supplyAccountUrl(id), { supply_account: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(supplyAccountsUrl, { supply_account: values })
            return response.data
        }
    }
}

export function deleteSupplyAccount(supplyAccountId) {
    return async (dispatch, getState) => {
        client
            .delete(`${supplyAccountUrl(supplyAccountId)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                    })

                    dispatch(supplyAccountsSlice.actions.deleteSupplyAccount(supplyAccountId))
                } else {
                    notification.error({
                        message: data.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
    }
}

// export function fetchSupplyAccountSelectOptions(searchTerm) {
//     return async (dispatch, getState) => {
//         client.get(`${supplyAccountSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
//             dispatch(setSupplyAccountSelectOptions(response.data.supplyAccounts))
//         })
//     }
// }

// export function fetchSupplyAccountSelectOptionById(id) {
//     return async (dispatch, getState) => {
//         client.get(`${supplyAccountSelectOptionByIdUrl}?supplyAccount_id=${id}`).then((response) => {
//             dispatch(setSupplyAccountSelectOptions(response.data.supplyAccounts))
//         })
//     }
// }

export function filterSupplyAccounts(query, { facilityId } = {}) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, supplyAccountsScopedByClientUrl(query.clientId))
    if (facilityId) {
        filterUrl += "&facility_id=" + facilityId
    }

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setSupplyAccounts(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateCsv(clientId, filters) {
    return async (dispatch, getState) => {
        client.get(`${supplyAccountCsvUrl(clientId)}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectSupplyAccount = (state) => state.supplyAccounts.supplyAccount
export const selectSupplyAccounts = (state) => state.supplyAccounts.supplyAccounts
export const selectTotal = (state) => state.supplyAccounts.total
export const selectPagination = (state) => state.supplyAccounts.pagination
export const selectLoading = (state) => state.supplyAccounts.loading
export const selectSupplyAccountSelectOptions = (state) => state.supplyAccounts.supplyAccountSelectOptions
export const selectSupplyAccountSearch = (state) => state.supplyAccounts.supplyAccountSearch

export default supplyAccountsSlice.reducer
