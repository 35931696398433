import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, notification } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"

import { fetchLedgerField, selectLedgerField, setLedgerField, submitLedgerField } from "../ledgerFieldsSlice"

import { requestNotification } from "../../../../lib/notifications"

export default function LedgerFieldForm({ actionName }) {
    const { url } = useRouteMatch()
    const history = useHistory()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const ledgerField = useSelector(selectLedgerField)
    const { ledgerFieldId, clientId } = useParams()

    useEffect(() => {
        if (ledgerFieldId) {
            dispatch(fetchLedgerField(ledgerFieldId, clientId))
        } else {
            dispatch(setLedgerField({}))
        }
    }, [dispatch, ledgerFieldId])

    useEffect(() => {
        form.resetFields()
    }, [form, ledgerField])

    const submit = async (values) => {
        const response = await dispatch(submitLedgerField({ ...values, client_id: clientId }, ledgerFieldId, clientId))

        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    return (
        <div id="user-form-container">
            <Link to={`/clients/${clientId}/ledger_fields`}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={ledgerField} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            {/* TODO: Why does this not work? */}
                            {/* <Form.Item name="client_id" hidden="true">
                                <Input value={clientId} />
                            </Form.Item> */}

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Field Name" name="name" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Field Description" name="description" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Field Order" name="order" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
