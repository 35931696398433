import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, InputNumber, Row, Col, Select, notification, Switch, Tooltip } from "antd"
import { LeftOutlined, PlusOutlined, MinusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import nodePath from "path"
import { mapKeys, isEmpty } from "lodash"

import { fetchService, setService, selectService, submitBillableService, submitSupplyService, fetchEntryFieldSelectOptions, selectEntryFieldSelectOptions } from "../servicesSlice"
import {
    fetchUtilityServiceSelectOptions,
    selectUtilityServiceSelectOptions,
    fetchUnitSelectOptions,
    selectUnitSelectOptions,
    fetchUsageFactorSelectOptions,
    selectUsageFactorSelectOptions,
} from "../../utilityServices/utilityServicesSlice"
import { fetchLedgerAccountSelectOptions, selectLedgerAccountSelectOptions } from "../../financials/ledgerAccounts/ledgerAccountsSlice"

import { requestNotification } from "../../../lib/notifications"

const { Option } = Select

export default function ServiceForm({ actionName, scopedByBillableAccount, scopedBySupplyAccount }) {
    const { url } = useRouteMatch()
    const history = useHistory()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const service = useSelector(selectService)
    const ledgerAccountSelectOptions = useSelector(selectLedgerAccountSelectOptions)
    const utilityServiceSelectOptions = useSelector(selectUtilityServiceSelectOptions)
    const unitSelectOptions = useSelector(selectUnitSelectOptions)
    const usageFactorSelectOptions = useSelector(selectUsageFactorSelectOptions)
    const entryFieldSelectOptions = useSelector(selectEntryFieldSelectOptions)
    const { clientId, billableAccountId, supplyAccountId, serviceId } = useParams()
    const [loaded, setLoaded] = useState(false)
    const [sharedAccountsToRemove, setSharedAccountsToRemove] = useState([])
    const [entryFieldsToRemove, setEntryFieldsToRemove] = useState([])

    useEffect(() => {
        if (serviceId) {
            dispatch(fetchService(serviceId))
        } else {
            dispatch(setService({}))
        }
    }, [dispatch, serviceId])

    // When a Service is set, we need to fetch its Ledger Accounts in order
    // to prepopulate the select with the current value
    useEffect(() => {
        form.resetFields()

        if (!loaded) {
            dispatch(fetchLedgerAccountSelectOptions(clientId))
            dispatch(fetchUtilityServiceSelectOptions(clientId))
            dispatch(fetchEntryFieldSelectOptions())
        }

        if (service && service.utility_service_id) {
            dispatch(fetchUnitSelectOptions(service.utility_service_id))
            dispatch(fetchUsageFactorSelectOptions(service.utility_service_id))
        }

        setLoaded(true)
    }, [dispatch, service]) //eslint-disable-line

    const handleLedgerAccountSelect = (value) => {
        form.setFieldsValue({ ledger_account_id: value })
    }

    const handleUtilityServiceSelect = (value) => {
        form.setFieldsValue({ utility_service_id: value, usage_unit_id: null, usage_factor_ids: [] })
        dispatch(fetchUnitSelectOptions(value))
        dispatch(fetchUsageFactorSelectOptions(value))
    }

    const handleUnitSelect = (value) => {
        form.setFieldsValue({ usage_unit_id: value })
    }

    const handleUsageFactorSelect = (value) => {
        form.setFieldsValue({ usage_factor_ids: value })
    }

    const handleEntryFieldSelect = (value) => {
        form.setFieldsValue({ entry_field_ids: value })
    }

    // We must set the _destroy attribute of nested fields in order for rails
    // to mark them for deletion
    const setSharedAccountsForRemoval = (removeFnc, id) => {
        const sharedAccounts = form.getFieldValue("shared_accounts")

        if (sharedAccounts && sharedAccounts[id]) {
            setSharedAccountsToRemove(sharedAccountsToRemove.concat({ id: sharedAccounts[id].id, _destroy: true }))
        }

        removeFnc()
    }

    const removeSharedAccounts = (values) => {
        const sharedAccounts = values.shared_accounts
        return { ...values, shared_accounts: { ...sharedAccounts, ...sharedAccountsToRemove } }
    }

    // We must set the _destroy attribute of nested fields in order for rails
    // to mark them for deletion
    const setEntryFieldsForRemoval = (removeFnc, id) => {
        const entryFields = form.getFieldValue("additional_entry_fields")

        if (entryFields && entryFields[id]) {
            setEntryFieldsToRemove(entryFieldsToRemove.concat({ id: entryFields[id].id, _destroy: true }))
        }

        removeFnc()
    }

    const removeEntryFields = (values) => {
        const entryFields = values.additional_entry_fields
        return { ...values, additional_entry_fields: { ...entryFields, ...entryFieldsToRemove } }
    }

    // validate that the sum of all shared acount share ratios is 1
    const validateShareRatios = (updatedKeys) => {
        let shareRatioValidation = false

        if (!isEmpty(updatedKeys.shared_accounts_attributes)) {
            let sum = 0
            Object.keys(updatedKeys.shared_accounts_attributes).map(function (key, index) {
                sum += parseFloat(updatedKeys.shared_accounts_attributes[key].share_ratio)
            })

            if (sum === 1) {
                shareRatioValidation = true
            }
        } else {
            shareRatioValidation = true
        }

        if (!shareRatioValidation) {
            notification.error({
                message: "Share Ratio Error",
                description: "Share ratios must add up to 1",
            })
        }

        return shareRatioValidation
    }

    const submit = async (values) => {
        const withRemovedAccounts = removeSharedAccounts(values)
        const finalValues = removeEntryFields(withRemovedAccounts)

        // We need to update the keys to always be passing in "shared_accounts_attributes"
        // and "usage_factors_attributes" when posting
        const updatedKeys = mapKeys(finalValues, (value, key) => {
            if (key === "shared_accounts") {
                return "shared_accounts_attributes"
            } else if (key === "additional_entry_fields") {
                return "additional_entry_fields_attributes"
            } else {
                return key
            }
        })

        // validate that the sum of all shared acount share ratios is 1
        const shareRatioValidation = validateShareRatios(updatedKeys)

        if (shareRatioValidation) {
            if (scopedByBillableAccount) {
                const response = await dispatch(submitBillableService({ ...updatedKeys, billable_account_id: billableAccountId }, serviceId, clientId, billableAccountId))
                showResponse(response)
            } else if (scopedBySupplyAccount) {
                const response = await dispatch(submitSupplyService({ ...updatedKeys, supply_account_id: supplyAccountId }, serviceId, clientId, supplyAccountId))
                showResponse(response)
            }
        }
    }

    const showResponse = (response) => {
        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    return (
        <div id="user-form-container">
            {/* TODO: will need to handle new vs edit action */}
            <Link to={actionName === "edit" ? nodePath.join(url, "../../").replace(/\/$/, "") : nodePath.join(url, "../").replace(/\/$/, "")}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={!isEmpty(service) ? service : { active: true }} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Active" name="active" valuePropName="checked" rules={[{ required: false }]}>
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="ID Number" name="id_number" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Tax Code" name="tax_code" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Service Name 1" name="service_name_1" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Service Name 2" name="service_name_2" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="Meter Number" name="meter_number" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Meter Multiplier" name="meter_multiplier" rules={[{ required: false }]}>
                                        <InputNumber style={{ width: "100%" }} precision={4} />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Rate Schedule" name="rate_schedule" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Contact Demand Capacity" name="contract_demand_capacity" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Supply" name="supply" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item label="notes" name="notes" rules={[{ required: false }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <hr />

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Ledger Account" name="ledger_account_id">
                                        <Select placeholder={"Select"} showArrow={true} onChange={handleLedgerAccountSelect}>
                                            {ledgerAccountSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <hr />
                            <h2>Shared Accounts</h2>

                            <Col span={8}>
                                <Form.Item label="Billable Ledgers" name="billable_ledgers" valuePropName="checked" rules={[{ required: false }]}>
                                    <Switch />
                                </Form.Item>
                            </Col>

                            <Form.List name="shared_accounts">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field) => (
                                            <Row gutter={24} key={field.name}>
                                                <Col span={8}>
                                                    <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.shared_accounts_attributes !== curValues.shared_accounts_attributes}>
                                                        {() => (
                                                            <Form.Item
                                                                {...field}
                                                                label="Ledger Account"
                                                                name={[field.name, "ledger_account_id"]}
                                                                fieldKey={[field.fieldKey, "ledger_account"]}
                                                                rules={[{ required: true, message: "Missing Ledger Account" }]}>
                                                                <Select>
                                                                    {ledgerAccountSelectOptions.map((o) => (
                                                                        <Option key={o.id} value={o.id}>
                                                                            {o.name}
                                                                        </Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        )}
                                                    </Form.Item>
                                                </Col>

                                                <Col span={8}>
                                                    <Form.Item
                                                        {...field}
                                                        label="Share Ratio"
                                                        name={[field.name, "share_ratio"]}
                                                        fieldKey={[field.fieldKey, "share_ratio"]}
                                                        rules={[{ required: true, message: "Missing Share Ratio" }]}>
                                                        {/* @TODO just like the other components, use this elsewhere and change precision to 4 for all decimals... */}
                                                        <InputNumber min={0} precision={4} step={0.01} max={1} style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={2}>
                                                    <MinusCircleOutlined
                                                        // TODO: Properly style this
                                                        style={{ color: "red", position: "absolute", top: "39px" }}
                                                        onClick={() => setSharedAccountsForRemoval(() => remove(field.name), field.name)}
                                                    />
                                                </Col>
                                            </Row>
                                        ))}

                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ color: "black" }}>
                                                Add Shared Account
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <hr />
                            <h2>Utility Service</h2>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="Utility Service" name="utility_service_id">
                                        <Select placeholder={"Select Utility Service"} showArrow={true} onChange={handleUtilityServiceSelect} disabled={false}>
                                            {utilityServiceSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item label="Unit of Measure" name="usage_unit_id">
                                        <Select placeholder={"Select Unit"} showArrow={true} onChange={handleUnitSelect} disabled={false}>
                                            {unitSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.symbol}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Additional Measures" name="usage_factor_ids">
                                        <Select placeholder={"Select Additional Measures"} showArrow={true} onChange={handleUsageFactorSelect} mode="multiple" className="black-text" allowClear>
                                            {usageFactorSelectOptions.map((o) => (
                                                <Option key={o.id} value={o.id}>
                                                    {o.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {/* <Tooltip title="Tooltip with customize">
                                    <InfoCircleOutlined />
                                </Tooltip> */}

                                <Col span={4}>
                                    <Form.Item
                                        label={
                                            <div>
                                                Emissions Factor&nbsp;
                                                <Tooltip title="Leave empty to use the default value from this utility service">
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </div>
                                        }
                                        name="emissions_factor"
                                        rules={[{ required: false }]}>
                                        <InputNumber style={{ width: "100%" }} precision={4} />
                                    </Form.Item>
                                </Col>

                                {/* <Col span={4}>
                                    <Form.Item label="Emissions Factor" name="emissions_factor" rules={[{ required: false }]}>
                                        <InputNumber style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col> */}
                            </Row>

                            <hr />
                            <Row gutter={24}>
                                <Col span={12}>
                                    <h2>Bill Entry Fields</h2>
                                    <Row gutter={24}>
                                        <Col span={18}>
                                            <Form.Item label="Entry Fields" name="entry_field_ids">
                                                <Select placeholder={"Select"} showArrow={true} mode="multiple" allowClear onChange={handleEntryFieldSelect} style={{ color: "black" }}>
                                                    {entryFieldSelectOptions.map((o) => (
                                                        <Option key={o.id} value={o.id}>
                                                            {o.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <p>Utility Charges and Other Charge are added automatically. Supply Charges are added when linked to a Supply Account.</p>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col span={12}>
                                    <h2>Additional Entry Fields</h2>
                                    <Form.List name="additional_entry_fields">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field) => (
                                                    <Row gutter={24} key={field.name}>
                                                        <Col span={8}>
                                                            <Form.Item
                                                                {...field}
                                                                label="Name"
                                                                name={[field.name, "name"]}
                                                                fieldKey={[field.fieldKey, "name"]}
                                                                rules={[{ required: true, message: "Missing Field Name" }]}>
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>

                                                        <Form.Item
                                                            label="Active"
                                                            name={[field.name, "active"]}
                                                            fieldKey={[field.fieldKey, "active"]}
                                                            valuePropName="checked"
                                                            rules={[{ required: false }]}>
                                                            <Switch defaultChecked />
                                                        </Form.Item>

                                                        <Col span={2}>
                                                            <MinusCircleOutlined
                                                                // TODO: Properly style this
                                                                style={{ color: "red", position: "absolute", top: "39px" }}
                                                                onClick={() => setEntryFieldsForRemoval(() => remove(field.name), field.name)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}

                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ color: "black" }}>
                                                        Add Entry Field
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Col>
                            </Row>

                            <br />

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
