import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import { Table, Tooltip, Skeleton, notification, Button, Dropdown, Menu } from "antd"
import { DownloadOutlined, PlusOutlined, UploadOutlined, EditOutlined, SearchOutlined, FilterOutlined, DownOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons"
import nodePath from "path"

import { fetchFacilities, selectFacilities, deleteFacility, selectTotal, selectPagination, selectLoading, filterFacilities, generateCsv } from "../facilitiesSlice"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
import useConfirmationModal from "../../alertsPanel/ShowConfirm"

import Role from "../../userRoles/Role"

export default function FacilityTable({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    let { clientId, divisionId } = useParams()
    // console.log(clientId)
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const facilities = useSelector(selectFacilities)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialFacilities()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialFacilities = () => {
        dispatch(fetchFacilities(pagination, nodePath.join(url, "")))
    }

    const handleFilterFacilities = (newPagination = null, newSort = null) => {
        return dispatch(
            filterFacilities(
                {
                    clientId: clientId,
                    filters: filters,
                    pagination: !!newPagination ? newPagination : pagination,
                    sort: !!newSort ? newSort : sort,
                },
                { divisionId: divisionId }
            )
        )
    }
    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteFacility, id);
    };
    const handleSortFacilitiesChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterFacilities(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchFacilities(newPagination, nodePath.join(url, "")))
        } else {
            handleFilterFacilities(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    const facilityActionPath = (action) => {
        let path = `/clients/${clientId}`

        if (divisionId) {
            path += `/divisions/${divisionId}/facilities`
        } else {
            path += `/facilities`
        }

        return `${path}/${action}`
    }

    const renderColumnHeaderTitle = (title, altTitle = null) => {
        return <ColumnHeaderTitle title={title} altTitle={altTitle} handleSortChange={handleSortFacilitiesChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialFacilities()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    const facilitiesCsv = async () => {
        notification.open({
            message: "Facilities export started",
        })

        dispatch(generateCsv(clientId, filters))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Facilities</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <Role action="create" model="facility">
                                    <Link to={facilityActionPath("new")}>
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role>

                                {!divisionId && (
                                    <Link to={`${url}/import`}>
                                        <PlusOutlined />
                                        Import
                                    </Link>
                                )}

                                <Button type="link" onClick={() => facilitiesCsv()}>
                                    <DownloadOutlined />
                                    Export
                                </Button>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={facilities} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("name")}
                                key="name"
                                dataIndex="name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialFacilities}
                                        filterAction={filterFacilities}
                                        filterColumn={"name"}
                                        filterType={"name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        divisionId={divisionId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("number")}
                                key="number"
                                dataIndex="number"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialFacilities}
                                        filterAction={filterFacilities}
                                        filterColumn={"number"}
                                        filterType={"number_eq"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        divisionId={divisionId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("number_eq")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("square_footage")}
                                key="square_footage"
                                dataIndex="square_footage"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialFacilities}
                                        filterAction={filterFacilities}
                                        filterColumn={"square_footage"}
                                        filterType={"square_footage_eq"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        divisionId={divisionId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("square_footage_eq")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("division_name", "division")}
                                key="division_id"
                                dataIndex="division_name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialFacilities}
                                        filterAction={filterFacilities}
                                        filterColumn={"division_name"}
                                        filterType={"division_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        divisionId={divisionId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("division_name_cont")}
                            />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Role action="update" model="facility">
                                                        <Menu.Item key="1" icon={<EditOutlined />}>
                                                            <Link to={facilityActionPath(`${record.id}/edit`)}>Edit Facility</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Menu.Item key="2" icon={<EyeOutlined />}>
                                                        <Link to={`/clients/${clientId}/facilities/${record.id}/dashboard`}>View</Link>
                                                    </Menu.Item>

                                                    <Role action="destroy" model="facility">
                                                        <Menu.Item key="3" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)}>
                                                            Delete Facility
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
