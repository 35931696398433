import React from "react"
import { useSelector } from "react-redux"
import { Form, Row, Col, Select } from "antd"
import { selectServices, selectVendors, selectDivisions, selectFacilities } from "../searchSlice"

export default function AdvancedSearchAssociations() {
    const services = useSelector(selectServices)
    const vendors = useSelector(selectVendors)
    const divisions = useSelector(selectDivisions)
    const facilities = useSelector(selectFacilities)

    return (
        <div>
            <h1 className="search-heading">Filter Your Search By</h1>
            <Row gutter={24}>
                {/* service, vendor, division, and facility dropdowns */}
                <Col span={6}>
                    <Form.Item label="Services" name="services">
                        <Select
                            placeholder="All Services"
                            showArrow={true}
                            mode="multiple"
                            allowClear={true}
                            showSearch
                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                            {services.map((s) => (
                                <Select.Option key={s.id} value={s.id} label={s.name}>
                                    {s.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Vendors" name="vendors">
                        <Select
                            placeholder="All Vendors"
                            showArrow={true}
                            mode="multiple"
                            allowClear={true}
                            showSearch
                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                            {vendors.map((v) => (
                                <Select.Option key={v.id} value={v.id} label={v.display_name}>
                                    {v.display_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Divisions" name="divisions">
                        <Select
                            placeholder="All Divisions"
                            showArrow={true}
                            mode="multiple"
                            allowClear={true}
                            showSearch
                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                            {divisions.map((d) => (
                                <Select.Option key={d.id} value={d.id} label={d.name}>
                                    {d.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Facilities" name="facilities">
                        <Select
                            placeholder="All Facilities"
                            showArrow={true}
                            mode="multiple"
                            allowClear={true}
                            showSearch
                            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}>
                            {facilities.map((f) => (
                                <Select.Option key={f.id} value={f.id} label={f.name}>
                                    {f.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}
