import React from "react"

import SiteSurveysForm from "./siteSurveys/components/SiteSurveysForm"
import SiteSurveysTable from "./siteSurveys/components/SiteSurveysTable"

function AncillaryServicesPage(props) {
    const renderView = () => {
        switch (props.view) {
            case "siteSurveys":
                return renderSiteSurveys()
            case "siteSurveysForm":
                return renderSiteSurveysForm()
            default:
                return renderDefault()
        }
    }

    const renderDefault = () => {
        return <div></div>
    }

    const renderSiteSurveys = () => {
        return <SiteSurveysTable />
    }

    const renderSiteSurveysForm = () => {
        return <SiteSurveysForm recordSelected={props.recordSelected} />
    }

    return <>{renderView()}</>
}

export default AncillaryServicesPage
