import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Tooltip, Skeleton, Space } from "antd"
import { PlusOutlined, EditOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons"
import nodePath from "path"

import { fetchSubaccounts, selectSubaccounts, selectTotal, selectPagination, selectLoading, filterSubaccounts } from "../../groupAccounts/groupAccountsSlice"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import moment from "moment"

export default function SubaccountTable({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    const { clientId, groupAccountId } = useParams()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const subaccounts = useSelector(selectSubaccounts)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffect start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialSubaccounts()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    /////*** useEffect end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialSubaccounts = () => {
        dispatch(fetchSubaccounts(pagination, nodePath.join(url, "")))
    }

    const handleFilterSubaccounts = (newPagination = null, newSort = null) => {
        return dispatch(
            filterSubaccounts({
                clientId: clientId,
                groupAccountId: groupAccountId,
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortSubaccountsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterSubaccounts(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchSubaccounts(newPagination, nodePath.join(url, "")))
        } else {
            handleFilterSubaccounts(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    /////*** render methods start ***/////
    const renderColumnHeaderTitle = (title) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortSubaccountsChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        setFilters({})
                        handleFetchInitialSubaccounts()
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }
    /////*** render methods end ***/////

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>{groupAccountId ? "Subaccounts" : "Billable Accounts"}</h2>
                            <div className="badge">{total}</div>
                        </div>

                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <Link to={`/clients/${scopedByClient}/group_accounts/${groupAccountId}/edit`}>
                                    <PlusOutlined />
                                    Manage
                                </Link>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={subaccounts} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("facility")}
                                key="facility_id"
                                dataIndex="facility"
                                render={(facility) => facility?.name}
                                filterDropdown={() => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialSubaccounts}
                                        filterAction={filterSubaccounts}
                                        filterColumn={"facility_name"}
                                        filterType={"facility_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("facility_name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("account_number")}
                                key="account_number"
                                dataIndex="account_number"
                                filterDropdown={() => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialSubaccounts}
                                        filterAction={filterSubaccounts}
                                        filterColumn={"account_number"}
                                        filterType={"account_number_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("account_number_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("name")}
                                key="name"
                                dataIndex="name"
                                filterDropdown={() => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialSubaccounts}
                                        filterAction={filterSubaccounts}
                                        filterColumn={"name"}
                                        filterType={"name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />
                            <Table.Column title={"Address"} render={(row) => `${row.street_address_1} ${row.street_address_2}, ${row.city}, ${row.state} ${row.zip}, ${row.country}`} />
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <Space>
                                        <div className="row-actions" style={{ display: "inline-block" }}>
                                            <Link to={scopedByClient ? `/clients/${scopedByClient}/billable_accounts/${record.id}/edit` : `/billable_accounts/${record.id}/edit`}>
                                                <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip>
                                            </Link>
                                        </div>
                                        {scopedByClient && (
                                            <div className="row-actions" style={{ display: "inline-block" }}>
                                                <Link to={`/clients/${scopedByClient}/billable_accounts/${record.id}/dashboard`}>
                                                    View
                                                    {/* <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip> */}
                                                </Link>
                                            </div>
                                        )}
                                    </Space>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
