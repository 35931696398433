import React, { useEffect } from "react"
import { Redirect, BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Layout } from "antd"
import Cookies from "universal-cookie"

import ConsoleLayout from "./ConsoleLayout"
import Nav from "./features/nav/Nav"
import Home from "./features/home/Home"
import Login from "./features/login/Login"
import ForgotPassword from "./features/passwordReset/ForgotPassword"
import ResetPassword from "./features/passwordReset/ResetPassword"
import { getToken } from "./lib/storage"
import ClientRoutes from "./routes/ClientRoutes"
import UserRoutes from "./routes/UserRoutes"
import ChannelPartnerRoutes from "./routes/ChannelPartnerRoutes"
import DivisionRoutes from "./routes/DivisionRoutes"
import FacilityRoutes from "./routes/FacilityRoutes"
import BillableAccountRoutes from "./routes/BillableAccountRoutes"
import SupplyAccountRoutes from "./routes/SupplyAccountRoutes"
import GroupAccountRoutes from "./routes/GroupAccountRoutes"
import GroupSupplyAccountRoutes from "./routes/GroupSupplyAccountRoutes"
import BillRoutes from "./routes/BillRoutes"
import UtilityServiceRoutes from "./routes/UtilityServiceRoutes"
import VendorRoutes from "./routes/VendorRoutes"
import SettingsRoutes from "./routes/SettingsRoutes"
import AdvancedSearchRoutes from "./routes/AdvancedSearchRoutes"

export default function App() {
    const path = window.location.pathname

    useEffect(() => {
        const cookies = new Cookies()
        const theme = cookies.get("theme")
        const bodyElement = document.querySelector("body")
        if (!!theme) {
            bodyElement.className = ""
            bodyElement.classList.add(theme)
        } else {
            cookies.set("theme", "dark", { path: "/" })
        }
    }, [])

    return (
        <Router>
            {/* TODO: Can do something like the below to prevent bookmarking/direct navigation
            without setting proper store variables needed for navigation */}
            {/* ({!getToken() || {!seenHomeScreen()})&& <Redirect to="/login" />} */}
            {!getToken() && path !== "/forgot_password" && path !== "/reset_password" && <Redirect to="/login" />}

            <Switch>
                <Route path="/login">
                    <Login />
                </Route>

                <Route path="/forgot_password">
                    <ForgotPassword />
                </Route>

                <Route path="/reset_password">
                    <ResetPassword />
                </Route>

                <Route path="/clients">
                    <ClientRoutes />
                </Route>
                
                <Route path={`/advanced_search`}>
                    <AdvancedSearchRoutes />
                </Route>

                <Route path="/channel_partners">
                    <ChannelPartnerRoutes />
                </Route>

                <Route path="/users">
                    <UserRoutes />
                </Route>

                <Route path="/divisions">
                    <DivisionRoutes />
                </Route>

                <Route path="/facilities">
                    <FacilityRoutes />
                </Route>

                <Route path="/billable_accounts">
                    <BillableAccountRoutes />
                </Route>

                <Route path="/supply_accounts">
                    <SupplyAccountRoutes />
                </Route>

                <Route path="/group_accounts">
                    <GroupAccountRoutes />
                </Route>

                <Route path="/group_supply_accounts">
                    <GroupSupplyAccountRoutes />
                </Route>

                <Route path="/bills">
                    <BillRoutes />
                </Route>

                <Route path="/utility_services">
                    <UtilityServiceRoutes />
                </Route>

                <Route path="/vendors">
                    <VendorRoutes />
                </Route>

                <Route path="/settings">
                    <SettingsRoutes />
                </Route>

                <Route exact path="/">
                    <ConsoleLayout>
                        <Home />
                    </ConsoleLayout>
                </Route>

                <Route exact path="/landing">
                    {/* <ConsoleLayout landingPage={true} /> */}
                    <Layout id="console">
                        <Nav landingPage={true} />
                    </Layout>
                </Route>
            </Switch>
        </Router>
    )
}
