import React from "react"
import ReportDivisions from "./fields/ReportDivisions"
import ReportFacilities from "./fields/ReportFacilities"
import ReportAccountingBatches from "./fields/ReportAccountingBatches"
import ReportDateRange from "./fields/ReportDateRange"
import ReportVendors from "./fields/ReportVendors"
import ReportBillableAccounts from "./fields/ReportBillableAccounts"
import ReportSupplyAccounts from "./fields/ReportSupplyAccounts"
import ReportUtilityServices from "./fields/ReportUtilityServices"

export default function Accrual() {
    return (
        <div>
            <ReportDateRange required={false} />
            <ReportUtilityServices />
            <ReportAccountingBatches />
            <ReportDivisions />
            <ReportFacilities />
            <ReportVendors />
            <ReportBillableAccounts />
            <ReportSupplyAccounts />
        </div>
    )
}
