import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import path from "path"
import { Link, useRouteMatch, useParams, useHistory } from "react-router-dom"
import { LeftOutlined } from "@ant-design/icons"
import { Form, Input, Row, Col, Button, Select, notification } from "antd"
import ReportFields from "./ReportFields"
import ReportGlobals from "./ReportGlobals"
import { COST_AND_USAGE, INVENTORY, TRIAL_BALANCE, GLOBAL_DATA, ACCRUAL } from "../../lib/reports"

import { selectReport, fetchReport, selectLoading, generateReport, fetchGeneratedReport, selectGeneratedReport, updateGeneratedReport, setGeneratedReport } from "./reportsSlice"

import moment from "moment"

import { requestNotification } from "../../lib/notifications"

export default function ReportForm({ actionName }) {
    const { url } = useRouteMatch()

    const history = useHistory()

    const { reportId, clientId, generatedReportId } = useParams()

    const dispatch = useDispatch()

    const [init, setInit] = useState(false)

    const loading = useSelector(selectLoading)
    const report = useSelector(selectReport)
    const generatedReport = useSelector(selectGeneratedReport)

    const [form] = Form.useForm()

    // set generated report back to null whenever we leave the form
    useEffect(() => {
        return () => {
            dispatch(setGeneratedReport(null))
        }
    }, [])

    useEffect(() => {
        if (init) {
            return
        }

        setInit(true)

        dispatch(fetchReport(clientId, reportId))

        if (generatedReportId) {
            dispatch(fetchGeneratedReport(clientId, reportId, generatedReportId))
        }
    }, [dispatch, init, reportId, generatedReportId])

    useEffect(() => {
        if (!report) {
            return
        }

        form.resetFields()
    }, [report])

    useEffect(() => {
        if (!generatedReport) {
            return
        }

        form.resetFields()
    }, [generatedReport])

    const showErrorNotification = (message, description) => {
        notification.error({
            message: message,
            description: description,
        })
    }

    // Specific forms can have unique validation requirements.
    const specificFormErrors = () => {
        const fieldValues = form.getFieldsValue()
        const dateRangeOrAccountingBatchForms = [TRIAL_BALANCE, GLOBAL_DATA]

        // Trial Balance reports need either a date range or an client batch selected
        if (dateRangeOrAccountingBatchForms.includes(report?.name) && !fieldValues?.form_data?.date_range && !fieldValues?.form_data?.accounting_batch_ids) {
            showErrorNotification("Missing Required Fields", "A date range or Client Batch must be selected for this report")
            return true
        } else {
            return false
        }
    }

    const submit = async (values) => {
        // Check for individual form validation errors
        if (specificFormErrors()) {
            return
        }

        const response = await dispatch(actionName === "edit" ? updateGeneratedReport(clientId, reportId, generatedReportId, values) : generateReport(clientId, reportId, values))

        if (response.data.success) {
            history.push(backPath())
        }

        requestNotification(response.data)
    }

    const backPath = () => {
        if (actionName === "edit") {
            return path.join(url, "../../../..", "generated-reports")
        } else {
            return path.join(url, "../..", "dashboard")
        }
    }

    const formatInitialName = () => {
        if (!report || actionName === "edit") {
            return null
        }

        return `${report.name} - ${moment().format()}`
    }

    const initialValues = () => {
        const gr = generatedReport || { form_data: { date_range: [moment(), moment()] } }

        if (actionName === "new") {
            return {
                ...report,
                name: formatInitialName(),
            }
        } else {
            return {
                ...gr,
                form_data: {
                    ...gr.form_data,
                    date_range: gr.form_data?.date_range ? gr.form_data.date_range.map((d) => moment(d)) : null,
                },
            }
        }
    }

    return (
        <div id="user-form-container">
            <Link to={backPath()}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={initialValues()} onFinish={submit}>
                <div className="card">
                    <div className="card-body" style={{ display: "block" }}>
                        <ReportGlobals form={form} actionName={actionName} />

                        <hr />

                        <ReportFields form={form} actionName={actionName} />

                        <hr />

                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    )
}
