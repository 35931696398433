import React from "react"
import { Link, useParams } from "react-router-dom"

function AncillaryServicesNav(props) {
    const { clientId } = useParams()

    return (
        <>
            <h2>Ancillary Services</h2>
            <ul className="header-tab-nav" role="navigation">
                <li>
                    <Link to={`/clients/${clientId}/ancillary_services/site_surveys`}>
                        <button type="link" className={`${props.activeTab === "siteSurveys" ? "selected" : ""}`}>
                            Site Surveys
                        </button>
                    </Link>
                </li>
            </ul>
        </>
    )
}

export default AncillaryServicesNav
