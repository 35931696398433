import moment from "moment"
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default null

export function calculateDateRangesByType(type, addLastYear) {
    const now = moment()
    let target, lastYearDateRangeFrom, lastYearDateRangeTo

    switch (type) {
        case "last_12_months":
            target = moment().subtract("12", "months")
            break
        case "last_6_months":
            target = moment().subtract("6", "months")
            break
        case "last_3_months":
            target = moment().subtract("3", "months")
            break
        case "year_to_date":
            target = moment().startOf("year")
            break
        case "fiscal_year":
            // @TODO: what is a fiscal year?
            target = moment().subtract("12", "months")
            break
        default:
            console.error(`Invalid date range type: ${type}`)
    }

    if (addLastYear) {
        lastYearDateRangeFrom = moment(target).subtract("12", "months").format("L")
        lastYearDateRangeTo = moment().subtract("12", "months").format("L")
    }

    const ret = {
        dateRangeFrom: moment(target).add("1", "day").format("L"),
        dateRangeTo: now.format("L"),
        lastYearDateRangeFrom: lastYearDateRangeFrom || null,
        lastYearDateRangeTo: lastYearDateRangeTo || null,
    }

    return ret
}

export function parseDataSuffix(amount) {
    if (amount >= 1_000_000) {
        return "m"
    } else if (amount >= 1_000) {
        return "K"
    } else {
        return ""
    }
}

export function parseCardAmount(amount) {
    if (amount >= 1_000_000) {
        return amount / 1_000_000
    } else if (amount >= 1_000) {
        return amount / 1_000
    } else {
        return amount
    }
}

export function parseCardCompareText(dateRangeType) {
    switch (dateRangeType) {
        case "last_12_months":
            return "Previous 12mo:"
        case "last_6_months":
            return "Previous 6mo:"
        case "last_3_months":
            return "Previous 3mo:"
        case "year_to_date":
            return "Previous YTD:"
        case "fiscal_year":
            return "Previous Fiscal:"
    }
}

export function generateAmountCardProps(data, dateRangeType, compare, suffixUnit) {
    const dataNumber = parseCardAmount(data?.current)
    const dataSuffix = parseDataSuffix(data?.current)
    const footer = compare ? parseCardCompareText(dateRangeType) : ""
    const footerSuffix = parseCardAmount(data?.previous)
    const footerDataSuffix = parseDataSuffix(data?.previous)
    const bottomIcon = data?.previous > data?.current ? faArrowDown : faArrowUp

    return {
        dataNumber: dataNumber ? Number.parseFloat(dataNumber).toFixed(2) : null,
        dataSuffix: `${dataSuffix} ${suffixUnit || ""}`,
        footer: footer,
        footerSuffix: footerSuffix ? Number.parseFloat(footerSuffix).toFixed(2) : null,
        footerDataSuffix: `${footerDataSuffix} ${suffixUnit || ""}`,
        bottomIcon: <FontAwesomeIcon icon={bottomIcon} color="#fbb040" />,
    }
}
