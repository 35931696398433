import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Button, Form, Steps } from "antd"
import moment from "moment"

import SiteSurveysGeneralForm from "./siteSurveysFormSteps/SiteSurveysGeneralForm"
import SiteSurveysContactsForm from "./siteSurveysFormSteps/contacts/SiteSurveysContactsForm"
import SiteSurveysCompressorsForm from "./siteSurveysFormSteps/compressors/SiteSurveysCompressorsForm"
import SiteSurveysMotorsForm from "./siteSurveysFormSteps/motors/SiteSurveysMotorsForm"
import SiteSurveysGearBoxesForm from "./siteSurveysFormSteps/gearBoxes/SiteSurveysGearBoxesForm"
import SiteSurveysLightingForm from "./siteSurveysFormSteps/lighting/SiteSurveysLightingForm"
import SiteSurveysHvacForm from "./siteSurveysFormSteps/hvac/SiteSurveysHvacForm"
import SiteSurveysDocumentsForm from "./siteSurveysFormSteps/SiteSurveysDocumentsForm"

import { fetchClientFacilitySelectOptions } from "../../../facilities/facilitiesSlice"
import { fetchSiteSurvey, selectSiteSurvey, setSiteSurvey, submitSiteSurvey } from "../siteSurveysSlice"

function SiteSurveysForm(props) {
    /////*** react router start ***/////
    const { clientId, siteSurveyId } = useParams()
    const { url } = useRouteMatch()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()

    const siteSurvey = useSelector(selectSiteSurvey)
    /////*** redux end ***/////

    /////*** useState start ***/////
    let history = useHistory()
    const [currentStep, setCurrentStep] = useState(0)

    const [contactsToRemove, setContactsToRemove] = useState([])
    const [compressorsToRemove, setCompressorsToRemove] = useState([])
    const [motorsToRemove, setMotorsToRemove] = useState([])
    const [gearBoxesToRemove, setGearBoxesToRemove] = useState([])
    const [lightingsToRemove, setLightingsToRemove] = useState([])
    const [hvacsToRemove, setHvacsToRemove] = useState([])
    /////*** useState end ***/////

    /////*** useEffect start ***/////
    useEffect(() => {
        dispatch(fetchClientFacilitySelectOptions(clientId))
    }, [clientId, url]) // eslint-disable-line

    useEffect(() => {
        form.resetFields()
        if (!!siteSurveyId) {
            dispatch(fetchSiteSurvey(siteSurveyId))
        } else {
            dispatch(setSiteSurvey({}))
        }
    }, [siteSurveyId, url]) // eslint-disable-line

    useEffect(() => {
        form.resetFields()
    }, [siteSurvey, url]) // eslint-disable-line

    /////*** useEffect end ***/////

    /////*** event handler methods start ***/////
    const handleNextStep = () => {
        setCurrentStep(currentStep + 1)
    }

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1)
    }

    const handleSubmit = async (values) => {
        let allValues = { ...form.getFieldsValue(true) }
        allValues.contacts_attributes = allValues.contacts_attributes.concat(contactsToRemove)
        allValues.compressors_attributes = allValues.compressors_attributes.concat(compressorsToRemove)
        allValues.motors_attributes = allValues.motors_attributes.concat(motorsToRemove)
        allValues.gear_boxes_attributes = allValues.gear_boxes_attributes.concat(gearBoxesToRemove)
        allValues.lightings_attributes = allValues.lightings_attributes.concat(lightingsToRemove)
        allValues.hvacs_attributes = allValues.hvacs_attributes.concat(hvacsToRemove)

        await dispatch(submitSiteSurvey(allValues, siteSurveyId, clientId))
        history.push(`/clients/${clientId}/ancillary_services/site_surveys`)
    }
    /////*** event handler methods end ***/////

    /////*** render methods start ***/////
    const renderHvacsInitialAttributes = () => {
        if (!!siteSurvey.hvacs) {
            return siteSurvey.hvacs.map((hvac) => {
                return {
                    ...hvac,
                    cooling_installation_date: !!hvac.cooling_installation_date ? moment(hvac.cooling_installation_date) : null,
                    heating_installation_date: !!hvac.heating_installation_date ? moment(hvac.heating_installation_date) : null,
                    water_installation_date: !!hvac.water_installation_date ? moment(hvac.water_installation_date) : null,
                }
            })
        } else return []
    }

    const renderNextOrDoneButton = () => {
        if (currentStep === steps.length - 1) {
            return (
                <Button type="primary" htmlType="submit">
                    Done
                </Button>
            )
        }
        return (
            <Button type="primary" onClick={() => handleNextStep()}>
                Next
            </Button>
        )
    }

    const renderStepKeys = () => {
        return steps.map((item, index) => {
            return (
                <Steps.Step
                    key={item.title}
                    onClick={() => {
                        setCurrentStep(index)
                    }}
                    title={item.title}
                />
            )
        })
    }
    /////*** render methods end ***/////

    /////*** antd variables start ***/////
    const [form] = Form.useForm()

    const layout = {
        form: form,
        id: "site-surveys-form",
        initialValues: {
            ...siteSurvey,
            contacts_attributes: !!siteSurvey.contacts ? [...siteSurvey.contacts] : [],
            compressors_attributes: !!siteSurvey.compressors ? [...siteSurvey.compressors] : [],
            motors_attributes: !!siteSurvey.motors ? [...siteSurvey.motors] : [],
            gear_boxes_attributes: !!siteSurvey.gear_boxes ? [...siteSurvey.gear_boxes] : [],
            lightings_attributes: !!siteSurvey.lightings ? [...siteSurvey.lightings] : [],
            hvacs_attributes: renderHvacsInitialAttributes(),
            // documents_attributes: []
        },
        labelCol: { span: 8 },
        size: "small",
        wrapperCol: { span: 16 },
    }

    const steps = [
        {
            title: "General",
            content: <SiteSurveysGeneralForm form={form} />,
        },
        {
            title: "Contacts",
            content: <SiteSurveysContactsForm form={form} toRemove={contactsToRemove} setToRemove={setContactsToRemove} />,
        },
        {
            title: "Compressors",
            content: <SiteSurveysCompressorsForm form={form} toRemove={compressorsToRemove} setToRemove={setCompressorsToRemove} />,
        },
        {
            title: "Motors",
            content: <SiteSurveysMotorsForm form={form} toRemove={motorsToRemove} setToRemove={setMotorsToRemove} />,
        },
        {
            title: "Gear Boxes",
            content: <SiteSurveysGearBoxesForm form={form} toRemove={gearBoxesToRemove} setToRemove={setGearBoxesToRemove} />,
        },
        {
            title: "Lighting",
            content: <SiteSurveysLightingForm form={form} toRemove={lightingsToRemove} setToRemove={setLightingsToRemove} />,
        },
        {
            title: "HVAC",
            content: <SiteSurveysHvacForm form={form} toRemove={hvacsToRemove} setToRemove={setHvacsToRemove} />,
        },
        {
            title: "Documents",
            content: <SiteSurveysDocumentsForm form={form} />,
        },
    ]
    /////*** antd variables end ***/////

    return (
        <Form {...layout} onFinish={handleSubmit}>
            <Steps current={currentStep} size="small">
                {renderStepKeys()}
            </Steps>
            <div className="steps-content">{steps[currentStep].content}</div>
            <div className="steps-action">
                <Button disabled={!(currentStep > 0)} onClick={() => handlePreviousStep()}>
                    Previous
                </Button>
                {renderNextOrDoneButton()}
            </div>
        </Form>
    )
}

export default SiteSurveysForm
