import { createSlice } from "@reduxjs/toolkit"
import { remove, filter } from "lodash"
import { batch } from "react-redux"
import { notification } from "antd"

// http client
import client from "../../../lib/client"
import {
    paymentBatchesUrl,
    paymentBatchUrl,
    paymentBatchesSidebar,
    utilityPaymentPdfUrl,
    dynamicUrl,
    batchGroupSelectOptionsUrl,
    vendorPaymentSyncUrl,
    paymentBatchBillsUrl,
    paymentBatchNewBillsUrl,
    paymentBatchNewBillsUrlutilityPaymentsUrl,
    utilityPaymentsUrl,
    utilityPaymentUrl,
    utilityPaymentBillsUrl,
    divisionsScopedByClientUrl,
    paymentBatchBillsCsvUrl,
    paymentBatchVoucherCsvUrl,
    PaymentBatchesCsvUrl,
    paymentBatchSearchUrl, accountingBatchGenerateInvoicePdfUrl,
} from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"
import { setDivisions } from "../../divisions/divisionsSlice"
import { createRansackParams } from "../../../functions/objectFunctions/createRansackParams"

export const paymentBatchesSlice = createSlice({
    name: "paymentBatches",
    initialState: {
        paymentBatch: {},
        paymentBatches: [],
        paymentBatchSelectOptions: [],
        paymentBatchSearch: [],
        batchGroupSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        bills: [],
        utilityPayments: [],
        utilityPayment: {},
        utilityPaymentsTotal: 0,
        fetchingBills: false,
        loading: false,
    },
    reducers: {
        setPaymentBatch: (state, action) => {
            state.paymentBatch = action.payload
        },
        setPaymentBatches: (state, action) => {
            state.paymentBatches = action.payload.payment_batches
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setPaymentBatchSelectOptions: (state, action) => {
            state.paymentBatchSelectOptions = action.payload
        },
        setPaymentBatchSearch: (state, action) => {
            state.paymentBatchSearch = action.payload
        },
        setBatchGroupSelectOptions: (state, action) => {
            state.batchGroupSelectOptions = action.payload
        },
        setBills: (state, action) => {
            state.bills = action.payload
        },
        setVendorPayments: (state, action) => {
            state.utilityPayments = action.payload.vendor_payments
            state.utilityPaymentsTotal = action.payload.total
        },
        setVendorPayment: (state, action) => {
            state.utilityPayment = action.payload
        },
        deletePaymentBatch: (state, action) => {
            state.paymentBatches = state.paymentBatches.filter((paymentBatch) => paymentBatch.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const {
    setPaymentBatch,
    setPaymentBatches,
    setPagination,
    setLoading,
    setPaymentBatchSelectOptions,
    setPaymentBatchSearch,
    setBatchGroupSelectOptions,
    setBills,
    setVendorPayments,
    setVendorPayment,
} = paymentBatchesSlice.actions

export function fetchPaymentBatch(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(paymentBatchUrl(id)).then((response) => {
            dispatch(setPaymentBatch(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchPaymentBatches(clientId, pagination) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        if (pagination?.values) {
            const response = await client.get(`${paymentBatchesUrl(clientId)}?values=[${pagination.values}]`)
            return response.data
        }

        client.get(`${paymentBatchesUrl(clientId)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setPaymentBatches(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchPaymentBatchesSidebar(clientId) {
    return async (dispatch, getState) => {
        const response = await client.get(`${paymentBatchesSidebar(clientId)}`)
        return response.data.payment_batches
    }
}


export function generateVendorPaymentPdf(vendorPaymentId) {
    return async (dispatch, getState) => {
        client.get(`${utilityPaymentPdfUrl(vendorPaymentId)}?connection_id=${getState().app.uid}`)
    }
}

export function generatePaymentBatchForVendorsPdf(paymentBatchId) {
    return async (dispatch, getState) => {
        client.get(`${paymentBatchUrl(paymentBatchId)}/voucher_pdf?connection_id=${getState().app.uid}`)
    }
}

export function submitPaymentBatch(values, id, clientId, utilityPaymentId = null, updateBills = false) {
    return async (dispatch, getState) => {
        if (utilityPaymentId) {
            if (id) {
                // patch - update
                const response = await client.patch(paymentBatchUrl(id, clientId), { payment_batch: values, vendor_payment_id: utilityPaymentId })

                if (updateBills) {
                    dispatch(setBills(response.data.bills))
                    dispatch(setPaymentBatch(response.data.payment_batch))
                }

                return response.data
            } else {
                // post - create
                const response = await client.post(paymentBatchesUrl(clientId), { payment_batch: values })
                return response.data
            }
        } else {
            if (id) {
                // patch - update
                const response = await client.patch(paymentBatchUrl(id, clientId), { payment_batch: values })

                if (updateBills) {
                    dispatch(setBills(response.data.bills))
                    dispatch(setPaymentBatch(response.data.payment_batch))
                }

                return response.data
            } else {
                // post - create
                const response = await client.post(paymentBatchesUrl(clientId), { payment_batch: values })
                return response.data
            }
        }
    }
}

export function fetchBatchGroupSelectOptions(clientId) {
    return async (dispatch, getState) => {
        client.get(`${batchGroupSelectOptionsUrl(clientId)}`).then((response) => {
            dispatch(setBatchGroupSelectOptions(response.data.batch_groups))
        })
    }
}

export function fetchPaymentBatchBills(clientId, paymentBatchId, utilityPaymentId = null) {
    return async (dispatch, getState) => {
        if (utilityPaymentId) {
            client.get(`${utilityPaymentBillsUrl(utilityPaymentId)}`).then((response) => {
                dispatch(setBills(response.data.bills))
            })
        } else {
            client.get(`${paymentBatchBillsUrl(clientId, paymentBatchId)}`).then((response) => {
                dispatch(setBills(response.data.bills))
            })
        }
    }
}

export function fetchPaymentBatchNewBills(clientId, paymentBatchId, utilityPaymentId = null) {
    return async (dispatch, getState) => {
        const response = await client.get(`${paymentBatchNewBillsUrl(clientId, paymentBatchId, utilityPaymentId)}`)
        dispatch(setBills(response.data.bills))
        return { success: true, new_bills: response.data.new_bills, total: response.data.new_bills_total }
    }
}

export function syncVendorPayments(clientId, paymentBatchId) {
    return async (dispatch, getState) => {
        const response = await client.post(`${vendorPaymentSyncUrl(clientId, paymentBatchId)}`)
        dispatch(setBills(response.data.bills))
        return { success: true }
    }
}

export function deletePaymentBatch(id) {
    return async (dispatch, getState) => {
        client
            .delete(`${paymentBatchUrl(id)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                    })

                    dispatch(paymentBatchesSlice.actions.deletePaymentBatch(id))
                } else {
                    notification.error({
                        message: data.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
    }
}

// export function deletePaymentBatch(paymentBatchId) {
//     return async (dispatch, getState) => {
//         const response = await client.delete(`${paymentBatchUrl(paymentBatchId)}`)
//         if (response.data.success) {
//             let batches = [...getState().paymentBatches.paymentBatches]

//             let newList = filter(batches, function (n) {
//                 return n.id !== parseInt(paymentBatchId)
//             })

//             dispatch(setPaymentBatches({ payment_batches: [...newList], total: getState().paymentBatches.total - 1 }))
//             return response.data
//         } else {
//             return response.data
//         }
//     }
// }

/////////// Vendor Payments ////////////////////////////
export function fetchVendorPayments(paymentBatchId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(`${utilityPaymentsUrl(paymentBatchId)}`).then((response) => {
            dispatch(setVendorPayments(response.data))
            dispatch(setLoading(false))
        })
    }
}


export function filterVendorPayments(query) {
    let filterUrl = createFilterUrl(query, utilityPaymentsUrl(query.paymentBatchId))

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setVendorPayments(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function fetchVendorPayment(id) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(utilityPaymentUrl(id)).then((response) => {
            dispatch(setVendorPayment(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function submitVendorPayment(values, id, clientId) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(utilityPaymentUrl(id, clientId), { vendor_payment: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(utilityPaymentUrl(clientId), { vendor_payment: values })
            return response.data
        }
    }
}

export function deleteVendorPayment(id) {
    return async (dispatch, getState) => {
        const response = await client.delete(`${utilityPaymentUrl(id)}`)
        if (response.data.success) {
            let payments = [...getState().paymentBatches.utilityPayments]

            let newList = filter(payments, function (n) {
                return n.id !== parseInt(id)
            })

            dispatch(setVendorPayments({ vendor_payments: [...newList], total: getState().paymentBatches.utilityPaymentsTotal - 1 }))
            return response.data
        } else {
            return response.data
        }
    }
}

export function generateCsv(paymentBatchId) {
    return async (dispatch, getState) => {
        client.get(`${paymentBatchBillsCsvUrl(paymentBatchId)}?connection_id=${getState().app.uid}`)
    }
}

export function generateVoucherCsv(paymentBatchId) {
    return async (dispatch, getState) => {
        client.get(`${paymentBatchVoucherCsvUrl(paymentBatchId)}?connection_id=${getState().app.uid}`)
    }
}

/////////// Vendor Payments End ////////////////////////////

// export function fetchPaymentBatchSelectOptions(clientId) {
//     return async (dispatch, getState) => {
//         client.get(`${paymentBatchSelectOptionsUrl(clientId)}`).then((response) => {
//             dispatch(setPaymentBatchSelectOptions(response.data.payment_batches))
//         })
//     }
// }

// export function fetchPaymentBatchSelectOptionById(id, currentPath) {
//     return async (dispatch, getState) => {
//         client.get(`${paymentBatchSelectOptionByIdUrl}?paymentBatchId=${id}`).then((response) => {
//             dispatch(setPaymentBatchSelectOptions(response.data.paymentBatch))
//         })
//     }
// }

export function searchPaymentBatches(query, clientId) {
    return async (dispatch) => {
        dispatch(setLoading(true))

        client
            .get(`${paymentBatchSearchUrl(clientId)}?q=${query}`)
            .then(({ data }) => {
                dispatch(setPaymentBatchSearch(data))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function filterPaymentBatches(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, paymentBatchesUrl(query.clientId))

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setPaymentBatches(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generatePaymentBatchesCsv(clientId, filters) {
    return async (dispatch, getState) => {
        client.get(`${PaymentBatchesCsvUrl(clientId)}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectPaymentBatch = (state) => state.paymentBatches.paymentBatch
export const selectPaymentBatches = (state) => state.paymentBatches.paymentBatches
export const selectTotal = (state) => state.paymentBatches.total
export const selectPagination = (state) => state.paymentBatches.pagination
export const selectLoading = (state) => state.paymentBatches.loading
export const selectPaymentBatchSelectOptions = (state) => state.paymentBatches.paymentBatchSelectOptions
export const selectPaymentBatchSearch = (state) => state.paymentBatches.paymentBatchSearch
export const selectBatchGroupSelectOptions = (state) => state.paymentBatches.batchGroupSelectOptions
export const selectBills = (state) => state.paymentBatches.bills
export const selectVendorPayments = (state) => state.paymentBatches.utilityPayments
export const selectVendorPayment = (state) => state.paymentBatches.utilityPayment
export const selectVendorPaymentsTotal = (state) => state.paymentBatches.utilityPaymentsTotal

export default paymentBatchesSlice.reducer
