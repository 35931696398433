import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Skeleton, Popconfirm, notification, Dropdown, Menu, Button } from "antd"
import { DownloadOutlined, PlusOutlined, UploadOutlined, FilterOutlined, SearchOutlined, FileTextOutlined, EditOutlined, DeleteOutlined, DownOutlined, FolderOutlined } from "@ant-design/icons"
import moment from "moment"

import {
    fetchPaymentBatches,
    selectPaymentBatches,
    deletePaymentBatch,
    selectTotal,
    selectPagination,
    selectLoading,
    filterPaymentBatches,
    generateVoucherCsv,
    generatePaymentBatchesCsv,
} from "../paymentBatchesSlice"
import { allObjectValuesEmpty } from "../../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../../functions/objectFunctions/tableFiltersApplied"
import ColumnSearchFilter from "../../../../components/utilityComponents/table/ColumnSearchFilter"
import ColumnDateFilter from "../../../../components/utilityComponents/table/ColumnDateFilter"
import useConfirmationModal from "../../../alertsPanel/ShowConfirm"

import Role from "../../../userRoles/Role"
import {formatCurrency} from "../../../../lib/utilities";

export default function PaymentBatchTable({ scopedByClient }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    const { clientId } = useParams()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const paymentBatches = useSelector(selectPaymentBatches)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffect start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialPaymentBatches()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    /////*** useEffect end ***/////

    /////*** event handler methods start ***/////
    const deleteRecord = async (id) => {
        const response = await dispatch(deletePaymentBatch(id))
        if (response.success) {
            notification.open({
                message: "Success",
                description: "Deleted Vendor Batch",
            })
        } else {
            notification.open({
                message: "Error",
                description: "There was an error deleting the Vendor Batch",
            })
        }
    }

    const handleFetchInitialPaymentBatches = () => {
        dispatch(fetchPaymentBatches(clientId, pagination))
    }

    const handleFilterPaymentBatches = (newPagination = null, newSort = null) => {
        return dispatch(
            filterPaymentBatches({
                clientId: clientId,
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortPaymentBatchesChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterPaymentBatches(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchPaymentBatches(clientId, newPagination))
        } else {
            handleFilterPaymentBatches(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    /////*** render methods start ***/////
    const renderColumnHeaderTitle = (title, columnClass) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortPaymentBatchesChange} sort={sort} columnClass={columnClass} />
    }


    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deletePaymentBatch, id);
    };

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialPaymentBatches()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }
    /////*** render methods end ***/////

    const voucherCsv = async (paymentBatchId) => {
        notification.open({
            message: "Vendor Batch Voucher CSV started",
        })

        dispatch(generateVoucherCsv(paymentBatchId))
    }

    const paymentbatchesCsv = async () => {
        notification.open({
            message: "Vendor batches export started",
        })

        dispatch(generatePaymentBatchesCsv(clientId, filters))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Vendor Batches</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <Role action="create" model="payment_batch">
                                    <Link to={`${url}/new`}>
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role>

                                <a href="javascript:;" onClick={() => console.log("Import")}>
                                    <UploadOutlined />
                                    Import
                                </a>

                                <a href="javascript:;" onClick={() => paymentbatchesCsv()}>
                                    <DownloadOutlined />
                                    Export
                                </a>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={paymentBatches} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("closed")}
                                key="closed"
                                dataIndex="closed"
                                render={(text, record) => <>{record.closed ? "Closed" : ""}</>}
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialPaymentBatches}
                                        filterAction={filterPaymentBatches}
                                        filterColumn={"batch_date"}
                                        filterType={"batch_date"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("batch_date_cont")}
                            />
                            <Table.Column title="Payer" key="payer_name" dataIndex="payer_name" />
                            <Table.Column title="Vendors" key="vendor_names" dataIndex="vendor_names" />
                            <Table.Column
                                title={renderColumnHeaderTitle("batch_date")}
                                key="batch_date"
                                dataIndex="batch_date"
                                filterDropdown={({ visible }) => (
                                    <ColumnDateFilter
                                        handleFetchInitial={handleFetchInitialPaymentBatches}
                                        filterAction={filterPaymentBatches}
                                        filterColumn={"batch_date"}
                                        filterType={"batch_date"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("batch_date_lteq" || "batch_date_gteq")}
                                render={(text, record, index) => moment(text).format("L")}
                            />
                            {/* TODO: Make money formatting a library function */}
                            <Table.Column
                                title={renderColumnHeaderTitle("bills_due_date")}
                                key="bills_due_date"
                                dataIndex="bills_due_date"
                                filterDropdown={({ visible }) => (
                                    <ColumnDateFilter
                                        handleFetchInitial={handleFetchInitialPaymentBatches}
                                        filterAction={filterPaymentBatches}
                                        filterColumn={"bills_due_date"}
                                        filterType={"bills_due_date"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("bills_due_date_lteq" || "bills_due_date_gteq")}
                                render={(text, record, index) => moment(text).format("L")}
                            />
                            <Table.Column title={renderColumnHeaderTitle("batch_total", 'right_align_column')} key="batch_total" dataIndex="batch_total"
                                          align="right" render={(text, record, index) => formatCurrency(text)}
                            />
                            <Table.Column title="Notes" key="notes" dataIndex="notes" />
                            <Table.Column
                                title=""
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Role action="read" model="payment_batch">
                                                        <Menu.Item key="1" icon={<FolderOutlined />}>
                                                            <Link to={`${url}/${record.id}/vendor_payments`}>Vendor Payments</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="update" model="payment_batch">
                                                        <Menu.Item key="2" icon={<FolderOutlined />}>
                                                            <Link to={`${url}/${record.id}/bills`}>Bills</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="read" model="payment_batch">
                                                        <Menu.Item key="3" icon={<FileTextOutlined />} onClick={() => voucherCsv(record.id)}>
                                                            Voucher CSV
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="update" model="payment_batch">
                                                        <Menu.Item key="4" icon={<EditOutlined />}>
                                                            <Link to={`${url}/${record.id}/edit`}>Edit Vendor Batch</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="destroy" model="payment_batch">
                                                        <Menu.Item key="5" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)} disabled={record.has_been_closed}>
                                                            Delete Vendor Batch
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
