import React, { useState } from "react"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons"
import DashboardServices from "./DashboardServices"
import DashboardFilterDropdown from "./DashboardFilterDropdown"
import { selectCompareToLastYear, selectDateRangeValues } from "../dashboardsSlice"

export default function DashboardSelectionBar({ scopeType, scopeId, clientId }) {
    const [filterOpen, setFilterOpen] = useState(false)

    const compareToLastYear = useSelector(selectCompareToLastYear)
    const dateRangeValues = useSelector(selectDateRangeValues)

    return (
        <div className={"card dashboard-selection-bar"}>
            <DashboardServices scopeType={scopeType} scopeId={scopeId} clientId={clientId} />

            <div className="dashboard-filter-container">
                <div className="overview" onClick={() => setFilterOpen(!filterOpen)}>
                    <FontAwesomeIcon icon={faCalendarDays} color={"#fbb040"} />
                    <b>{compareToLastYear ? "Compare" : "Time Period"}</b>
                    {dateRangeValues.dateRangeFrom} - {dateRangeValues.dateRangeTo}
                    {compareToLastYear && (
                        <span>
                            <b>to</b>
                            {dateRangeValues.lastYearDateRangeFrom} - {dateRangeValues.lastYearDateRangeTo}
                        </span>
                    )}
                </div>

                {filterOpen && <DashboardFilterDropdown scopeType={scopeType} scopeId={scopeId} clientId={clientId} setFilterOpen={setFilterOpen} />}
            </div>
        </div>
    )
}
