import React, { useEffect, useState } from "react"
import { Table, Skeleton, Button, Tooltip } from "antd"
import { DownloadOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons"
import { Link, useRouteMatch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { downloadDocument, fetchDocuments, selectDocuments, selectLoading, selectPagination, selectTotal } from "../documentsSlice"
import moment from "moment"

export default function DocumentsTable({ type, id, display }) {
    const dispatch = useDispatch()

    const documents = useSelector(selectDocuments)
    const loading = useSelector(selectLoading)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)

    const { url } = useRouteMatch()

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            dispatch(fetchDocuments(pagination, type, id))
        }
    }, [dispatch, id, pagination, type, loaded])

    const handleTableChange = (newPagination) => {
        dispatch(fetchDocuments(newPagination, type, id))
    }

    const download = (record) => {
        dispatch(downloadDocument(record.id))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>{display || type} Documents</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {/* @TODO how do we get the type & id into here if it's not nested... */}
                                <Link to={`${url}/new`}>
                                    <PlusOutlined />
                                    Add New
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Skeleton loading={loading}>
                        <Table dataSource={documents} onChange={handleTableChange} rowKey={(r) => r.id} pagination={{ ...pagination, showSizeChanger: true, total: total }}>
                            <Table.Column title="Title" key="title" dataIndex="title" />
                            <Table.Column title="Created At" key="created_at" dataIndex="created_at" render={(text, record) => moment(record.created_at).format("MM/DD/YYYY")} />
                            <Table.Column title="Updated At" key="updated_at" dataIndex="updated_at" render={(text, record) => moment(record.updated_at).format("MM/DD/YYYY")} />
                            <Table.Column
                                title="Download"
                                key="download"
                                render={(text, record) => (
                                    <Button type="primary" onClick={() => download(record)}>
                                        <DownloadOutlined />
                                    </Button>
                                )}
                            />
                            <Table.Column
                                title="Edit"
                                key="edit"
                                render={(text, record) => (
                                    <div className="row-actions" style={{ display: "inline-block" }}>
                                        <Link to={`${url}/${record.id}/edit`}>
                                            <Tooltip title="Edit">
                                                <EditOutlined />
                                            </Tooltip>
                                        </Link>
                                    </div>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}
