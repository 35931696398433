import React, { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import { Input, Select, Button } from "antd"
import { CloseCircleOutlined } from "@ant-design/icons"

import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"

const { Option } = Select

function ColumnToggleFilter(props) {
    const focusInput = useRef(null)

    /////*** redux start ***/////
    const dispatch = useDispatch()
    /////*** redux end ***/////

    /////*** router start ***/////
    let { clientId, groupAccountId } = useParams()
    /////*** router end ***/////

    /////*** component state start ***/////
    const [searchString, setSearchString] = useState("")
    const [filterData, setFilterData] = useState(null)
    /////*** component state end ***/////

    /////*** component useEffects start ***/////
    useEffect(() => {
        /// we check to see if the current column in the table is already being filtered -- if it is, we assign it
        /// to the searchProps variable.
        let searchProps = props.filters?.[props.filterType]

        /// if the table's column is already being filtered and that filter isn't the same as "searchString,"
        /// we update searchString; this ensures that the current filter shows up as text inside the filter input when
        // this component (ColumnSearchFilter) loads
        if (!!searchProps && searchProps !== searchString) {
            setSearchString(searchProps)
        }
    }, [props.filters]) // eslint-disable-line
    /////*** component useEffects end ***/////

    useEffect(() => {
        // console.log(props.visible)
        if (focusInput.current != null) {
            if (props.visible) {
                setTimeout(() => focusInput.current.focus(), 100)
            }
        }
    }, [props.visible]) // eslint-disable-line

    /////*** event handler methods start ***/////
    const handleClearFilter = () => {
        /// clear the text in the filter
        setSearchString("")

        /// call handleSetFilters with the the "fromClear" value being true, since it's from clicking the clear button.
        /// the extra parenthesis are necessary to call the function correctly -- it's originally a handler function,
        /// so if we refactor it to take away the "() =>" on handleSetFilters, the handleSetFilters function will
        /// execute when the component loads.
        handleSetFilters(true)()
    }

    const handleSearchStringChange = (event) => {
        setSearchString(event.target.value)
    }

    const handleSetFilters = (fromClear) => () => {
        /// create a copy of the filters object that's passed down from props -- which are a list of filters that have
        /// already been applied to the table
        let newFilters = { ...props.filters }

        /// if we're clearing the value of the filter, we want to make sure that the value we're assigning to the
        /// filter is an empty string instead of what could've previously been in state as "searchString" -- although
        /// we previously reset the state to an empty string in the handleClearFilter function, this function
        /// (handleSetFilters) will trigger before the component updates.
        let filterString = !!fromClear ? "" : searchString

        /// update the newFilters object with the key value pair representing the column name (passed down from props)
        /// as the key and filterString as its value
        newFilters[props.filterType] = filterString

        /// pass the newFilters object back up to the parent component's state, so it can manage all the active filters
        props.setFilters(newFilters)

        if (filterData != newFilters) {
            setFilterData(newFilters)
            if (!allObjectValuesEmpty(newFilters) || props.sort !== { column: "name", direction: "asc" }) {
                return dispatch(
                    props.filterAction(
                        {
                            clientId: clientId,
                            groupAccountId: groupAccountId,
                            filters: newFilters,
                            pagination: { current: 1 },
                            sort: props.sort,
                        },
                        { divisionId: props.divisionId, facilityId: props.facilityId, billableAccountId: props.billableAccountId, supplyAccountId: props.supplyAccountId }
                    )
                )
            }
        } else {
            /// if there are any that values that exist in the newFilters object that are not empty strings, or we have a
            /// non-default sorting method selected, we send the whole object to be filtered/sorted.
            if (!allObjectValuesEmpty(newFilters) || props.sort !== { column: "name", direction: "asc" }) {
                return dispatch(
                    props.filterAction(
                        {
                            clientId: clientId,
                            groupAccountId: groupAccountId,
                            filters: newFilters,

                            pagination: props.pagination,
                            sort: props.sort,
                        },
                        { divisionId: props.divisionId, facilityId: props.facilityId, billableAccountId: props.billableAccountId, supplyAccountId: props.supplyAccountId }
                    )
                )
            }
        }

        /// if all of the values in the newFilters object that exist are empty strings (and not caught by the for loop
        /// above), it means that all the filters have been cleared and we want to reset the filtered list back to its
        /// original state via a method passed down from props.
        props.handleFetchInitial()
    }
    /////*** event handler methods end ***/////

    const renderSuffixButtons = (
        <div className="columnsearchfilter-suffixbuttons">
            <CloseCircleOutlined onClick={handleClearFilter} />
        </div>
    )

    return (
        <div className="columnsearchfilter">
            <Select placeholder={"- select -"} showArrow={true} onChange={setSearchString} value={searchString}>
                <Option key={0} value="" label="- select -">
                    - select -
                </Option>
                <Option key={1} value="1" label="Active">
                    Active
                </Option>
                <Option key={2} value="0" label="Inactive">
                    Inactive
                </Option>
            </Select>
            <Button className="ant-btn ant-btn-primary ant-input-search-button" onClick={handleSetFilters(false)}>
                {" "}
                Filter{" "}
            </Button>
        </div>
    )
}

export default ColumnToggleFilter
