import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useParams } from "react-router-dom"

import { selectScopedByClient } from "../../stores/appSlice"
// import { fetchDivision, selectDivision } from "./divisionsSlice"

export default function AlertsPanelNav({ activeTab }) {
    const dispatch = useDispatch()
    const { clientId, divisionId } = useParams()
    // const division = useSelector(selectDivision)
    const scopedByClient = useSelector(selectScopedByClient)

    const formatRoute = (newRoute) => {
        if (clientId) {
            return `/clients/${clientId}/alerts${newRoute}`
        } else {
            return `/alerts${newRoute || ""}`
        }
    }

    // If we are scoped by a client, we are looking at an end-user's view.
    // If we are not scoped by a client, we are looking at an admin's view.
    const renderHeader = () => {
        // if (scopedByClient) {
        //     return renderIndexHeader()
        // }

        return (
            <>
                <h2>Alerts</h2>
                <ul className="header-tab-nav" role="navigation">
                    <li>
                        <Link to={() => formatRoute("/billable_accounts_missing_bills")}>
                            <button type="link" className={`${activeTab === "billable_accounts_missing_bills" ? "selected" : ""}`}>
                                Billable Accounts W/O Bills Past 30 Days
                            </button>
                        </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/supply_accounts_missing_bills")}>
                            <button type="link" className={`${activeTab === "supply_accounts_missing_bills" ? "selected" : ""}`}>
                                Supply Accounts W/O Bills Past 30 Days
                            </button>
                        </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/billable_accounts_consecutive_estimations")}>
                            <button type="link" className={`${activeTab === "billable_accounts_consecutive_estimations" ? "selected" : ""}`}>
                                Billable Accounts With Three Consecutive Estimated Reads
                            </button>
                        </Link>
                    </li>

                    <li>
                        <Link to={() => formatRoute("/past_due_bills")}>
                            <button type="link" className={`${activeTab === "past_due_bills" ? "selected" : ""}`}>
                                Bills 30+ Days Past Due
                            </button>
                        </Link>
                    </li>
                </ul>
            </>
        )
    }

    return <>{renderHeader()}</>
}
