import { createSlice } from "@reduxjs/toolkit"
import { notification } from "antd"

// http client
import client from "../../../lib/client"
import { unitsUrl, unitUrl, unitSelectOptionsUrl, dynamicUrl } from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"

export const unitsSlice = createSlice({
    name: "units",
    initialState: {
        unit: {},
        units: [],
        unitSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setUnit: (state, action) => {
            state.unit = action.payload
        },
        setUnits: (state, action) => {
            state.units = action.payload.units
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setUnitSelectOptions: (state, action) => {
            state.unitSelectOptions = action.payload
        },
        deleteUnit: (state, action) => {
            state.units = state.units.filter((unit) => unit.id !== action.payload)
            state.total = state.total - 1
        },
    },
})

export const { setUnit, setUnits, setPagination, setLoading, setUnitSelectOptions } = unitsSlice.actions

export function fetchUnit(id) {
    return async (dispatch, getState) => {
        client.get(unitUrl(id)).then((response) => {
            dispatch(setUnit(response.data))
        })
    }
}

export function fetchUnits(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(`${unitsUrl}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setUnits(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClientUnits(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientUnitsPath = `clients/${clientId}/units/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientUnitsPath)}`)
        return response.data.units
    }
}

export function submitUnit(values, id) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(unitUrl(id), { unit: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(unitsUrl, { unit: values })
            return response.data
        }
    }
}

export function deleteUnit(id) {
    return async (dispatch, getState) => {
        client
            .delete(`${unitUrl(id)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                    })

                    dispatch(unitsSlice.actions.deleteUnit(id))
                } else {
                    notification.error({
                        message: data.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
    }
}

export function fetchUnitSelectOptions(searchTerm, currentPath) {
    return async (dispatch, getState) => {
        client.get(`${unitSelectOptionsUrl}`).then((response) => {
            dispatch(setUnitSelectOptions(response.data.units))
        })
    }
}

export function filterUnits(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, unitsUrl)

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setUnits(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectUnit = (state) => state.units.unit
export const selectUnits = (state) => state.units.units
export const selectTotal = (state) => state.units.total
export const selectPagination = (state) => state.units.pagination
export const selectLoading = (state) => state.units.loading
export const selectUnitSelectOptions = (state) => state.units.unitSelectOptions

export default unitsSlice.reducer
