import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Tooltip, Skeleton, Button, notification, Dropdown, Menu } from "antd"
import { DownloadOutlined, PlusOutlined, UploadOutlined, EditOutlined, SearchOutlined, FilterOutlined, DownOutlined, DeleteOutlined } from "@ant-design/icons"
import moment from "moment"
import nodePath from "path"

import { fetchBills, selectBills, deleteBill, selectTotal, selectPagination, selectLoading, filterBills, generateCsv } from "../billsSlice"
import WarningChip from "./subcomponents/WarningChip"
import ColumnSearchFilter from "../../../components/utilityComponents/table/ColumnSearchFilter"
import { allObjectValuesEmpty } from "../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../functions/objectFunctions/tableFiltersApplied"
import ColumnDateFilter from "../../../components/utilityComponents/table/ColumnDateFilter"
import BillAccountLink from "../../../components/utilityComponents/linkGenerators/BillAccountLink"
import useConfirmationModal from "../../alertsPanel/ShowConfirm"

import Role from "../../userRoles/Role"
import { formatCurrency } from "../../../lib/utilities";

export default function BillTable({ scopedByClient, accountType, accountId }) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    let { clientId, groupAccountId, billableAccountId, supplyAccountId } = useParams()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const bills = useSelector(selectBills)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "full_name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialBills()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialBills = () => {
        dispatch(fetchBills(pagination, nodePath.join(url, "")))
    }

    const handleFilterBills = (newPagination = null, newSort = null) => {
        return dispatch(
            filterBills(
                {
                    clientId: clientId,
                    filters: filters,
                    pagination: !!newPagination ? newPagination : pagination,
                    sort: !!newSort ? newSort : sort,
                },
                { billableAccountId: billableAccountId, supplyAccountId: supplyAccountId }
            )
        )
    }

    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteBill, id);
    };

    const handleSortBillsChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterBills(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchBills(newPagination, nodePath.join(url, "")))
        } else {
            handleFilterBills(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title, altTitle = null, columnClass = '') => {
        return <ColumnHeaderTitle title={title} altTitle={altTitle} handleSortChange={handleSortBillsChange} sort={sort} columnClass={columnClass}  />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialBills()
                        setFilters({})
                        setSort({ column: "", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    const billsCsv = () => {
        notification.open({
            message: "Utility bills export started",
        })

        dispatch(generateCsv(clientId, filters))
    }

    const renderAccountParams = () => {
        if (!!billableAccountId) {
            return "?account_type=billable_account&account_id=" + billableAccountId
        } else if (!!supplyAccountId) {
            return "?account_type=supply_account&account_id=" + supplyAccountId
        } else {
            return ""
        }
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Bills</h2>
                            <div className="badge">{total}</div>
                        </div>

                        {!groupAccountId && (
                            <div className="actions">
                                <div className="table-action-links">
                                    {renderResetFilters()}

                                    {!!accountType && !!accountId ? (
                                        <Role action="create" model="bill">
                                            <Link to={`/clients/${clientId}/bills/new?account_type=${accountType}&account_id=${accountId}`}>
                                                <PlusOutlined />
                                                Add New
                                            </Link>
                                        </Role>
                                    ) : (
                                        <Role action="create" model="bill">
                                            <Link to={`${url}/new`}>
                                                <PlusOutlined />
                                                Add New
                                            </Link>
                                        </Role>
                                    )}

                                    <Link to={`${url}/import`}>
                                        <PlusOutlined />
                                        Import
                                    </Link>

                                    <a href="javascript:;" onClick={() => billsCsv()}>
                                        <DownloadOutlined />
                                        Export
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={bills} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title=" "
                                key="flags"
                                dataIndex="flags"
                                render={(flags, record) => <WarningChip contentText={Object.values(flags)} flagCount={Object.values(flags).length} childFlags={record.any_child_flags} />}
                                width={50}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("vendor_name", "vendor")}
                                key="vendor_name"
                                dataIndex="vendor_name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialBills}
                                        filterAction={filterBills}
                                        filterColumn={"vendor_name"}
                                        filterType={"vendor_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        billableAccountId={billableAccountId}
                                        supplyAccountId={supplyAccountId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("vendor_name_cont")}
                            />
                            <Table.Column
                                title={"Account"}
                                key="account_name_with_number"
                                dataIndex="account_name_with_number"
                                render={(text, record) => (
                                    <BillAccountLink
                                        accountType={record.account_type}
                                        accountId={record.account_id}
                                        accountName={record.account_name_with_number}
                                        clientId={record.client_id}
                                        target="_self"
                                    />
                                )}
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialBills}
                                        filterAction={filterBills}
                                        filterColumn={"account_name"}
                                        filterType={
                                            "account_of_BillableAccount_type_name_or_account_of_BillableAccount_type_account_number_or_account_of_SupplyAccount_type_name_or_account_of_SupplyAccount_type_account_number_cont"
                                        }
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        billableAccountId={billableAccountId}
                                        supplyAccountId={supplyAccountId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("account_of_BillableAccount_type_name_or_account_of_SupplyAccount_type_name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("accounting_batch_batch_code", "client batch")}
                                key="accounting_batch_batch_code"
                                dataIndex="accounting_batch_batch_code"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialBills}
                                        filterAction={filterBills}
                                        filterColumn={"accounting_batch_batch_code"}
                                        filterType={"accounting_batch_batch_code_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        billableAccountId={billableAccountId}
                                        supplyAccountId={supplyAccountId}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("accounting_batch_batch_code_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("date")}
                                key="date"
                                dataIndex="date"
                                filterDropdown={({ visible }) => (
                                    <ColumnDateFilter
                                        handleFetchInitial={handleFetchInitialBills}
                                        filterAction={filterBills}
                                        filterColumn={"date"}
                                        filterType={"date"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("date_lteq" || "date_gteq")}
                                render={(text, record, index) => moment(text).format("L")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("due_date")}
                                key="due_date"
                                dataIndex="due_date"
                                filterDropdown={({ visible }) => (
                                    <ColumnDateFilter
                                        handleFetchInitial={handleFetchInitialBills}
                                        filterAction={filterBills}
                                        filterColumn={"due_date"}
                                        filterType={"due_date"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("due_date_lteq" || "due_date_gteq")}
                                render={(text, record, index) => moment(text).format("L")}
                            />
                            <Table.Column title={"Read Date"} key="read_date" dataIndex="read_date" render={(text, record, index) => moment(text).format("L")} />
                            <Table.Column title={"Total Usage"} key="total_usage" dataIndex="total_usage" align='right'/>
                            <Table.Column title={renderColumnHeaderTitle("current_charges", null, 'right_align_column')} key="current_charges" dataIndex="current_charges" align='right' render={(text, record, index) => formatCurrency(text)} />
                            {/* <Table.Column
                                title={renderColumnHeaderTitle("created_at")}
                                key="created_at"
                                dataIndex="created_at"
                                filterDropdown={({ visible }) => (
                                    <ColumnDateFilter
                                        handleFetchInitial={handleFetchInitialBills}
                                        filterAction={filterBills}
                                        filterColumn={"created_at"}
                                        filterType={"created_at"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("created_at_lteq" || "created_at_gteq")}
                                render={(text, record, index) => moment(text).format("L")}
                            /> */}
                            <Table.Column title="Status" key="full_status" dataIndex="full_status" />
                            <Table.Column
                                title="Copy"
                                key="copy"
                                render={(text, record) => (
                                    <>
                                        {record.file_url && (
                                            <Button type="primary" size="small" onClick={() => window.open(record.file_url, "_blank")}>
                                                <DownloadOutlined />
                                            </Button>
                                        )}
                                    </>
                                )}
                            />
                            {/* <Table.Column title="Abbreviation" key="abbreviation" dataIndex="abbreviation" />
                            <Table.Column title="Code" key="code" dataIndex="code" />
                            <Table.Column title="Client" key="client_id" dataIndex="client_id" /> */}
                            {!groupAccountId && (
                                <Table.Column
                                    title="Edit"
                                    key="action"
                                    render={(text, record) => (
                                        <>
                                            <Dropdown
                                                overlay={
                                                    <Menu>
                                                        <Role action="update" model="bill">
                                                            <Menu.Item key="1" icon={<EditOutlined />}>
                                                                <Link to={`/clients/${clientId}/bills/${record.id}/edit${renderAccountParams()}`}>Edit Bill</Link>
                                                            </Menu.Item>
                                                        </Role>

                                                        <Role action="destroy" model="bill">
                                                            <Menu.Item key="3" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)}>
                                                                Delete Bill
                                                            </Menu.Item>
                                                        </Role>
                                                    </Menu>
                                                }>
                                                <Button>
                                                    Actions <DownOutlined />
                                                </Button>
                                            </Dropdown>
                                        </>
                                    )}
                                />
                            )}
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
