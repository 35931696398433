export default window.localStorage

const authTokenKey = "muc-token"
const authClientKey = "muc-client"
const authUidKey = "muc-uid"

export const getToken = () => {
    return window.localStorage.getItem(authTokenKey)
}

export const setToken = (token) => {
    window.localStorage.setItem(authTokenKey, token)
}

export const removeToken = () => {
    window.localStorage.removeItem(authTokenKey)
}

export const getClient = () => {
    return window.localStorage.getItem(authClientKey)
}

export const setClient = (client) => {
    window.localStorage.setItem(authClientKey, client)
}

export const removeClient = () => {
    window.localStorage.removeItem(authClientKey)
}

export const getUid = () => {
    return window.localStorage.getItem(authUidKey)
}

export const setUid = (uid) => {
    window.localStorage.setItem(authUidKey, uid)
}

export const removeUid = () => {
    window.localStorage.removeItem(authUidKey)
}

/**
 * Wipe local storage from all app data used for things like when a user logs out
 * or has invalid credentials in local storage (expired tokens, etc)
 */
export const clearAuthStorage = () => {
    removeToken()
    removeClient()
    removeUid()
}
