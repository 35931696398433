import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Tooltip, Skeleton, Button, notification, Dropdown, Menu } from "antd"
import { PlusOutlined, EditOutlined, FilterOutlined, SearchOutlined, DownloadOutlined, UserOutlined, DownOutlined, MailOutlined, DeleteOutlined } from "@ant-design/icons"
import nodePath from "path"

import { loadUsers, selectPagination, selectTotal, selectUsers, selectLoading, filterUsers, generateUsersCsv, resendAccountConfirmationEmail, deleteUser } from "./usersSlice"
import { allObjectValuesEmpty } from "../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../functions/objectFunctions/tableFiltersApplied"
import ColumnSearchFilter from "../../components/utilityComponents/table/ColumnSearchFilter"
import useConfirmationModal from "../alertsPanel/ShowConfirm"

import Role from "../userRoles/Role"

export default function UserTable() {
    /////*** redux start ***/////
    const dispatch = useDispatch()
    const { url } = useRouteMatch()
    let { clientId } = useParams()

    const users = useSelector(selectUsers)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "email",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialUsers()
        }
    }, [loaded, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialUsers = () => {
        dispatch(loadUsers(pagination, nodePath.join(url, "")))
    }

    const handleFilterUsers = (newPagination = null, newSort = null) => {
        return dispatch(
            filterUsers({
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
                clientId: clientId,
            })
        )
    }

    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteUser, id);
    };

    const handleSortUsersChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterUsers(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(loadUsers(newPagination, nodePath.join(url, "")))
        } else {
            handleFilterUsers(newPagination)
        }
    }
    /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortUsersChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a // eslint-disable-line
                    onClick={() => {
                        handleFetchInitialUsers()
                        setFilters({})
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ fontSize: "16px", color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    const usersCsv = () => {
        notification.open({
            message: "Users export started",
        })

        dispatch(generateUsersCsv(filters))
    }

    const resendConfirmationEmail = async (userId) => {
        const response = await dispatch(resendAccountConfirmationEmail(userId))

        if (response.success) {
            notification.open({
                message: "Password reset email sent",
            })
        }
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Users</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <Role action="create" model="user">
                                    <Link to="/users/new">
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role>

                                <Button type="link" onClick={() => usersCsv()}>
                                    <DownloadOutlined />
                                    Export
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Skeleton active loading={loading}>
                        <Table dataSource={users} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("email")}
                                key="email"
                                dataIndex="email"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialUsers}
                                        filterAction={filterUsers}
                                        filterColumn={"email"}
                                        filterType={"email_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("email_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("first_name")}
                                key="first_name"
                                dataIndex="first_name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialUsers}
                                        filterAction={filterUsers}
                                        filterColumn={"first_name"}
                                        filterType={"first_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("first_name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("last_name")}
                                key="last_name"
                                dataIndex="last_name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialUsers}
                                        filterAction={filterUsers}
                                        filterColumn={"last_name"}
                                        filterType={"last_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("last_name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("username")}
                                key="username"
                                dataIndex="username"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialUsers}
                                        filterAction={filterUsers}
                                        filterColumn={"username"}
                                        filterType={"username_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("username_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("title")}
                                key="title"
                                dataIndex="title"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialUsers}
                                        filterAction={filterUsers}
                                        filterColumn={"title"}
                                        filterType={"title_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("title_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("status")}
                                key="status"
                                dataIndex="status"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialUsers}
                                        filterAction={filterUsers}
                                        filterColumn={"status"}
                                        filterType={"status_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("title_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("account_confirmed")}
                                key="account_confirmed"
                                dataIndex="account_confirmed"
                                render={(text, record) => (record.account_confirmed ? "Yes" : "")}
                            />
                            <Table.Column
                                title="Role(s)"
                                key="roles"
                                dataIndex="roles"
                                render={(text, record) => (record.associated_entries.roles.map(role => role.name.split('_').join(' ').toUpperCase()).join(', '))}
                            />
                            <Table.Column
                                title="Client(s)"
                                key="clients"
                                dataIndex="clients"
                                render={(text, record) => (record.associated_entries.clients.map(client => client.name.split('_').join(' ').toUpperCase()).join(', '))}
                            />
                            <Table.Column
                                title="Actions"
                                key="actions"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Role action="update" model="user">
                                                        <Menu.Item key="1" icon={<EditOutlined />}>
                                                            <Link to={clientId ? `/clients/${clientId}/users/${record.id}/edit` : `/users/${record.id}/edit`}>Edit</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="update" model="user">
                                                        <Menu.Item key="2" icon={<UserOutlined />}>
                                                            <Link to={clientId ? `/clients/${clientId}/users/${record.id}/roles` : `/users/${record.id}/roles`}>Roles</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="destroy" model="user">
                                                        <Menu.Item key="3" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)}>
                                                            Delete user
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="update" model="user">
                                                        <Menu.Item key="3" icon={<EditOutlined />}>
                                                        <Link to={`/users/${record.id}/edit/password`}>Update Password</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="update" model="user">
                                                        <Menu.Item key="3" icon={<MailOutlined />} onClick={() => resendConfirmationEmail(record.id)}>
                                                            Resend password email
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
        </div>
    )
}
