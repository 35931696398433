import React from "react"
import { Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import AlertsPanelNav from "../features/alertsPanel/AlertsPanelNav"
import AlertsPanelPage from "../features/alertsPanel/AlertsPanelPage"

export default function AlertPanelRoutes() {
    return (
        <Switch>
            <Route exact path={`/clients/:clientId/alerts/billable_accounts_missing_bills`}>
                <ConsoleLayout
                    highlightTab="alerts"
                    header={<AlertsPanelNav activeTab="billable_accounts_missing_bills" />}
                    body={<AlertsPanelPage view="billable_accounts_missing_bills" recordSelected={false} />}
                    sidebarType=""
                />
            </Route>

            <Route exact path={`/clients/:clientId/alerts/supply_accounts_missing_bills`}>
                <ConsoleLayout
                    highlightTab="alerts"
                    header={<AlertsPanelNav activeTab="supply_accounts_missing_bills" />}
                    body={<AlertsPanelPage view="supply_accounts_missing_bills" recordSelected={false} />}
                    sidebarType=""
                />
            </Route>

            <Route exact path={`/clients/:clientId/alerts/billable_accounts_consecutive_estimations`}>
                <ConsoleLayout
                    highlightTab="alerts"
                    header={<AlertsPanelNav activeTab="billable_accounts_consecutive_estimations" />}
                    body={<AlertsPanelPage view="billable_accounts_consecutive_estimations" recordSelected={false} />}
                    sidebarType=""
                />
            </Route>

            <Route exact path={`/clients/:clientId/alerts/past_due_bills`}>
                <ConsoleLayout highlightTab="alerts" header={<AlertsPanelNav activeTab="past_due_bills" />} body={<AlertsPanelPage view="past_due_bills" recordSelected={false} />} sidebarType="" />
            </Route>
        </Switch>
    )
}
