import React from "react"
import { Form, Input, InputNumber, DatePicker} from "antd"
import { CloseOutlined } from "@ant-design/icons"
const dateFormat = "MM/DD/YYYY"

function SiteSurveysHvacFormPiece(props) {
    return (
        <div className={"site-surveys-hvac-form-piece"}>
            <CloseOutlined className={"close-icon"} onClick={props.remove} />

            <Form.Item {...props} name={[props.name, "area"]} fieldKey={[props.fieldKey, "area"]} label={"Area"}>
                <Input />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "cooling"]} fieldKey={[props.fieldKey, "cooling"]} label={"Cooling"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "cooling_efficiency"]} fieldKey={[props.fieldKey, "cooling_efficiency"]} label={"Cooling Efficiency"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "cooling_installation_date"]} fieldKey={[props.fieldKey, "cooling_installation_date"]} label={"Cooling Installation Date"}>
                <DatePicker format={dateFormat} style={{ width: "100%" }} /> 
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "heating"]} fieldKey={[props.fieldKey, "heating"]} label={"Heating"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "heating_efficiency"]} fieldKey={[props.fieldKey, "heating_efficiency"]} label={"Heating Efficiency"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "heating_installation_date"]} fieldKey={[props.fieldKey, "heating_installation_date"]} label={"Heating Installation Date"}>
                <DatePicker format={dateFormat} style={{ width: "100%" }} />
            </Form.Item>

            <hr />

            <Form.Item {...props} name={[props.name, "water"]} fieldKey={[props.fieldKey, "water"]} label={"Water"}>
                <Input />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "water_efficiency"]} fieldKey={[props.fieldKey, "water_efficiency"]} label={"Water Efficiency"} rules={[{ type: "number" }]}>
                <InputNumber />
            </Form.Item>
            <Form.Item {...props} name={[props.name, "water_installation_date"]} fieldKey={[props.fieldKey, "water_installation_date"]} label={"Water Installation Date"}>
                <DatePicker format={dateFormat} style={{ width: "100%" }} />
            </Form.Item>
        </div>
    )
}

export default SiteSurveysHvacFormPiece
