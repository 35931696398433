import React from "react"
import { Link } from "react-router-dom"

export default function ServiceBillServiceLink({ accountId, serviceNumber, serviceId, serviceType, clientId }) {
    return (
        <Link to={`/clients/${clientId}/billable_accounts/${accountId}/services/${serviceId}/edit`} target="_blank">
            {serviceType} - {serviceNumber}
        </Link>
    )
}
