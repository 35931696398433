import React from "react"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { arrayMoveImmutable as arrayMove } from "array-move"
import { Form, Switch } from "antd"
import { titleize } from "../../../../lib/string"

const SortableItem = SortableElement(({ field, handleToggle }) => (
    <li>
        <Switch checked={field.checked} onChange={(checked) => handleToggle(field.name, checked)} />
        <span className="sortable-label">{titleize(field.name)}</span>
    </li>
))

const SortableList = SortableContainer(({ fields, handleToggle, toggleAll }) => (
    <ul style={{ listStyle: "none" }} className="switch-list">
        <li>
            <Switch onChange={(checked) => toggleAll(checked)} />
            Select/Deselect All
        </li>

        {fields.map((f, index) => {
            return <SortableItem key={f.name} index={index} field={f} handleToggle={handleToggle} />
        })}
    </ul>
))

export default function GlobalDataServiceFields({ fields, setServiceFields, form }) {
    const handleSort = ({ oldIndex, newIndex }) => {
        const current = form.getFieldsValue()

        const sorted = arrayMove(fields, oldIndex, newIndex)

        form.setFieldsValue({
            ...current,
            form_data: {
                ...current.form_data,
                utility_service_fields: sorted,
            },
        })

        setServiceFields(sorted)
    }

    const handleToggle = (name, checked) => {
        const current = form.getFieldsValue()

        const newData = fields.map((i) => {
            if (i.name === name) {
                return {
                    ...i,
                    checked: checked,
                }
            } else {
                return i
            }
        })

        setServiceFields(newData)

        form.setFieldsValue({
            ...current,
            form_data: {
                ...current.form_data,
                utility_service_fields: newData,
            },
        })
    }

    const toggleAll = (checked) => {
        const current = form.getFieldsValue()

        const newData = fields.map((i) => {
            return {
                ...i,
                checked: checked,
            }
        })

        setServiceFields(newData)

        form.setFieldsValue({
            ...current,
            form_data: {
                ...current.form_data,
                utility_service_fields: newData,
            },
        })
    }

    return <SortableList fields={fields} onSortEnd={handleSort} handleToggle={handleToggle} toggleAll={toggleAll} distance={10} />
}
