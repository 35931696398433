import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useHistory, useRouteMatch } from "react-router-dom"
import { Button, Form, Input, Row, Col, notification } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import nodePath from "path"

import { fetchUnit, selectUnit, setUnit, submitUnit } from "../unitsSlice"

import { requestNotification } from "../../../../lib/notifications"

export default function UnitForm({ actionName }) {
    const { url } = useRouteMatch()
    const history = useHistory()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const unit = useSelector(selectUnit)
    const { unitId } = useParams()

    useEffect(() => {
        if (unitId) {
            dispatch(fetchUnit(unitId))
        } else {
            dispatch(setUnit({}))
        }
    }, [dispatch, unitId])

    useEffect(() => {
        form.resetFields()
    }, [form, unit])

    const submit = async (values) => {
        const response = await dispatch(submitUnit(values, unitId))

        if (response.success) {
            actionName === "edit" ? history.push(`${nodePath.join(url, "../..")}`) : history.push(`${nodePath.join(url, "..")}`)
        }

        requestNotification(response)
    }

    return (
        <div id="user-form-container">
            <Link to={"/settings/units"}>
                <LeftOutlined />
                Go Back
            </Link>
            <Form form={form} initialValues={unit} onFinish={submit}>
                <div className="card">
                    <div className="card-body">
                        <div>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Unit symbol" name="symbol" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label="Description" name="description" rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
