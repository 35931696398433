import React from "react"
import { useSelector } from "react-redux"

import { selectScopedByClient } from "../../stores/appSlice"
import BillableAccountsMissingBillsTable from "./BillableAccountsMissingBills/BillableAccountsMissingBillsTable"
import SupplyAccountsMissingBillsTable from "./SupplyAccountsMissingBills/SupplyAccountsMissingBillsTable"
import BillableAccountsConsecutiveEstimationsTable from "./BillableAccountsConsecutiveEstimations/BillableAccountsConsecutiveEstimationsTable"
import PastDueBillsTable from "./PastDueBills/PastDueBillsTable"

export default function AlertsPanelPage({ view }) {
    const scopedByClient = useSelector(selectScopedByClient)

    const renderView = () => {
        switch (view) {
            case "billable_accounts_missing_bills":
                return renderBillableAccountsMissingBills()
            case "supply_accounts_missing_bills":
                return renderSupplyAccountsMissingBills()
            case "billable_accounts_consecutive_estimations":
                return renderBillableAccountsConsecutiveEstimations()
            case "past_due_bills":
                return renderPastDueBills()
            default:
                return renderSupplyAccountsMissingBills()
        }
    }

    const renderBillableAccountsMissingBills = () => {
        return <BillableAccountsMissingBillsTable scopedByClient={scopedByClient} />
    }

    const renderSupplyAccountsMissingBills = () => {
        return <SupplyAccountsMissingBillsTable scopedByClient={scopedByClient} />
    }

    const renderBillableAccountsConsecutiveEstimations = () => {
        return <BillableAccountsConsecutiveEstimationsTable scopedByClient={scopedByClient} />
    }

    const renderPastDueBills = () => {
        return <PastDueBillsTable scopedByClient={scopedByClient} />
    }

    return <>{renderView()}</>
}
