import React from "react"

import PaymentMethodTable from "./components/PaymentMethodTable"
import PaymentMethodForm from "./components/PaymentMethodForm"

export default function PaymentMethodPage({ view, recordSelected = null }) {
    const renderView = () => {
        switch (view) {
            case "index":
                return renderIndex()
            case "form":
                return renderForm()
            default:
                return renderIndex()
        }
    }

    const renderIndex = () => {
        return <PaymentMethodTable />
    }

    const renderForm = () => {
        if (recordSelected) {
            return <PaymentMethodForm actionName="edit" />
        }

        return <PaymentMethodForm actionName="new" />
    }

    return <>{renderView()}</>
}
