import { createSlice } from "@reduxjs/toolkit"

// import store from "../stores/store"
import client from "../lib/client"
import { clientUrl, activeUserUrl, channelPartnerUrl } from "../lib/endpoints"
import { setClient } from "../features/clients/clientsSlice"

export const appSlice = createSlice({
    name: "app",
    initialState: {
        uid: null,
        scopedByClient: null,
        scopedClient: null,
    },
    reducers: {
        setUid: (state, action) => {
            state.uid = action.payload.unique_id
        },
        setScopedByClient: (state, action) => {
            state.scopedByClient = action.payload
        },
        setScopedClient: (state, action) => {
            state.scopedClient = action.payload
        },
        setChannelPartnerLogo: (state, action) => {
            state.channelPartnerLogo = action.payload
        },
    },
})

export const { setUid, setScopedByClient, setScopedClient, setChannelPartnerLogo } = appSlice.actions

export function fetchAndSetUid() {
    return async (dispatch, getState) => {
        client.get(activeUserUrl).then((response) => {
            dispatch(setUid(response.data))
        })
    }
}

export function fetchAndSetScopedClient(id, pathypath) {
    return async (dispatch, getState) => {
        if (id) {
            // const response = await client.get(clientUrl(id))
            // console.log("RESPONSE: ", response.data)

            // dispatch(setScopedByClient(id))
            // dispatch(setScopedClient(response.data))
            // dispatch(setClient(response.data))

            // if (response.data.channel_partner_id) {
            //     client.get(channelPartnerUrl(response.data.channel_partner_id)).then((response) => {
            //         if (response.data.file_url) {
            //             // console.log(response.data.file_url)
            //             // console.log(typeof response.data.file_url)
            //             dispatch(setChannelPartnerLogo(response.data.file_url))
            //         } else {
            //             dispatch(setChannelPartnerLogo(null))
            //         }
            //     })
            // } else {
            //     dispatch(setChannelPartnerLogo(null))
            // }

            client.get(clientUrl(id)).then((response) => {
                dispatch(setScopedByClient(id))
                dispatch(setScopedClient(response.data))
                dispatch(setClient(response.data))

                if (response.data.channel_partner_id) {
                    client.get(channelPartnerUrl(response.data.channel_partner_id)).then((response) => {
                        if (response.data.file_url) {
                            // console.log(response.data.file_url)
                            // console.log(typeof response.data.file_url)
                            dispatch(setChannelPartnerLogo(response.data.file_url))
                        } else {
                            dispatch(setChannelPartnerLogo(null))
                        }
                    })
                } else {
                    dispatch(setChannelPartnerLogo(null))
                }

                if (!response.data.active) {
                    pathypath()
                }
            })
        } else {
            dispatch(setScopedByClient(null))
            dispatch(setScopedClient(null))
            dispatch(setChannelPartnerLogo(null))
        }
    }
}

export const selectUid = (state) => state.app.uid
export const selectScopedByClient = (state) => state.app.scopedByClient
export const selectScopedClient = (state) => state.app.scopedClient
export const selectChannelPartnerLogo = (state) => state.app.channelPartnerLogo

export default appSlice.reducer
