import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../../lib/client"
import { productionUnitsUrl, productionUnitUrl, productionUnitSelectOptionsUrl, dynamicUrl } from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"

export const productionUnitsSlice = createSlice({
    name: "productionUnits",
    initialState: {
        productionUnit: {},
        productionUnits: [],
        productionUnitSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setProductionUnit: (state, action) => {
            state.productionUnit = action.payload
        },
        setProductionUnits: (state, action) => {
            state.productionUnits = action.payload.production_units
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setProductionUnitSelectOptions: (state, action) => {
            state.productionUnitSelectOptions = action.payload
        },
    },
})

export const { setProductionUnit, setProductionUnits, setPagination, setLoading, setProductionUnitSelectOptions } = productionUnitsSlice.actions

export function fetchProductionUnit(id) {
    return async (dispatch, getState) => {
        client.get(productionUnitUrl(id)).then((response) => {
            dispatch(setProductionUnit(response.data))
        })
    }
}

export function fetchProductionUnits(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(`${productionUnitsUrl}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setProductionUnits(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClientProductionUnits(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientProductionUnitsPath = `clients/${clientId}/productionUnits/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientProductionUnitsPath)}`)
        return response.data.productionUnits
    }
}

export function submitProductionUnit(values, id) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(productionUnitUrl(id), { production_unit: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(productionUnitsUrl, { production_unit: values })
            return response.data
        }
    }
}

export function fetchProductionUnitSelectOptions(searchTerm, currentPath) {
    return async (dispatch, getState) => {
        client.get(`${productionUnitSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
            dispatch(setProductionUnitSelectOptions(response.data.production_units))
        })
    }
}

export function filterProductionUnits(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, productionUnitsUrl)

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setProductionUnits(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectProductionUnit = (state) => state.productionUnits.productionUnit
export const selectProductionUnits = (state) => state.productionUnits.productionUnits
export const selectTotal = (state) => state.productionUnits.total
export const selectPagination = (state) => state.productionUnits.pagination
export const selectLoading = (state) => state.productionUnits.loading
export const selectProductionUnitSelectOptions = (state) => state.productionUnits.productionUnitSelectOptions

export default productionUnitsSlice.reducer
