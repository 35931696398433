import React from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import ConsoleLayout from "../ConsoleLayout"

import BillableAccountNav from "../features/billableAccounts/BillableAccountNav"
import BillableAccountPage from "../features/billableAccounts/BillableAccountPage"

export default function BillableAccountRoutes() {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route exact path={path}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="list" />}
                    body={<BillableAccountPage view="index" recordSelected={false} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            <Route path={`${path}/new`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="list" />}
                    body={<BillableAccountPage view="form" recordSelected={false} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            <Route path={`${path}/import`}>
                <ConsoleLayout 
                    highlightTab="billableAccounts" 
                    header={<BillableAccountNav activeTab="list" />} 
                    body={<BillableAccountPage view="import" recordSelected={false} />} 
                    sidebarType="billableAccounts" />
            </Route>

            <Route path={`${path}/:billableAccountId/edit`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="list" scopedByBillableAccount={false} />}
                    body={<BillableAccountPage view="form" recordSelected={true} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            <Route path={`${path}/:billableAccountId/dashboard`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="dashboard" scopedByBillableAccount={true} />}
                    body={<BillableAccountPage view="dashboard" recordSelected={true} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            {/* SERVICES ------------------------------------------------------------------------------------------------------------- */}

            <Route path={`${path}/:billableAccountId/services/:serviceId/edit`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="services" scopedByBillableAccount={true} />}
                    body={<BillableAccountPage view="serviceForm" recordSelected={true} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            <Route path={`${path}/:billableAccountId/services/new`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="services" scopedByBillableAccount={true} />}
                    body={<BillableAccountPage view="serviceForm" recordSelected={false} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            <Route path={`${path}/:billableAccountId/services`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="services" scopedByBillableAccount={true} />}
                    body={<BillableAccountPage view="services" recordSelected={true} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            {/* END SERVICES --------------------------------------------------------------------------------------------------------- */}

            <Route path={`${path}/:billableAccountId/bills`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="bills" scopedByBillableAccount={true} />}
                    body={<BillableAccountPage view="bills" recordSelected={true} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            <Route path={`${path}/:billableAccountId/documents/new`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="documents" scopedByBillableAccount={true} />}
                    body={<BillableAccountPage view="documents-form" recordSelected={true} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            <Route path={`${path}/:billableAccountId/documents/:documentId/edit`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="documents" scopedByBillableAccount={true} />}
                    body={<BillableAccountPage view="documents-form" recordSelected={true} />}
                    sidebarType="billableAccounts"
                />
            </Route>

            <Route path={`${path}/:billableAccountId/documents`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="documents" scopedByBillableAccount={true} />}
                    body={<BillableAccountPage view="documents" recordSelected={true} />}
                    sidebarType="billableAccounts"
                />
            </Route>


            <Route path={`${path}/:billableAccountId`}>
                <ConsoleLayout
                    highlightTab="billableAccounts"
                    header={<BillableAccountNav activeTab="dashboard" scopedByBillableAccount={true} />}
                    body={<BillableAccountPage view="dashboard" recordSelected={true} />}
                    sidebarType="billableAccounts"
                />
            </Route>
        </Switch>
    )
}
