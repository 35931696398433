import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../../lib/client"
import { clientPastDueBillsUrl, dynamicUrl, clientPastDueBillsCsvUrl } from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"
import { createRansackParams } from "../../../functions/objectFunctions/createRansackParams"

export const pastDueBillsSlice = createSlice({
    name: "pastDueBills",
    initialState: {
        bills: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setBills: (state, action) => {
            state.bills = action.payload.bills
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
    },
})

export const { setBills, setPagination, setLoading } = pastDueBillsSlice.actions

export function fetchBills(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        // This route may be nested so we have to use a dynamic route
        // /divisions VS /clients/client_id/divisions
        client.get(`${dynamicUrl(currentPath)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setBills(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function filterBills(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, clientPastDueBillsUrl(query.clientId), "")

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setBills(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function generateCsv(clientId, filters) {
    return async (dispatch, getState) => {
        client.get(`${clientPastDueBillsCsvUrl(clientId)}${createRansackParams(filters)}connection_id=${getState().app.uid}`)
    }
}

export const selectBills = (state) => state.pastDueBills.bills
export const selectTotal = (state) => state.pastDueBills.total
export const selectPagination = (state) => state.pastDueBills.pagination
export const selectLoading = (state) => state.pastDueBills.loading

export default pastDueBillsSlice.reducer
