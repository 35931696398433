import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams, useRouteMatch } from "react-router-dom"
import { Table, Skeleton, Dropdown, Menu, Button, notification } from "antd"
import { PlusOutlined, EditOutlined, FilterOutlined, SearchOutlined, DeleteOutlined, FilePdfOutlined, DownOutlined } from "@ant-design/icons"

import { allObjectValuesEmpty } from "../../../../functions/objectFunctions/allObjectValuesEmpty"
import ColumnHeaderTitle from "../../../../components/utilityComponents/table/ColumnHeaderTitle"
import { tableFiltersApplied } from "../../../../functions/objectFunctions/tableFiltersApplied"
import ColumnSearchFilter from "../../../../components/utilityComponents/table/ColumnSearchFilter"
import { selectSiteSurveys, selectTotal, selectPagination, selectLoading, fetchSiteSurveys, filterSiteSurveys, generatePdf } from "../siteSurveysSlice"

import Role from "../../../userRoles/Role"

function SiteSurveysTable(props) {
    /////*** react router start ***/////
    const { url } = useRouteMatch()
    const { clientId } = useParams()
    /////*** react router end ***/////

    /////*** redux start ***/////
    const dispatch = useDispatch()
    const siteSurveys = useSelector(selectSiteSurveys)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    /////*** redux end ***/////

    /////*** useState start ***/////
    const [loaded, setLoaded] = useState(false)
    const [filters, setFilters] = useState({})
    const [sort, setSort] = useState({
        column: "name",
        direction: "asc",
    })
    /////*** useState end ***/////

    /////*** useEffects start ***/////
    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            handleFetchInitialSiteSurveys()
        }
    }, [loaded, url, dispatch, pagination]) // eslint-disable-line
    /////*** useEffects end ***/////

    /////*** event handler methods start ***/////
    const handleFetchInitialSiteSurveys = () => {
        dispatch(fetchSiteSurveys(pagination, clientId))
    }

    const handleFilterSiteSurveys = (newPagination = null, newSort = null) => {
        return dispatch(
            filterSiteSurveys({
                filters: filters,
                pagination: !!newPagination ? newPagination : pagination,
                sort: !!newSort ? newSort : sort,
            })
        )
    }

    const handleSortSiteSurveysChange = (column) => () => {
        let newDirection = sort.direction === "asc" ? "desc" : "asc"
        let newSort = {
            column: column,
            direction: newDirection,
        }
        setSort(newSort)
        handleFilterSiteSurveys(null, newSort)
    }

    const handleTableChange = (newPagination, sorter) => {
        if (allObjectValuesEmpty(filters) && allObjectValuesEmpty(sorter)) {
            dispatch(fetchSiteSurveys(newPagination))
        } else {
            handleFilterSiteSurveys(newPagination)
        }
    }

    const siteSurveyPdf = async (siteSurveyId) => {
        notification.open({
            message: "PDF generation started",
        })

        dispatch(generatePdf(siteSurveyId))
    }
    /////*** event handler methods end ***/////

    const renderColumnHeaderTitle = (title) => {
        return <ColumnHeaderTitle title={title} handleSortChange={handleSortSiteSurveysChange} sort={sort} />
    }

    const renderResetFilters = () => {
        if (allObjectValuesEmpty(filters)) {
            return null
        }
        return (
            <div className={"actions-alternate-buttons"}>
                <a
                    href="javascript:;"
                    onClick={() => {
                        handleFetchInitialSiteSurveys()
                        setFilters({})
                        setSort({ column: "name", direction: "asc" })
                    }}>
                    <FilterOutlined />
                    Reset Filters
                </a>
            </div>
        )
    }

    const renderSearchOutlinedButton = (filterKey) => {
        return <SearchOutlined style={{ color: tableFiltersApplied(filterKey, filters) ? "#1890ff" : undefined }} />
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Site Surveys</h2>
                            <div className="badge">{total}</div>
                        </div>

                        <div className="actions">
                            <div className="table-action-links">
                                {renderResetFilters()}

                                <Role action="create" model="site_survey">
                                    <Link to={`/clients/${clientId}/ancillary_services/site_surveys/new`}>
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={siteSurveys} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column
                                title={renderColumnHeaderTitle("name")}
                                key="name"
                                dataIndex="name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialSiteSurveys}
                                        filterAction={filterSiteSurveys}
                                        filterColumn={"name"}
                                        filterType={"name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("facility")}
                                key="facility_id"
                                dataIndex="facility"
                                render={(facility) => facility?.name}
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialSiteSurveys}
                                        filterAction={filterSiteSurveys}
                                        filterColumn={"facility_name"}
                                        filterType={"facility_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("facility_name_cont")}
                            />
                            <Table.Column
                                title={renderColumnHeaderTitle("facility_type")}
                                key="facility_type_name"
                                dataIndex="facility_type_name"
                                filterDropdown={({ visible }) => (
                                    <ColumnSearchFilter
                                        handleFetchInitial={handleFetchInitialSiteSurveys}
                                        filterAction={filterSiteSurveys}
                                        filterColumn={"facility_type_name"}
                                        filterType={"facility_type_name_cont"}
                                        filters={filters}
                                        pagination={pagination}
                                        setFilters={setFilters}
                                        sort={sort}
                                        setSort={setSort}
                                        visible={visible}
                                    />
                                )}
                                filterIcon={() => renderSearchOutlinedButton("facility_type_name_cont")}
                            />
                            {/* <Table.Column title="Abbreviation" key="abbreviation" dataIndex="abbreviation" />
                            <Table.Column title="Code" key="code" dataIndex="code" />
                            <Table.Column title="Client" key="client_id" dataIndex="client_id" /> */}
                            <Table.Column
                                title="Edit"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Menu.Item key="1" icon={<FilePdfOutlined />}>
                                                        <a href="javascript:;" onClick={() => siteSurveyPdf(record.id)}>
                                                            PDF
                                                        </a>
                                                    </Menu.Item>

                                                    <Role action="update" model="site_survey">
                                                        <Menu.Item key="2" icon={<EditOutlined />}>
                                                            <Link to={`/clients/${clientId}/ancillary_services/site_surveys/${record.id}/edit`}>Edit Site Survey</Link>
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}

export default SiteSurveysTable
