import React from "react"
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons"

function ColumnHeaderTitle(props) {
    const renderSortIcon = (title) => {
        let titleSortProps = {
            onClick: !!props.handleSortChange ? props.handleSortChange(title) : null,
            style: {
                color: "#bfbfbf",
            },
        }

        if (!!props.sort) {
            if (props.sort.column === title) {
                titleSortProps.style = {
                    color: "#1890ff",
                }
                // TODO: This was backwards previously. Actual fix incoming
                // if (props?.sort.direction === "desc")
                if (props?.sort.direction === "asc") {
                    return <SortDescendingOutlined {...titleSortProps} />
                }
            }
        } else {
            if (!!props.row && props?.row?.sortColumn?.key === title) {
                if (!!props.row.sortOrder) {
                    titleSortProps.style = {
                        color: "#1890ff",
                    }
                }
                if (props.row.sortOrder === "descend") {
                    return <SortDescendingOutlined {...titleSortProps} />
                }
            }
        }
        return <SortAscendingOutlined {...titleSortProps} />
    }

    const renderTitleText = () => {
        if (!!props.altTitle) {
            return <span>{props.altTitle}</span>
        } else {
            return <span>{props.title.replace(/_/g, " ")}</span>
        }
    }

    return (
        <div className={"antd-table-title-cell "+props.columnClass}>
            <span>{renderTitleText()}</span>
            {renderSortIcon(props.title)}
        </div>
    )
}

export default ColumnHeaderTitle
