import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../../lib/client"
import { ledgerAccountsUrl, ledgerAccountUrl, ledgerAccountsSidebar, ledgerAccountSelectOptionsUrl, dynamicUrl } from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"
import { notification } from "antd"

export const ledgerAccountsSlice = createSlice({
    name: "ledgerAccounts",
    initialState: {
        ledgerAccount: {},
        ledgerAccounts: [],
        ledgerAccountSelectOptions: [],
        ledgerAccountSearch: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setLedgerAccount: (state, action) => {
            state.ledgerAccount = action.payload
        },
        setLedgerAccounts: (state, action) => {
            state.ledgerAccounts = action.payload.ledger_accounts
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setLedgerAccountSelectOptions: (state, action) => {
            state.ledgerAccountSelectOptions = action.payload
        },
        setLedgerAccountSearch: (state, action) => {
            state.ledgerAccountSearch = action.payload
        },
    },
})

export const { setLedgerAccount, setLedgerAccounts, setPagination, setLoading, setLedgerAccountSelectOptions, setLedgerAccountSearch } = ledgerAccountsSlice.actions

export function fetchLedgerAccount(id, clientId) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(ledgerAccountUrl(id, clientId)).then((response) => {
            dispatch(setLedgerAccount(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchLedgerAccounts(clientId, pagination) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        client.get(`${ledgerAccountsUrl(clientId)}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setLedgerAccounts(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchLedgerAccountsSidebar(clientId) {
    return async (dispatch, getState) => {
        const response = await client.get(`${ledgerAccountsSidebar(clientId)}`)
        return response.data.ledger_accounts
    }
}

export function fetchClientLedgerAccounts(clientId, pagination = null) {
    return async (dispatch, getState) => {
        const clientLedgerAccountsPath = `clients/${clientId}/ledgerAccounts/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientLedgerAccountsPath)}`)
        return response.data.ledgerAccounts
    }
}

export function submitLedgerAccount(values, id, clientId) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(ledgerAccountUrl(id, clientId), { ledger_account: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(ledgerAccountsUrl(clientId), { ledger_account: values })
            return response.data
        }
    }
}

export function fetchLedgerAccountSelectOptions(clientId) {
    return async (dispatch, getState) => {
        client.get(`${ledgerAccountSelectOptionsUrl(clientId)}`).then((response) => {
            dispatch(setLedgerAccountSelectOptions(response.data.ledger_accounts))
        })
    }
}

// export function fetchLedgerAccountSelectOptionById(id, currentPath) {
//     return async (dispatch, getState) => {
//         client.get(`${ledgerAccountSelectOptionByIdUrl}?ledgerAccount_id=${id}`).then((response) => {
//             dispatch(setLedgerAccountSelectOptions(response.data.ledgerAccount))
//         })
//     }
// }

// export function searchLedgerAccounts(query) {
//     return async (dispatch) => {
//         dispatch(setLoading(true))

//         client
//             .get(`${ledgerAccountSearchUrl}?q=${query}`)
//             .then(({ data }) => {
//                 dispatch(setLedgerAccountSearch(data))
//             })
//             .catch((error) => {
//                 console.warn(error)
//             })
//             .finally(() => {
//                 dispatch(setLoading(false))
//             })
//     }
// }

export function filterLedgerAccounts(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, ledgerAccountsUrl(query.clientId))

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setLedgerAccounts(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export function deleteLedgerAccount(id, clientId) {
    return async (dispatch, getState) => {
        client
            .delete(`${ledgerAccountUrl(id, clientId)}`)
            .then(({ data }) => {
                if (data.success) {
                    notification.success({
                        message: data.message,
                    })
                window.location.reload();
                } else {
                    notification.error({
                        message: data.message,
                    })
                }
            })
            .catch((e) => {
                notification.error({
                    message: "Request error",
                    description: e?.response?.statusText,
                })
                console.error(e)
            })
    }
}

export const selectLedgerAccount = (state) => state.ledgerAccounts.ledgerAccount
export const selectLedgerAccounts = (state) => state.ledgerAccounts.ledgerAccounts
export const selectTotal = (state) => state.ledgerAccounts.total
export const selectPagination = (state) => state.ledgerAccounts.pagination
export const selectLoading = (state) => state.ledgerAccounts.loading
export const selectLedgerAccountSelectOptions = (state) => state.ledgerAccounts.ledgerAccountSelectOptions
export const selectLedgerAccountSearch = (state) => state.ledgerAccounts.ledgerAccountSearch

export default ledgerAccountsSlice.reducer
