import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useRouteMatch, useParams } from "react-router-dom"
import { Table, Skeleton, Popconfirm, notification, Tooltip, Dropdown, Menu, Button } from "antd"
import { DownloadOutlined, PlusOutlined, UploadOutlined, FilePdfOutlined, FileTextOutlined, EditOutlined, DeleteOutlined, DownOutlined, FolderOutlined, LoadingOutlined } from "@ant-design/icons"
import moment from "moment"

import {
    fetchAccountingBatches,
    selectAccountingBatches,
    deleteAccountingBatch,
    selectTotal,
    selectPagination,
    selectLoading,
    generateAccountingBatchCsv,
    generateAccountingBatchUploadFile,
    generateAccountingBatchInvoicePdf,
} from "../accountingBatchesSlice"

import Role from "../../../userRoles/Role"
import {formatCurrency} from "../../../../lib/utilities";
import useConfirmationModal from "../../../alertsPanel/ShowConfirm"

export default function AccountingBatchTable({ scopedByClient }) {
    const { url } = useRouteMatch()
    const { clientId } = useParams()
    const dispatch = useDispatch()
    const accountingBatches = useSelector(selectAccountingBatches)
    const total = useSelector(selectTotal)
    const pagination = useSelector(selectPagination)
    const loading = useSelector(selectLoading)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded) {
            setLoaded(true)
            dispatch(fetchAccountingBatches(clientId, pagination))
        }
        // eslint-disable-next-line
    }, [loaded, url, dispatch, pagination])

    const handleTableChange = (newPagination, filters, sorter) => {
        dispatch(fetchAccountingBatches(clientId, newPagination))
    }

    const deleteRecord = async (id) => {
        const response = await dispatch(deleteAccountingBatch(id))
        if (response.success) {
            notification.open({
                message: "Success",
                description: "Deleted Client Batch",
            })
        } else {
            notification.open({
                message: "Error",
                description: "There was an error deleting the Client Batch",
            })
        }
    }

    const accountingBatchesCsv = () => {
        notification.open({
            message: "Client batch export started",
        })

        dispatch(generateAccountingBatchCsv(clientId))
    }

    const generateUploadFile = async (accountingBatchId) => {
        notification.open({
            message: "Upload file generation started",
        })

        dispatch(generateAccountingBatchUploadFile(accountingBatchId))
    }

    const showConfirm = useConfirmationModal();
    const handleDeleteClick = (id) => {
        showConfirm(deleteAccountingBatch, id);
    };

    const generateInvoicePdf = async (accountingBatchId) => {
        notification.open({
            message: "Invoice PDF generation started",
        })

        dispatch(generateAccountingBatchInvoicePdf(accountingBatchId))
    }

    return (
        <div className="muc-table card">
            <div className="card-body">
                <div>
                    <div className="card-body-heading">
                        <div className="badged-header">
                            <h2>Client Batches</h2>
                            <div className="badge">{total}</div>
                        </div>
                        <div className="actions">
                            <div className="table-action-links">
                                <Role action="create" model="accounting_batch">
                                    <Link to={`${url}/new`}>
                                        <PlusOutlined />
                                        Add New
                                    </Link>
                                </Role>

                                {/* <a href="javascript:;" onClick={() => console.log("Import")}>
                                    <UploadOutlined />
                                    Import
                                </a> */}

                                <a href="javascript:;" onClick={() => accountingBatchesCsv()}>
                                    <DownloadOutlined />
                                    Export
                                </a>
                            </div>
                        </div>
                    </div>

                    <Skeleton active loading={loading}>
                        <Table dataSource={accountingBatches} pagination={{ ...pagination, showSizeChanger: true, total: total }} onChange={handleTableChange} rowKey={(r) => r.id}>
                            <Table.Column title="Batch Group" key="batch_group_name" dataIndex="batch_group_name" render={(text, record) => <>{record.batch_group?.name}</>} />
                            <Table.Column title="Batch Code" key="batch_code" dataIndex="batch_code" />
                            <Table.Column title="Batch Date" key="batch_date" dataIndex="batch_date" render={(text, record) => <>{moment(record.batch_date).format("MM/DD/YYYY")}</>} />
                            {/* TODO: Make money formatting a library function */}
                            <Table.Column title="Bills Total" key="bills_total" dataIndex="bills_total" align="right" render={(text, record, index) => formatCurrency((Math.round(record.bills_total * 100) / 100))} />
                            <Table.Column title="Line Item Count" key="line_item_count" dataIndex="line_item_count" />
                            <Table.Column
                                title="Upload File"
                                key="download"
                                // LoadingOutlined icon when new file is generating
                                render={(text, record) => {
                                    if (!record.upload_file_url) {
                                        return null
                                    }

                                    if (record.generating_file) {
                                        return <LoadingOutlined />
                                    } else {
                                        return (
                                            <a href={record.upload_file_url} target="_blank" rel="noreferrer">
                                                <Tooltip title="View Report">
                                                    <FileTextOutlined />
                                                </Tooltip>
                                            </a>
                                        )
                                    }
                                }}
                            />
                            <Table.Column
                                title="Actions"
                                key="action"
                                render={(text, record) => (
                                    <>
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Role action="update" model="accounting_batch">
                                                        <Menu.Item key="1" icon={<FolderOutlined />}>
                                                            <Link to={`${url}/${record.id}/bills`}>Bills</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="update" model="accounting_batch">
                                                        <Menu.Item key="2" icon={<EditOutlined />}>
                                                            <Link to={`${url}/${record.id}/edit`}>Edit Client Batch</Link>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="read" model="accounting_batch">
                                                        <Menu.Item key="3" icon={<FileTextOutlined />} >
                                                            <a href="javascript:;" onClick={() => generateUploadFile(record.id)}>
                                                                Generate Upload File
                                                            </a>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="read" model="accounting_batch">
                                                        <Menu.Item key="4" icon={<FilePdfOutlined />}>
                                                            <a href="javascript:;" onClick={() => generateInvoicePdf(record.id)}>
                                                                Invoice (PDF)
                                                            </a>
                                                        </Menu.Item>
                                                    </Role>

                                                    <Role action="destroy" model="accounting_batch">
                                                        <Menu.Item key="5" icon={<DeleteOutlined />} onClick={() => handleDeleteClick(record.id)} disabled={record.has_been_closed}>
                                                            Delete Client Batch
                                                        </Menu.Item>
                                                    </Role>
                                                </Menu>
                                            }>
                                            <Button>
                                                Actions <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </>
                                )}
                            />
                        </Table>
                    </Skeleton>
                </div>
            </div>
            {/* <div className="card-footer">
                <div className="card-footer-left"></div>
                <div className="card-footer-right pagination"></div>
            </div> */}
        </div>
    )
}
