import { createSlice } from "@reduxjs/toolkit"

// http client
import client from "../../../lib/client"
import { costParametersUrl, costParameterUrl, costParameterSelectOptionsUrl, dynamicUrl } from "../../../lib/endpoints"
import { createFilterUrl } from "../../../functions/objectFunctions/createFilterUrl"

export const costParametersSlice = createSlice({
    name: "costParameters",
    initialState: {
        costParameter: {},
        costParameters: [],
        costParameterSelectOptions: [],
        total: 0,
        pagination: { current: 1, pageSize: 10 },
        loading: false,
    },
    reducers: {
        setCostParameter: (state, action) => {
            state.costParameter = action.payload
        },
        setCostParameters: (state, action) => {
            state.costParameters = action.payload.cost_parameters
            state.total = action.payload.total
        },
        setPagination: (state, action) => {
            state.pagination = action.payload
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        setCostParameterSelectOptions: (state, action) => {
            state.costParameterSelectOptions = action.payload
        },
    },
})

export const { setCostParameter, setCostParameters, setPagination, setLoading, setCostParameterSelectOptions } = costParametersSlice.actions

export function fetchCostParameter(id) {
    return async (dispatch, getState) => {
        client.get(costParameterUrl(id)).then((response) => {
            dispatch(setCostParameter(response.data))
        })
    }
}

export function fetchCostParameters(pagination, currentPath) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        client.get(`${costParametersUrl}?page=${pagination.current}&per=${pagination.pageSize}`).then((response) => {
            dispatch(setPagination(pagination))
            dispatch(setCostParameters(response.data))
            dispatch(setLoading(false))
        })
    }
}

export function fetchClientCostParameters(clientId, pagination = null, currentPath = null) {
    return async (dispatch, getState) => {
        const clientCostParametersPath = `clients/${clientId}/costParameters/sidebar_records`

        const response = await client.get(`${dynamicUrl(clientCostParametersPath)}`)
        return response.data.costParameters
    }
}

export function submitCostParameter(values, id) {
    return async (dispatch, getState) => {
        if (id) {
            // patch - update
            const response = await client.patch(costParameterUrl(id), { cost_parameter: values })
            return response.data
        } else {
            // post - create
            const response = await client.post(costParametersUrl, { cost_parameter: values })
            return response.data
        }
    }
}

export function fetchCostParameterSelectOptions(searchTerm, currentPath) {
    return async (dispatch, getState) => {
        client.get(`${costParameterSelectOptionsUrl}?search_term=${searchTerm}`).then((response) => {
            dispatch(setCostParameterSelectOptions(response.data.cost_parameters))
        })
    }
}

export function filterCostParameters(query) {
    /// create the filter URL string
    let filterUrl = createFilterUrl(query, costParametersUrl)

    return async (dispatch) => {
        dispatch(setLoading(true))
        client
            .get(filterUrl)
            .then((response) => {
                dispatch(setPagination(query.pagination))
                dispatch(setCostParameters(response.data))
                dispatch(setLoading(false))
            })
            .catch((error) => {
                console.warn(error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const selectCostParameter = (state) => state.costParameters.costParameter
export const selectCostParameters = (state) => state.costParameters.costParameters
export const selectTotal = (state) => state.costParameters.total
export const selectPagination = (state) => state.costParameters.pagination
export const selectLoading = (state) => state.costParameters.loading
export const selectCostParameterSelectOptions = (state) => state.costParameters.costParameterSelectOptions

export default costParametersSlice.reducer
